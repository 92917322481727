import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime, getStructureStars, getStatusClass } from '../../../js/Utils.js';
import { imageOnError, imageOnLoadHotel } from '../../../js/CDNUtility';
import { getDifferenceDay, getIconUrlToPrint, replaceAllStyleElements, isNotesTooBig, getAllFirstUppercase, getFirstUppercase, getNoteCut  } from '../../../js/PrintUtility';
import { HotelStaticContainer } from '../../Product/Hotel/components/HotelStaticContainer';
import { PdfPeople } from '../../Common/PdfPeople.js';
import { ServiceNotePrint } from '../service/ServiceNotePrint.js';


export const TourDetailPrint = ({ id, type, showNetPrices, tourDetail, isPrintCliente, isDocViaggio,isNotStep, isConfirmPrint, isPrintPdf = false, isHideCancelPolicy }) => {
    const { t } = useTranslation();
    
    function getFinalRooms(rooms) {
        if (isConfirmPrint) {
            return rooms.filter(x =>
                x.priceBar.status === "CONFIRMED" ||
                x.priceBar.status === "ONREQUEST" ||
                x.priceBar.status === "PRINTED" ||
                x.priceBar.status === "REFUND" ||
                x.priceBar.status === "PENALTY"
            );
        }
        else { // quotation
            return rooms;
        }
    }

    function getGeneralStatus(rooms) {
        if (isConfirmPrint) {
            let res = rooms.find(x =>
                x.priceBar.status === "ONREQUEST" ||
                x.priceBar.status === "PRINTED" ||
                x.priceBar.status === "REFUND" ||
                x.priceBar.status === "PENALTY"
            );
            
            if (res)
                return "MIX";
            else
                return "CONFIRMED"
        }
    }

    var generalStatus = "";
    if (tourDetail && tourDetail.rooms) {
        generalStatus = getGeneralStatus(tourDetail.rooms);
    }

    return (
        <>
        {/*Hotel*/}
            {tourDetail &&
                <table className="w-100 border shadow">
                    <tbody>
                        <tr className="shadow">
                            <td className="widthTdImage">
                                <img src={`data:image;base64, ` + tourDetail.thumbUrlB} alt="Thumb" className="imgProductStructure" onError={imageOnError} />
                            </td>
                            <td className="w-80  px-3">
                                <table className="w-100">
                                    <tbody>
                                        <tr className="h6">
                                            <td colspan="2" className="fontBold">
                                                {getAllFirstUppercase(tourDetail.name)}
                                            </td>
                                            {!isPrintPdf &&
                                                <button className={"btn p-0 ml-3 "} data-bs-toggle="modal" data-bs-target={"#schedStaticaModalHotel" + tourDetail.productId}>
                                                    <data m3ico="info text-gray-500 icon-10">
                                                        <span class="material-icons text-gray-500 icon-10">info</span>
                                                    </data>
                                                </button>
                                            }
                                            <td className="text-right">
                                                {isConfirmPrint && tourDetail.priceBar && tourDetail.priceBar.wsBookingId &&
                                                    <>
                                                    N. PNR: {tourDetail.priceBar.wsBookingId}
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="h5">
                                            <td colspan="4">
                                                
                                            </td>
                                            <td className="align-top text-right w-30">
                                                {isConfirmPrint && tourDetail && tourDetail.rooms && generalStatus !== "CONFIRMED" &&
                                                    <span className={"h6 status " + getStatusClass(generalStatus)}>{t(`statiPrenotazione:` + generalStatus)}</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                {getStructureStars(tourDetail.category)}
                                            </td>
                                        </tr>

                                        {tourDetail.rooms && tourDetail.rooms.length > 0 && getFinalRooms(tourDetail.rooms).map((room, key) =>
                                        <>
                                            <tr key={key}>
                                                <td className="w-imagePdf w-5">
                                                    <img src={getIconUrlToPrint('room')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    <label>
                                                        {getFirstUppercase(room.roomName ? room.roomName : room.name)} ({getDifferenceDay(room.priceBar.startDate, room.priceBar.endDate)} {t(`Product:Structure:Nights`)})
                                                    </label>
                                                </td>
                                                <td className="w-30 text-left">
                                                        <td>
                                                        {room.refPax ?
                                                                    <>
                                                                        <PdfPeople adults={room.adults} children={room.children} />
                                                            </>
                                                            :
                                                            <>
                                                                        <PdfPeople adults={room.adults} children={room.children} />
                                                            </>
                                                        }
                                                        </td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="w-imagePdf w-5">
                                                    <img src={getIconUrlToPrint('treatment')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    <label>
                                                        {getFirstUppercase(room.planName)}
                                                    </label>
                                                </td>
                                                <td className="w-30 text-right">
                                                    {isConfirmPrint && room.priceBar.status !== "CONFIRMED" &&
                                                        <span className={"h6 status " + getStatusClass(room.priceBar.status)}>{t(`statiPrenotazione:` + room.priceBar.status)}</span>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('announcement')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    {room.resortFees && room.resortFees.length > 0 ?
                                                        room.resortFees.map((resortFee, index) =>
                                                            <label key={index}>{resortFee}</label>
                                                        )
                                                    :
                                                        <label>{t(`Product:Structure:InfoTaxes`)}</label>
                                                    }
                                                </td>
                                            </tr>
                                            {room.extras && room.extras.length > 0 && room.extras.map((extra, key) =>
                                                <tr>
                                                    <td className="w-60 text-left">
                                                        <label>Extra &nbsp;{key + 1}:&nbsp;</label>
                                                        <label dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(extra.description) }} />
                                                    </td>
                                                </tr>
                                            )}
                                            <tr className="border-bottom">
                                                {!isHideCancelPolicy && room.cancelPolicy && !room.cancelPolicy.isFree &&
                                                <>
                                                    <td className="w-imagePdf">
                                                        <img src={getIconUrlToPrint('localAtm')} className="ico-image mr-1" alt="" />
                                                    </td>
                                                    <td>
                                                        {!room.cancelPolicy.isFree &&
                                                            <label className="penalty">{t(`CancelPolicy:PenaltyCancellation`)}</label>
                                                        }
                                                    </td>
                                                </>
                                            }
                                            </tr>
                                        </>
                                        )}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            <div style={{ padding: "5px" }}>
                <ServiceNotePrint notes={tourDetail.notesService} noteTypeToShow={[isDocViaggio ? 11 : isConfirmPrint ? 10 : 13]} />
                {tourDetail.rooms && tourDetail.rooms.length > 0 && getFinalRooms(tourDetail.rooms).map((room, index) => room.notes.length > 0 && room.notes[0] !== "" && <>
                    <ServiceNotePrint camera={index + 1} notes={room.notesService} noteTypeToShow={[isDocViaggio ? 11 : isConfirmPrint ? 10 : 13]} />
                </>
                )}
            </div>
           
            {tourDetail && !isPrintPdf &&
                <div className="modal fade" id={"schedStaticaModalHotel" + tourDetail.productId} tabIndex="-1" aria-labelledby={"exampleModalLabel"} aria-hidden="true">
                    <div className="modal-dialog modal-xxl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id={"exampleModalLabel"}>
                                    <data m3lab="ActionMenu.Detail">{t(`ActionMenu:Detail`)}</data>
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <HotelStaticContainer structureId={tourDetail.productId} isStaticView={true} isNotEngine={true} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                    <data m3lab="Button.Close">{t('Button:Close')}</data>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
