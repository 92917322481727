import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../Common/Loading';
import { SelectContact } from '../../MyBook/Rubrica/components/SelectContact';

export const FormSendMail = ({ onSend, onSendResponse, cartId, allowRubrica, isFromCart }) => {
    const { t } = useTranslation();

    const [onSendResponseResult, setOnSendResponseResult] = useState('');
    const [quotationName, setQuotationName] = useState('');
    const [emptyCart, setEmptyCart] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [selectContacts, setSelectContacts] = useState([]);

    useEffect(() => {
        let modalSendMail = document.getElementById('modal_SendMail');
        modalSendMail.addEventListener('shown.bs.modal', function () {
            setIsLoading(false);
            setQuotationName('Preventivo N. ' + cartId);
            setIsValid(true);
        });

    }, []);

    useEffect(() => {
        setIsLoading(false);
        setOnSendResponseResult(onSendResponse);
    }, [onSendResponse]);

    function onBlurEvent(event) {
        let newValue = event.target.value;
        let isNotEmpty = (newValue !== '' && newValue !== undefined);

        setQuotationName(newValue);
        setIsValid(isNotEmpty);
    }

    function sendRequest() {
        setIsLoading(true);
        setOnSendResponseResult(null);
        let selectUserId = null;
        if (selectContacts && selectContacts.length > 0)
            selectUserId = selectContacts[0].userId;

        onSend({ quotationName: quotationName, userId: selectUserId, emptyCart: emptyCart });
    }

    function onSelectContact(contact) {
        let myArray = [];
        if (contact) {
            const exists = selectContacts.some((x) => x.contactId === contact.contactId);

            if (!exists)
                myArray.push(contact);
        }

        setSelectContacts(myArray);
    }

    return (
        <>
            <a id="open_SendMail" data-bs-toggle="modal" data-bs-target="#modal_SendMail"></a>
            <div className="modal fade" id="modal_SendMail" tabIndex="-1" aria-labelledby="modal_SendMail" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal_sendMailLabel">Invia Mail</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {
                                (!onSendResponseResult || !onSendResponseResult.success) && !isLoading
                                    ? (
                                        <>
                                            <div className="row">
                                                <div className="form-floating col-2"></div>
                                                <div className={"form-floating col-8" + (!isValid ? " insert-danger" : "")}>
                                                    <input id="sendMail_Titolo"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Titolo Richiesta"
                                                        value={quotationName}
                                                        onChange={(e) => { onBlurEvent(e); }} />
                                                    <label htmlFor="sendMail_Titolo" className="active">{t('MyBook:QuotationName')}</label>
                                                    {
                                                        !isValid && <div className="text-danger">{t('Login:FieldNameIsMandatory')}</div>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                allowRubrica && <SelectContact
                                                    titleForm="Invia il tuo preventivo ad un tuo contatto"
                                                    mode="BASE"
                                                    selectContacts={selectContacts}
                                                    onSelectContact={onSelectContact} />
                                            }
                                            {
                                                isFromCart && <div className="row">
                                                    <div className="form-floating col-12">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                type="checkbox"
                                                                id="sendMail_emptyCart"
                                                                checked={emptyCart}
                                                                defaultChecked={emptyCart}
                                                                onChange={_ => setEmptyCart(!emptyCart)}
                                                                value="option2" />
                                                            <label className="text-dark" for="sendMail_emptyCart">
                                                                <data m3lab="Cart.EmptyCart_AfterQuotationSend">{t('Cart:EmptyCart_AfterQuotationSend')}</data>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )
                                    : <></>
                            }
                            {
                                <>
                                    {isLoading && !onSendResponseResult && <div className="mt-2"><Loading textMsg={t('Loading:PageSendCart')} /></div>}
                                </>
                            }
                        </div>
                        <div className='modal-footer'>
                            {
                                !isLoading && onSendResponseResult && !onSendResponseResult.success && <span className="text-danger" style={{ float: 'left', margin: '0 auto' }}>{t('Cart:CartErrorGeneric')}</span>
                            }
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t('Button:Close')}</button>
                            {(!onSendResponseResult || !onSendResponseResult.success) && !isLoading && <button type="button" className="btn bt-sm tp-btn-confirm" disabled={!isValid} onClick={() => sendRequest()}>Continua</button>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
