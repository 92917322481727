import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime, getStructureStars, getStatusClass } from '../../../js/Utils.js';
import { imageOnError, imageOnLoadHotel } from '../../../js/CDNUtility';
import { getDifferenceDay, getIconUrlToPrint, replaceAllStyleElements, isNotesTooBig, getAllFirstUppercase, getFirstUppercase, getNoteCut  } from '../../../js/PrintUtility';
import { HotelStaticContainer } from '../../Product/Hotel/components/HotelStaticContainer';
import { PdfPeople } from '../../Common/PdfPeople.js';
import { ServiceNote } from '../../Reservation/ServiceNote.js';
import { ServiceNotePrint } from '../service/ServiceNotePrint.js';


export const StructureDetailPrint = ({ id, type, showNetPrices, structureDetail, isDocViaggio,isPrintCliente, isNotStep, isConfirmPrint, isPrintPdf = false, isHideCancelPolicy }) => {
    const { t } = useTranslation();
    
    function getFinalRooms(rooms) {
        if (isConfirmPrint) {
            return rooms.filter(x =>
                x.priceBar.status === "CONFIRMED" ||
                x.priceBar.status === "ONREQUEST" ||
                x.priceBar.status === "PRINTED" ||
                x.priceBar.status === "REFUND" ||
                x.priceBar.status === "PENALTY"
            );
        }
        else { // quotation
            return rooms;
        }
    }


    function getGeneralStatus(rooms) {
        if (isConfirmPrint) {
            let res = rooms.find(x =>
                x.priceBar.status === "ONREQUEST" ||
                x.priceBar.status === "PRINTED" ||
                x.priceBar.status === "REFUND" ||
                x.priceBar.status === "PENALTY"
            );
            
            if (res)
                return "MIX";
            else
                return "CONFIRMED"
        }
    }

    var generalStatus = "";
    if (structureDetail && structureDetail.rooms) {
        generalStatus = getGeneralStatus(structureDetail.rooms);
    }

    function getPdfPeople(rooms) {
        let adults = 0;
        let children = 0;

        for (let i = 0; i < rooms.length; i++) {
            adults += rooms[i].adults;
            children += rooms[i].children;
        }

        return <PdfPeople adults={adults} children={children} />;
    }

    return (
        <>
        {/*Hotel*/}
            {structureDetail &&
                <table className="w-100 border shadow">
                    <tbody>
                        <tr className="shadow">
                            <td className="widthTdImage">
                                <img src={`data:image;base64, ` + structureDetail.thumbUrlB} alt="Thumb" className="imgProductStructure" onError={imageOnError} />
                            </td>
                            <td className="w-80  px-3">
                                <table className="w-100">
                                    <tbody>
                                        <tr className="h6">
                                            <td style={{ width: "280px" }} colspan="4" className="fontBold" >
                                                {getAllFirstUppercase(structureDetail.name)}
                                            </td>
                                            <td className="text-right" style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                                                <div style={{ width: "40px" }}></div>
                                                {getPdfPeople(structureDetail.rooms) }
                                            </td>
                                        </tr>
                                        <tr className="h5">
                                            <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>

                                                <div style={{ width: "4px" }}></div>
                                                {structureDetail.category < 11 ? [...Array(Math.round(Number(structureDetail.category) / 2))].map(() => {
                                                    return <>
                                                        <img src={getIconUrlToPrint('stargolden')} className="ico-image mr-1" alt="" />
                                                    </>
                                                }) : <></>}
                                            </div>
                                            <td className="align-top text-right w-30">
                                                {isConfirmPrint && structureDetail && structureDetail.rooms && generalStatus !== "CONFIRMED" &&
                                                    <span className={"h6 status " + getStatusClass(generalStatus)}>{t(`statiPrenotazione:` + generalStatus)}</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                                                    <span>{structureDetail.address}</span>

                                                </div>
                                            </td>
                                        </tr>

                                        {structureDetail.rooms && structureDetail.rooms.length > 0 && getFinalRooms(structureDetail.rooms).map((room, key) =>
                                            <>
                                                <tr key={key}>
                                                
                                                    <td className="">
                                                        <div style={{ display: "flex", flexDirection: "row", alignContent: 'center' }}>

                                                    <img src={getIconUrlToPrint('room')} className="ico-image mr-1" alt="" />
                                                        <label>
                                                        {getFirstUppercase(room.roomName ? room.roomName : room.name)} ({getDifferenceDay(room.priceBar.startDate, room.priceBar.endDate)} {t(`Product:Structure:Nights`)})
                                                        </label>
                                                        </div>
                                                        <br />
                                                        <div style={{ display: "flex", flexDirection: "row", alignContent: 'center' }}>

                                                            <img src={getIconUrlToPrint('treatment')} className="ico-image mr-1" alt="" />
                                                            <label>
                                                                {getFirstUppercase(room.planName)}
                                                            </label>
                                                        </div>
                                                           
                                                </td>
                                                    <td className="w-35 text-left">
                                                        <td className="w-imagePdf">
                                                            <img src={getIconUrlToPrint('announcement')} className="ico-image mr-1" alt="" />
                                                        </td>
                                                        <td>
                                                            {room.resortFees && room.resortFees.length > 0 ?
                                                                room.resortFees.map((resortFee, index) =>
                                                                    <label key={index}>{getNoteCut(resortFee)}</label>
                                                                )
                                                                :
                                                                <label>{t(`Product:Structure:InfoTaxes`)}</label>
                                                            }
                                                        </td>
                                                       
                                                        
                                                </td>
                                            </tr>
                                                

                                        </>
                                        )}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            {<div style={{ padding: "5px" }}>
                <ServiceNotePrint notes={structureDetail.notesService} noteTypeToShow={[isDocViaggio ? 11 : isConfirmPrint ? 10 : 13]} />
                {structureDetail.rooms && structureDetail.rooms.length > 0 && getFinalRooms(structureDetail.rooms).map((room, index) => {
                    return <>

                        <ServiceNotePrint camera={index + 1} notes={room.notesService} noteTypeToShow={[isDocViaggio ? 11 : isConfirmPrint ? 10 : 13]} />
                    </>
                })}
            </div>}
          
     

            {structureDetail && !isPrintPdf &&
                <div className="modal fade" id={"schedStaticaModalHotel" + structureDetail.productId} tabIndex="-1" aria-labelledby={"exampleModalLabel"} aria-hidden="true">
                    <div className="modal-dialog modal-xxl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id={"exampleModalLabel"}>
                                    <data m3lab="ActionMenu.Detail">{t(`ActionMenu:Detail`)}</data>
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <HotelStaticContainer structureId={structureDetail.productId} isStaticView={true} isNotEngine={true} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                    <data m3lab="Button.Close">{t('Button:Close')}</data>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
