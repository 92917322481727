import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TrainChangePaxModal } from './OperationModals/TrainChangePaxModal';

export const OperationButtons = ({ pnr, productType, status, allowedOps, openChangeDatePanel, isOpenChangeDatePanel, openChangeIteneraryPanel, isOpenChangeIteneraryPanel }) => {
    const { t } = useTranslation();
    const [cancel, setCancel] = useState(false);
    const [cancelRefund, setCancelRefund] = useState(false);
    const [changeStructurePaxes, setChangeStructurePaxes] = useState(false);
    const [changeStructureDates, setChangeStructureDates] = useState(false);
    const [printPnr, setPrintPnr] = useState(false);
    const [requotePnr, setRequotePnr] = useState(false);
    const [optionConfirm, setOptionConfirm] = useState(false);

    const [checkInLink, setCheckInLink] = useState(false);
    const [checkInLinkData, setCheckInLinkData] = useState();

    const [changeItineraryLink, setChangeItineraryLink] = useState(false);
    const [changeItineraryData, setChangeItineraryData] = useState();

    const [changePaxesLink, setChangePaxesLink] = useState(false);
    const [changePaxesData, setChangePaxesData] = useState();

    const [changeDateLink, setChangeDateLink] = useState(false);
    const [changeDateData, setChangeDateData] = useState();


    useEffect(() => {
        switch (productType) {
            case 0: // structure
            case 1: // activity
            case 2: // car rental
            case 4: // transfer
            case 7: // coupon
            case 8: // rent sell

                setCancelRefund(false);

                if ((status === "CONFIRMED" || status === "OPTION") && allowedOps.CancelReservation.enabled)
                    setCancel(true);

                if (status === "CONFIRMED" && allowedOps.ChangePaxes.enabled)
                    setChangeStructurePaxes(true);

                if (status === "CONFIRMED" && allowedOps.ChangeDate.enabled)
                    setChangeStructureDates(true);

                if (status === "OPTION" && allowedOps.OptionConfirm.enabled)
                    setOptionConfirm(true);
                break;

            case 3: // cruise

                setCancelRefund(false);

                if ((status === "CONFIRMED" || status === "ONREQUEST" || status === "OPTION") && allowedOps.CancelReservation.enabled)
                    setCancel(true);

                if ((status === "ONREQUEST" || status === "OPTION") && allowedOps.OptionConfirm && allowedOps.OptionConfirm.enabled)
                    setOptionConfirm(true);
                break;

            case 5: // flight

                setCancelRefund(false);

                if ((status === "CONFIRMED" || status === "PRINTED") && allowedOps.CancelReservation.enabled)
                    setCancel(true);

                if (allowedOps.PrintPNR.enabled)
                    setPrintPnr(true);

                if (allowedOps.RequotePNR.enabled)
                    setRequotePnr(true);

                if (status === "PRINTED" && allowedOps.CheckInLink.enabled) {
                    setCheckInLink(true);
                    setCheckInLinkData(allowedOps.CheckInLink.data);
                }

                if (status === "CONFIRMED" && allowedOps.ChangeItinerary.enabled) {
                    setChangeItineraryLink(true);
                    setChangeItineraryData(allowedOps.ChangeItinerary.data);
                }
                break;

            case 9: // train
                if (status === "CONFIRMED" && allowedOps.CancelReservation.enabled) {
                    setCancel(true);
                    setCancelRefund(true);
                }

                if (status === "CONFIRMED" && allowedOps.ChangePaxes.enabled) {
                    setChangePaxesLink(true);
                    setChangePaxesData(allowedOps.ChangePaxes.data);
                }

                if (status === "CONFIRMED" && allowedOps.ChangeDate.enabled) {
                    setChangeDateLink(true);
                    setChangeDateData(allowedOps.ChangeDate.data);
                }

                if (status === "CONFIRMED" && allowedOps.ChangeItinerary.enabled) {
                    setChangeItineraryLink(true);
                    setChangeItineraryData(allowedOps.ChangeItinerary.data);
                }
                break;

            default:
                break;
        }
    }, []);

    return (
        <>
            {optionConfirm && <a className="tp-btn-quote btn-sm" data-bs-toggle="modal" data-bs-target={'#OptionConfirm_' + pnr}>{t("MyBook:ConfirmOption")}</a>}
            &nbsp;
            {cancel && <a className="tp-btn-delete btn-sm" data-bs-toggle="modal" data-bs-target={'#CancelReservation_' + pnr}>
                {
                    productType !== 5 ? status === "OPTION" ? "Cancella Opzione" : "Cancella"
                        : printPnr ? "Void" : "Cancella PNR"
                }
            </a>}
            {
                cancelRefund && <a className="tp-btn-delete btn-sm ml-2" data-bs-toggle="modal" data-bs-target={'#CancelReservationWithRefund_' + pnr}>Cancella con rimborso</a>
            }
            &nbsp;
            {changeStructureDates && <a className="tp-btn-manage btn-sm" data-bs-toggle="modal" data-bs-target={'#ChangeStructureDates_' + pnr}>{t("MyBook:ChangeDate")}</a>}
            &nbsp;
            {changeStructurePaxes && <a className="tp-btn-manage btn-sm" data-bs-toggle="modal" data-bs-target={'#ChangeStructurePaxes_' + pnr}>{t("MyBook:ChangePassengers")}</a>}
            &nbsp;
            {printPnr && <a className="tp-btn-print btn-sm" data-bs-toggle="modal" data-bs-target={'#PrintPNR_' + pnr}>Print</a>}
            &nbsp;
            {requotePnr && <a className="tp-btn-quote btn-sm" data-bs-toggle="modal" data-bs-target={'#RequotePNR_' + pnr}>{t("Button:Riquote")}</a>}
            &nbsp;
            {changeItineraryLink && <a href={changeItineraryData} onClick={() => { if (openChangeIteneraryPanel) openChangeIteneraryPanel(!isOpenChangeIteneraryPanel) } } target="_blank" className="tp-btn-manage btn-sm">{t("MyBook:ChangeItinerary")}</a>}
            &nbsp;
            {checkInLink && <a href={checkInLinkData} target="_blank" className="tp-btn-action btn-sm">Check-In</a>}
            &nbsp;
            {changePaxesLink && <a data-bs-toggle="modal" data-bs-target="#change_pax" target="_blank" className="tp-btn-manage btn-sm">{t("MyBook:ChangePassengers")}</a>}
            &nbsp;
            {changeDateLink && <a href={changeDateData} onClick={() => { if (openChangeDatePanel) openChangeDatePanel(!isOpenChangeDatePanel) } } target="_blank" className="tp-btn-manage btn-sm">{t("MyBook:ChangeDate")}</a>}
            &nbsp;
            {/*Structure*/}
            {/*<button type="button" className="tp-btn-manage btn-sm">Cambio date</button>*/}
            {/*<button type="button" className="tp-btn-manage btn-sm">Cambio passeggeri</button>*/}
        </>
    );
}
