import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { handleError, formatDateTime, formatPrice, getCurrentUserFromJwtToken } from '../../js/Utils.js';
import { downloadDocumentPDF, replaceAllStyleElements, setCancelPolicy, setNoteProductBig, setFlightTaxesArr, getFinalOrderArr, getIconUrlToPrint } from "../../js/PrintUtility.js";
import configData from "../../appsettings.json";
import { HeaderPDFPrint } from '../Custom/Print/HeaderPDFPrint.js';
import { PrintProductsNotes } from './components/PrintProductsNotes.js';
import '../Custom/Print/PrintCssForHtml.css';
import { PrintStepHeader } from './components/PrintStepHeader.js';
import { PrintPaxes } from './components/PrintPaxes.js';
import { PrintStepItem } from './components/PrintStepItem.js';
import { PrintAncillaries } from './components/PrintAncillaries.js';
import { PrintCommissionsDetailCompact } from './components/PrintCommissionsDetailCompact.js';
import { PrintCommissionsDetail } from './components/PrintCommissionsDetail.js';
import { PrintScadenziario } from './components/PrintScadenziario.js';
import { PrintDynamicNotes } from './components/PrintDynamicNotes.js';
import { TourStaticPrint } from './components/TourStaticPrint.js';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PrintPdfDocuments } from '../Common/PrintPdfDocuments.js';
import { printCssStyle } from '../Custom/Print/PrintCss.js';
import { Loading } from '../Common/Loading.js';
import { Error } from '../Common/Error.js';
import { generateQuotationHTML } from "./service/QuotationPrintService.js";
import { PrintItineraryResume } from './components/PrintItineraryResume.js';
import { TemplatePrintStepHeader } from './template/TemplatePrintStepHeader.js';
import { TemplatePrintImageHeader } from './template/TemplatePrintImageHeader.js';
import { TemplatePrintHeaderDescription } from './template/TemplatePrintHeaderDescription.js';
import { TemplatePrintInclusion } from './template/TemplatePrintInclusion.js';
import { PrintStepItemAlternatives } from './components/PrintStepItemAlternatives.js';
import { PrintTitleSeparator } from './components/PrintTitleSeparator.js';
import { PrintCancelPolicy } from './components/PrintCancelPolicy.js';
import { PrintProductsNotesStep } from './components/PrintProductsNotesStep.js';
import { ServiceNotePrint } from './service/ServiceNotePrint.js';
import { ServiceNote } from '../Reservation/ServiceNote.js';


export const QuotationTo = () => {
    let { quotationId } = useParams();

    let params = new URLSearchParams(window.location.search);
    let authCode = params.get('ac');

    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isShowAfterAllCall, setIsShowAfterAllCall] = useState(true); // serve per ricaricare in caso di async lunghe
    const [isQuotationTo, setIsQuotationTo] = useState(false); // serve per ricaricare in caso di async lunghe

    var quotationItems = null
    const [flag, setFlag] = useState(false)
    const [userIdWorkNow, setUserIdWorkNow] = useState(null);
    const [actualUserInfo, setActualUserInfo] = useState({});
    const [customerInfoQuotation, setCustomerInfoQuotation] = useState({});
    const [quotation, setQuotation] = useState(null);
    const [quotationHeader, setQuotationHeader] = useState(null);
    const [quotationTableMarginItems, setQuotationTableMarginItems] = useState([]);
    const [quotationInternalNotes, setQuotationInternalNotes] = useState([]);
    const [quotationRecapItems, setQuotationRecapItems] = useState([]);
    const [quotationItemStep, setQuotationItemStep] = useState([]);
    const [newNoteSubType, setNewNoteSubType] = useState("");
    const [totalInternalNotes, setTotalInternalNotes] = useState(0);
    const [limitQuotationNote, setLimitQuotationNote] = useState(5);
    const [quotationItemStepPrint, setQuotationItemStepPrint] = useState([]);
    const [quotationMode, setQuotationMode] = useState([]);
    const [quotationAllowedOperation, setQuotationAllowedOperation] = useState(null);
    const [minProductDate, setMinProductDate] = useState(null);
    const [paxes, setQuotationPaxes] = useState(null);
    const [quotationAlternatives, setQuotationAlternatives] = useState([]);

    const [pdfFileName, setPdfFileName] = useState(t("Print:Quotation") + quotationId);

    const [myHtml, setMyHtml] = useState([]);
    const [websiteId, setWebsiteId] = useState();

    const [extraInfoPrint, setExtraInfoPrint] = useState({});
    const [isFinishPrintPDF, setIsFinishPrintPDF] = useState(0);
    const [isDownloadPDF, setIsDownloadPDF] = useState(true);
    const [isLoadingPrintWithoutAlternatives, setIsLoadingPrintWithoutAlternatives] = useState(false);
    const [isLoadingPrintClientWithoutAlternatives, setIsLoadingPrintClientWithoutAlternatives] = useState(false);
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const [itemStepPrint, setItemStepPrint] = useState([]);
    const [dynamicNotes, setDynamicNotes] = useState([]);
    const [dynamicNotesTop, setDynamicNotesTop] = useState([]);
    const [dynamicNotesStep, setDynamicNotesStep] = useState([]);
    const [scadenziario, setScadenziario] = useState({});
    const [isLoadingPrintCliente, setIsLoadingPrintCliente] = useState(false);
    const [isLoadingPrintCommExplode, setIsLoadingPrintCommExplode] = useState(false);
    const [template, setTemplate] = useState(null);

    // stati di stampa componenti
    const [isPrintHeaderImage, setIsPrintHeaderImage] = useState(true);
    const [isPrintHeaderDesc, setIsPrintHeaderDesc] = useState(true);
    const [isPrintItineraryResume, setIsPrintItineraryResume] = useState(true);
    const [isPrintDynamicNotesTop, setIsPrintDynamicNotesTop] = useState(true);
    const [isPrintPaxes, setIsPrintPaxes] = useState(true);
    const [isPrintAncillaries, setIsPrintAncillaries] = useState(true);
    const [isPrintInclusions, setIsPrintInclusions] = useState(true);
    const [isPrintCommissions, setIsPrintCommissions] = useState(true);
    const [isPrintDynamicNotes, setIsPrintDynamicNotes] = useState(true);
    const [isPrintNotes, setIsPrintNotes] = useState(true);
    const [isPrintExtraNotes, setIsPrintExtraNotes] = useState(true);
    const [isPrintServicesNotSelected, setIsPrintServicesNotSelected] = useState(true);
    const [isPrintScadenziario, setIsPrintScadenziario] = useState(true);
    const [isShowAlternatives, setIsShowAlternatives] = useState(true); // se mostrare le alternative
    const [isPrintCancelPolicy, setIsPrintCancelPolicy] = useState(true);
    const [isPrintNoteBig, setIsPrintNoteBig] = useState(true);
    const [isShowStep, setIsShowStep] = useState(true);


    var rowDescTemplate = null;
    var rowItineraryResume = null;
    var isCallDynamicNoteStep = false;
    var tmpDynamicNotesStep = [];

    // net prices
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);

    const [userAddToOrder, setUserAddToOrder] = useState(false);

    let arrProduct = [];
    let totQuotationItems = 0; // usato per contare il totale pratiche e proseguire dopo le chiamate asincrone

    const [quotationSettings, setQuotationSettings] = useState({
        baseUrl: configData.Settings.Base_Url,
        minPercTotaleMargineLordo: configData.Settings.QuotationTO.MinPercTotaleMargineLordo,
        maxPercTotaleMargineLordo: configData.Settings.QuotationTO.MaxPercTotaleMargineLordo,
        groupedAddictionalProductADV: false,
        excludeRicavoTMFromMargineSede: configData.Settings.QuotationTO.ExcludeRicavoTMFromMargineSede,
        calcoloTMType: configData.Settings.QuotationTO.CalculateMarginTM,
        isDeleted: false,
        isReplaced: false,
        isAdmin: true
    });

    useEffect(() => {
        setIsShowAlternatives(true);

        callGetQuotationDetail();
        callGetPaxes();

        try {

            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
            setWebsiteId(currentUser.websiteId);

            if (currentUser.roles !== undefined) {
                if (!currentUser.roles.includes("FE_NonVisualizzaNetto")) setEnableShowNetPrices(true);
                if (currentUser.roles.includes("MB_AddToOrder")) setUserAddToOrder(true);
            }

        } catch (ex) {
            setWebsiteId(configData.Settings.WebsiteId);
        }

        setIsLoading(true);
        console.log('dynamic step notes', dynamicNotesStep)
    }, []);

    useEffect(() => {
        setIsLoadingPrint(false);
        setIsLoadingPrintCliente(false);
        setIsLoadingPrintWithoutAlternatives(false)
        setIsLoadingPrintClientWithoutAlternatives(false)
        setIsLoadingPrintCommExplode(false);
    }, [isFinishPrintPDF]);

    useEffect(() => {
        if (!isShowAfterAllCall)
            setIsShowAfterAllCall(true);
    }, [isShowAfterAllCall]);

    function callGetQuotationDetail() {
        setIsLoading(true);

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: -1,
                quotationSettings: quotationSettings,
                authcode: authCode
            })

        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationdetail`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotation(item);
                setActualUserInfo({
                    username: item.userQuotation,
                    userId: item.userQuotationId,
                    agencyName: item.agencyName,
                    agencyId: item.agencyId
                });
                item?.ticketId ? setIsPrintCancelPolicy(false) : setIsPrintCancelPolicy(true)
                setUserIdWorkNow(item.userIdWorkNow);
                setQuotationHeader(
                    {
                        name: item.name,
                        ticketId: item.ticketId,
                        quotationId: item.quotationId,
                        agencyName: item.agencyName,
                        createDate: item.createDate,
                        tablePricing: item.tablePricing,
                        commentsTotal: item.chatTotal,
                        commentsToReadTotal: item.chatTotalToRead,
                        internalNotesTotal: item.totalInternalNotes,
                        status: item.status,
                        numeroOrdine: item.numeroOrdine,
                        isAgencyVisible: item.isAgencyVisible,
                        authorizationToSend: item.authorizationToSend,
                        authorizationStatus: item.authorizationStatus,
                        canAuthorization: item.canAuthorization,
                        isCommissionable: item.tablePricing.isCommissionable,
                        address: item.address,
                        province: item.province,
                        zipCode: item.zipCode,
                        city: item.city,
                        phone: item.phone
                    }
                );
                setQuotationMode(item.quotationMode);
                setQuotationAllowedOperation(item.allowedOperations);

                setTotalInternalNotes(item.totalInternalNotes);
                setQuotationRecapItems(item.recapItems);
                setQuotationTableMarginItems({
                    hasIVACee: item.tablePricing.hasIVACee,
                    tableMargin: item.tablePricing.tableMargin,
                    totalCommissionAmount: item.tablePricing.totalCommission,
                    totalCommissionable: item.tablePricing.totalCommissionable,
                    totalCommissionCurrency: item.tablePricing.currency,
                    totalNotCommissionableAmount: item.tablePricing.totalNotCommissionable,
                    totalNotCommissionableCurrency: item.tablePricing.currency
                });

                setQuotationItemStep(item.quotationItemStep);
                quotationItems = item.recapItems.filter(r => r.productType !== 'Generic');

                callGetScadenziario(item.userIdWorkNow);
                callGetCustomerInfoQuotation(item.agencyId);

                if (item.ticketId)
                    setIsQuotationTo(true);

                let mapQuotationItemInfo = [];
                let qItemIdsAlternative = [];

                if (isShowAlternatives && item.quotationItemStep && item.quotationItemStep.length > 0) {
                    item.quotationItemStep.forEach(async (step) => {
                        let tmpItem = [];

                        for (let i = 0; i < step.quotationItemInfo.length; i++) {
                            let qInfo = step.quotationItemInfo[i];

                            if (qInfo.isSelected) {
                                tmpItem.push({ id: qInfo.id, productType: qInfo.productType });
                                mapQuotationItemInfo.push({ id: qInfo.id, productType: qInfo.productType });
                            }
                        }
                        for (let i = 0; i < step.quotationItemInfo.length; i++) {
                            let qInfo = step.quotationItemInfo[i];
                            let father = tmpItem.find(x => x.productType === qInfo.productType);

                            if (!qInfo.isSelected && father) {
                                qItemIdsAlternative.push({
                                    id: qInfo.id,
                                    productType: qInfo.productType,
                                    fatherId: father.id
                                });
                            }
                        }
                    });

                    for (let i = 0; i < qItemIdsAlternative.length; i++) {
                        callGetAlternativesItems(qItemIdsAlternative[i].id, qItemIdsAlternative[i].fatherId);
                    }
                }

                if (item.recapItems && item.recapItems.length > 0) {
                    var myDates = item.recapItems.filter(r => r.productType !== 'Generic').map(function (myRecapItem) { return new Date(myRecapItem.dateFrom) });
                    var minDate = new Date(Math.min.apply(null, myDates))
                    setMinProductDate(minDate);

                    item.recapItems.filter(r => r.productType !== 'Generic').map(recapItem => {
                        totQuotationItems = item.recapItems.filter(r => r.productType !== 'Generic').length;
                        let tipoPratica;

                        if (recapItem.productType === "Structure")
                            tipoPratica = configData.Settings.Products.Structure.IdTipoProdotto;
                        else if (recapItem.productType === "Activity")
                            tipoPratica = configData.Settings.Products.Activity.IdTipoProdotto;
                        else if (recapItem.productType === "CarRental")
                            tipoPratica = configData.Settings.Products.CarRental.IdTipoProdotto;
                        else if (recapItem.productType === "Transfer")
                            tipoPratica = configData.Settings.Products.Transfer.IdTipoProdotto;
                        else if (recapItem.productType === "Flight")
                            tipoPratica = configData.Settings.Products.Flight.IdTipoProdotto;
                        else if (recapItem.productType === configData.Settings.Products.Train.Code)
                            tipoPratica = configData.Settings.Products.Train.IdTipoProdotto;
                        else if (recapItem.productType === "Cruise")
                            tipoPratica = configData.Settings.Products.Cruise.IdTipoProdotto;
                        else if (recapItem.productType === "Tour")
                            tipoPratica = configData.Settings.Products.Tour.IdTipoProdotto;
                            
                        if (recapItem.wsBookingId) {
                            const getReservation = async (inputData, itemId, step) => {
                                callGetReservationDetail(inputData, itemId, step);
                            }

                            getReservation({
                                PNR: recapItem.wsBookingId,
                                ProductType: tipoPratica,
                                Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl },
                            }, recapItem.id, recapItem.step);
                        }
                        else {
                            const getQuotationItem = async (inputData, step) => {
                                const response = callGetQuotationItem(inputData, step);
                            }
                            getQuotationItem(recapItem.id, recapItem.step);
                        }
                    });
                }
                
                setIsError(false);

                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setQuotation(null);
                setIsError(true);
                setIsLoading(false);
            });

    }
    async function callGetAlternativesItems(id, fatherId) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationitemalternative/${id}/${fatherId}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                item["quotationItemIdFather"] = fatherId;
                setQuotationAlternatives(arr => [...arr, item]);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
                setIsLoading(false);
            });
    }

    function callGetCustomerInfoQuotation(agencyId) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}customer/getcustomerinfo/${agencyId}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setCustomerInfoQuotation(item);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    async function callGetPaxes() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationpaxes/${quotationId}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationPaxes(item);
            })
            .catch((err) => {
                console.error(err);
            });
    }


    async function callGetQuotationItem(id, step) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationitem/${id}`, requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            getFinalArr(response, id, step);
        } else {
            totQuotationItems--;
        }

        setIsLoading(false);
    }

    async function callGetReservationDetail(inputData, itemId, step) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            getFinalArr(response, itemId, step);
        }
        else { // se resituisce errore togliere elimare praticha dal totale          
            totQuotationItems--;
        }

        setIsLoading(false);
    }

    function callGetScadenziario(_userIdWorkNow) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: _userIdWorkNow,
                quotationSettings: quotationSettings
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationscadenziario`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                let resultObj = item.deadlineGroups.reduce(function (r, a) {
                    let data = formatDateTime(a.deadlineDate, cultureName);
                    r[data] = r[data] || [];
                    r[data].push(a);

                    return r;
                }, Object.create(null));

                var resultArr = Object.entries(resultObj);

                setScadenziario(resultArr);
            })
            .catch((err) => {
                console.error(err);
            });
    }


    let isFirstFlight = true;
    function setFirstFlight(isFirst) {
        isFirstFlight = isFirst;
    }

    function getFinalArr(response, quotationItemid, step) {
        let productSubType = "";
        let idTipoProdotto = null;
        let productType = "";

        if (response.productType === configData.Settings.Products.Structure.IdTipoProdotto) {
            productType = "Structure";
            productSubType = "Hotel";
            idTipoProdotto = configData.Settings.Products.Structure.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.Tour.IdTipoProdotto) {
            productSubType = "Tour";
            productType = "Tour";
            idTipoProdotto = configData.Settings.Products.Tour.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.Activity.IdTipoProdotto) {
            productType = "Activity";
            if (response.productSubType === 12)
                productSubType = "Tour";
            else if (response.productSubType === 41)
                productSubType = "Transfer";
            else if (response.productSubType === 51)
                productSubType = "Flight";
            else
                productSubType = "Activity";

            idTipoProdotto = configData.Settings.Products.Activity.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.CarRental.IdTipoProdotto) {
            productSubType = "CarRental";
            productType = "CarRental";
            idTipoProdotto = configData.Settings.Products.CarRental.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.Transfer.IdTipoProdotto) {
            productSubType = "Transfer";
            productType = "Transfer";
            idTipoProdotto = configData.Settings.Products.Transfer.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.Flight.IdTipoProdotto) {
            productSubType = "Flight";
            productType = "Flight";
            idTipoProdotto = configData.Settings.Products.Flight.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.Train.IdTipoProdotto) {
            productSubType = "Train";
            productType = "Train";
            idTipoProdotto = configData.Settings.Products.Train.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.Cruise.IdTipoProdotto) {
            productSubType = "Cruise";
            productType = "Cruise";
            idTipoProdotto = configData.Settings.Products.Cruise.IdTipoProdotto;
        }
        else {
            productSubType = "Generic";
            productType = "Generic";
            idTipoProdotto = configData.Settings.Products.Generic.IdTipoProdotto;
        }

        if (response.priceBar &&
            (
                response.priceBar.status === "UNDEFINED" || // PREVENTIVO
                response.priceBar.status === "MANAGE" || // PREVENTIVO
                response.priceBar.status === "CONFIRMED" ||
                response.priceBar.status === "ONREQUEST" ||
                response.priceBar.status === "PRINTED" ||
                response.priceBar.status === "REFUND" ||
                response.priceBar.status === "PENALTY" ||
                (response.priceBar.status === "MIX" && response.productType === configData.Settings.Products.Structure.IdTipoProdotto)
            )
        ) {
            arrProduct.push({
                productSubType: productSubType,
                productInfo: response,
                productType: productType,
                quotationItemId: quotationItemid
            });
        }
        else {
            totQuotationItems--;
        }
        if (totQuotationItems === arrProduct.length) {
            if (arrProduct.length > 0) {

                let orderArr = [];
                // ordina array
                quotationItems.map((item, index) => {
                    for (let i = 0; i < arrProduct.length; i++) {
                        if (arrProduct[i].quotationItemId === item.id) {
                            orderArr.push(arrProduct[i]);
                            break;
                        }
                    }
                });

                let finalArr = getFinalOrderArr(orderArr);
                setItemStepPrint(finalArr);
            }
            else {
                setIsError(true);
            }
        }


        if (arrProduct.length === 0) {
            setIsError(true);
        }
        const getDynamicNote = async (inputData) => {
            const response = callGetDynamicNote(inputData);
        }

        getDynamicNote({
            cultureCode: cultureName,
            websiteId: websiteId,
            pageId: 14,
            styleId: 4,
            productTypes: [idTipoProdotto],
            productCode: response.productId,
            checkInFrom: response.priceBar ? response.priceBar.startDate : null,
            checkInTo: response.priceBar ? response.priceBar.endDate : null,
            webDestinationIds: response.webDestinationIds ? response.webDestinationIds : null,
            supplierId: response.supplierId ? response.supplierId : null,
        });

        const getDynamicNoteTop = async (inputData) => {
            const response = callGetDynamicNoteTop(inputData);
        }

        getDynamicNoteTop({
            cultureCode: cultureName,
            websiteId: websiteId,
            pageId: 14,
            styleId: 3,
            productTypes: [idTipoProdotto],
            productCode: response.productId,
            checkInFrom: response.priceBar ? response.priceBar.startDate : null,
            checkInTo: response.priceBar ? response.priceBar.endDate : null,
            webDestinationIds: response.webDestinationIds ? response.webDestinationIds : null,
            supplierId: response.supplierId ? response.supplierId : null,
        });

        const getDynamicNoteStep = async (inputData, step) => {
            const response = callGetDynamicNoteStep(inputData, step);
        }

        getDynamicNoteStep({
            cultureCode: cultureName,
            websiteId: websiteId,
            pageId: 14,
            styleId: 5,
            productTypes: [idTipoProdotto],
            productCode: response.productId,
            checkInFrom: response.priceBar ? response.priceBar.startDate : null,
            checkInTo: response.priceBar ? response.priceBar.endDate : null,
            webDestinationIds: response.webDestinationIds ? response.webDestinationIds : null,
            supplierId: response.supplierId ? response.supplierId : null,
        }, step);
    }

    async function callGetDynamicNote(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();
        
        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
            setDynamicNotes(arr => [...arr, response.dynamicNotes]);
        }
    }

    async function callGetDynamicNoteTop(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
            setDynamicNotesTop(arr => [...arr, response.dynamicNotes]);
        }
    }

    const renderedSubTypes = new Set();
    async function callGetDynamicNoteStep(inputData, step) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {

            for (var i = 0; i < response.dynamicNotes.length; i++) {
                response.dynamicNotes[i]["step"] = step;

                if (tmpDynamicNotesStep.length > 0 && tmpDynamicNotesStep.find(x => x.noteId === response.dynamicNotes[i].noteId && x.step === step)) {
                }
                else {
                    tmpDynamicNotesStep.push(response.dynamicNotes[i]);
                }
            }

            // order by priority
            tmpDynamicNotesStep.sort(function (a, b) {
                return a.priority - b.priority;
            });

            setDynamicNotesStep(tmpDynamicNotesStep);

            setIsShowAfterAllCall(false);
        }
    }


    var idArrNote = [];
    function showTextStep(note) {
        let hideText = false;

        if (!idArrNote.find(x => x.id === note.noteId && note.step === x.step)) {
            idArrNote.push({ id: note.noteId, step: note.step });
        }
        else {
            hideText = true;
        }

        return hideText;
    }

    var showImage = true;
    function getShowImageStep() {
        return showImage;
    }

    function setShowImageStep(isShow) {
        showImage = isShow;
    }
    function dowloadPDFAfterRender(filename, url) {
        downloadDocumentPDF(filename, url);

        setIsFinishPrintPDF(isFinishPrintPDF + 1);
        setIsDownloadPDF(true);
    }

    function onClickPrintPDF() {
        setPdfFileName(t("Print:Quotation") + "_" + t("Print:Agency") + "_" + quotationId);
        setIsLoadingPrint(true);
        callPrintPDF();
    }

    function onClickPrintPDFExplodeTable() {
        setPdfFileName(t("Print:Quotation") + "_" + t("Print:Agency") + "_" + t("Print:Explode") + "_" + quotationId);
        setIsLoadingPrintCommExplode(true);
        callPrintPDF(true, true);
    }

    function onClickPrintPDFCliente() {
        setPdfFileName(t("Print:Quotation") + "_" + t("Print:Client") + "_" + quotationId);
        setIsLoadingPrintCliente(true);
        callPrintPDFCliente();
    }

    function onClickPrintPDFWithoutAlternatives() {
        setPdfFileName(t("Print:Quotation") + "_" + t("Print:Agency") + "_" + t("Print:WithoutAlternatives") + "_" + quotationId);
        setIsLoadingPrintWithoutAlternatives(true);
        callPrintPDF(false);
    }

    function onClickPrintPDFClientWithoutAlternatives() {
        setPdfFileName(t("Print:Quotation") + "_" + t("Print:Client") + "_" + t("Print:WithoutAlternatives") + "_" + quotationId);
        setIsLoadingPrintClientWithoutAlternatives(true);
        callPrintPDFCliente(false);
    }

    // verifica se esite qualche valore
    function checkIfExistValues(recapItems) {
        let ok = recapItems.filter((item) => item.productType === "Generic")
        if (ok.length > 0)
            return true;
        else
            return false;
    }

    function callPrintPDF(showAlternativesFromPrintButton = true, showCommissionDetailExplode = false) {
        let myArr = generateQuotationHTML(
            t,
            quotationRecapItems,
            quotation,
            itemStepPrint,
            paxes,
            scadenziario,
            dynamicNotes,
            dynamicNotesStep,
            dynamicNotesTop,
            quotationAlternatives,
            getCancelPolicyArr(),
            getNotePorductBigArr(),
            cultureName,
            false,
            isPrintHeaderDesc,
            isPrintItineraryResume,
            isPrintPaxes,
            isPrintAncillaries,
            isPrintInclusions,
            isPrintCommissions,
            isPrintServicesNotSelected,
            (isShowAlternatives && showAlternativesFromPrintButton) ? true : false,
            isPrintScadenziario,
            isPrintDynamicNotes,
            isPrintNotes,
            isPrintCancelPolicy,
            isPrintNoteBig,
            isQuotationTo,
            showCommissionDetailExplode = showCommissionDetailExplode
        );
        console.log(myArr)

        setIsDownloadPDF(false);
        setMyHtml(myArr);
        setExtraInfoPrint({
            quotationId: quotationId,
            titleFirstPage: t("Print:QuotationTitle"),
            isPrintCliente: false,
            numberLabel: t("MyBook:NQuotation"),
            name: quotationHeader.name,
            date: quotationHeader.createDate,
            agencyName: quotationHeader.agencyName,
            address: quotationHeader.address,
            quotationHeader: quotationHeader
        });
    }

    function callPrintPDFCliente(showAlternativesFromPrintButton = true) {
        let myArr = generateQuotationHTML(
            t,
            quotationRecapItems,
            quotation,
            itemStepPrint,
            paxes,
            scadenziario,
            dynamicNotes,
            dynamicNotesStep,
            dynamicNotesTop,
            quotationAlternatives,
            getCancelPolicyArr(),
            getNotePorductBigArr(),
            cultureName,
            true,
            isPrintHeaderDesc,
            isPrintItineraryResume,
            isPrintPaxes,
            isPrintAncillaries,
            isPrintInclusions,
            isPrintCommissions,
            isPrintServicesNotSelected,
            (isShowAlternatives && showAlternativesFromPrintButton) ? true : false,
            isPrintScadenziario,
            isPrintDynamicNotes,
            isPrintNotes,
            isPrintCancelPolicy,
            isPrintNoteBig,
            isQuotationTo
        );

        setIsDownloadPDF(false);
        setMyHtml(myArr);
        setExtraInfoPrint({
            quotationId: quotationId,
            titleFirstPage: t("Print:QuotationClientTitle"),
            isPrintCliente: true,
            numberLabel: t("MyBook:NQuotation"),
            name: quotationHeader.name,
            date: quotationHeader.createDate,
            agencyName: quotationHeader.agencyName
        });
    }

    var isFirstProgrammaDiViaggio = true;
    function getIsFirstProgrammaDiViaggio() {
        return isFirstProgrammaDiViaggio;
    }

    function setProgrammaDiViaggioFalse() {
        if (isFirstProgrammaDiViaggio)
            isFirstProgrammaDiViaggio = false;
    }


    function getCancelPolicyArr() {
        let cancelPolicyArr = [];
        if (quotation && quotation.quotationItemStep && quotation.quotationItemStep.length > 0) {
            quotation.quotationItemStep.map((step, stepKey) => {
                if (step.quotationItemInfo && step.quotationItemInfo.length > 0) {
                    step.quotationItemInfo.map((item, itemKey) => {
                        let qInfo = itemStepPrint.find(c => c.quotationItemId === item.id);
                        setCancelPolicy(qInfo, cancelPolicyArr, t)
                    })
                }
            })
        }
        return cancelPolicyArr;
    }

    function getNotePorductBigArr() {
        var arrNotesProdcuts = [];

        if (quotation && quotation.quotationItemStep && quotation.quotationItemStep.length > 0) {
            quotation.quotationItemStep.map((step, stepKey) => {
                if (step.quotationItemInfo && step.quotationItemInfo.length > 0) {
                    step.quotationItemInfo.map((item, itemKey) => {
                        let qInfo = itemStepPrint.find(c => c.quotationItemId === item.id);
                        setNoteProductBig(qInfo, arrNotesProdcuts);
                    })
                }
            })
        }
        return arrNotesProdcuts;
    }

    /*function getTaxesFlightArr() {
        var arrTaxes = [];

        if (quotation && quotation.quotationItemStep && quotation.quotationItemStep.length > 0) {
            quotation.quotationItemStep.map((step, stepKey) => {
                if (step.quotationItemInfo && step.quotationItemInfo.length > 0) {
                    step.quotationItemInfo.map((item, itemKey) => {
                        let qInfo = itemStepPrint.find(c => c.quotationItemId === item.id);
                        if (qInfo.productType === "Flight")
                            setFlightTaxesArr(qInfo.productInfo, arrTaxes);
                    })
                }
            })
        }

        return arrTaxes;
    }*/

    var idArrNote = [];
    function showTextStep(note) {
        let hideText = false;
        if (!idArrNote.find(x => x.id === note.noteId && note.step === x.step)) {
            idArrNote.push({ id: note.noteId, step: note.step });
        }
        else {
            hideText = true;
        }

        return hideText;
    }

    var showImage = true;
    function getShowImageStep() {
        return showImage;
    }

    function setShowImageStep(isShow) {
        showImage = isShow;
    }

    return (
        <>
            <section className="confirmBookingSection bg-gray section bg-soft section-lg pt-5">
                {itemStepPrint !== undefined && itemStepPrint !== null && itemStepPrint.length !== 0 ?
                    <div className="container">
                        <div className="row document mb-3">
                            <div className="col-2">
                                <h4>{t(`Print:Quotation`)}</h4>
                            </div>
                            {(quotationMode === 'QuotationTOWorking' || quotationMode === 'QuotationWorking') &&
                                <>
                                    <div className="col-2 text-right">
                                        {/* button download pdf */}
                                        <a className="btn btn-secondary stepHeader text-white" onClick={onClickPrintPDF} >
                                            {!isLoadingPrint && <>
                                                <span className="far fa-file-pdf mr-2"></span>{t(`Button:Print`)}
                                            </>}
                                            {
                                                isLoadingPrint && <>
                                                    <div className="spinner-border spinner-border-sm text-white h7" role="status">
                                                    </div>
                                                    <span className="sr-only">Loading...</span>
                                                </>
                                            }
                                        </a>
                                    </div>
                                    {/*
                                        <div className="col-2 text-right pr-6">
                                        <a className="btn btn-secondary stepHeader text-white ml-3" onClick={onClickPrintPDFExplodeTable} >
                                            {!isLoadingPrintCommExplode && <>
                                                <span className="far fa-file-pdf mr-2"></span>{t(`Button:PrintCommissionExpode`)}
                                            </>}
                                            {
                                                isLoadingPrintCommExplode && <>
                                                    <div className="spinner-border spinner-border-sm text-white h7" role="status">
                                                    </div>
                                                    <span className="sr-only">Loading...</span>
                                                </>
                                            }
                                        </a>
                                    </div>
                                    */}
                                    <div className="col-2 text-center">
                                        {/* button download pdf */}
                                        <a className="btn btn-secondary stepHeader text-white" onClick={onClickPrintPDFWithoutAlternatives} >
                                            {!isLoadingPrintWithoutAlternatives && <>
                                                <span className="far fa-file-pdf mr-2"></span>{t(`Button:PrintWithoutAlternatives`)}
                                            </>}
                                            {
                                                isLoadingPrintWithoutAlternatives && <>
                                                    <div className="spinner-border spinner-border-sm text-white h7" role="status">
                                                    </div>
                                                    <span className="sr-only">Loading...</span>
                                                </>
                                            }
                                        </a>
                                    </div>
                                </>
                            }
                            {(quotationMode === 'QuotationTOWorking' || quotationMode === 'QuotationTOEndUser' || quotationMode === 'QuotationWorking' || quotationMode === 'QuotationEndUser') &&
                                <>
                                    <div className="col-2 text-center">
                                        <a className="btn btn-secondary stepHeader text-white" onClick={onClickPrintPDFCliente} >
                                            {!isLoadingPrintCliente && <>
                                                <span className="far fa-file-pdf mr-2"></span>{t(`Button:PrintClient`)}
                                            </>}
                                            {
                                                isLoadingPrintCliente && <>
                                                    <div className="spinner-border spinner-border-sm text-white h7" role="status">
                                                    </div>
                                                    <span className="sr-only">Loading...</span>
                                                </>
                                            }
                                        </a>
                                    </div>
                                    {
                                        !authCode && <div className="col-2 text-center">
                                            {/* button download pdf */}
                                            <a className="btn btn-secondary stepHeader text-white" onClick={onClickPrintPDFClientWithoutAlternatives} >
                                                {!isLoadingPrintClientWithoutAlternatives && <>
                                                    <span className="far fa-file-pdf mr-2"></span>{t(`Button:PrintClientWithoutAlt`)}
                                                </>}
                                                {
                                                    isLoadingPrintClientWithoutAlternatives && <>
                                                        <div className="spinner-border spinner-border-sm text-white h7" role="status">
                                                        </div>
                                                        <span className="sr-only">Loading...</span>
                                                    </>
                                                }
                                            </a>
                                        </div>
                                    }
                                </>
                            }
                            {!isDownloadPDF && <PDFDownloadLink className="lnkPDFDownload" document={<PrintPdfDocuments html={myHtml} agency={quotationHeader.agencyName} stylesheet={printCssStyle} extraInfo={extraInfoPrint} />} fileName={pdfFileName} >
                                {({ blob, url, loading, error }) => {
                                    if (!loading && blob) {
                                        dowloadPDFAfterRender(pdfFileName, URL.createObjectURL(blob));
                                    }
                                }}
                            </PDFDownloadLink>
                            }
                        </div>
                        <div className="card border-light p-4 p-md-5 position-relative">
                            <HeaderPDFPrint agency={quotationHeader.agencyName} extraInfo={{ numberLabel: t("MyBook:NQuotation"), quotationId: quotationId }} isNotPrint={true} />

                            {template && template.photoGallery && template.photoGallery.items && template.photoGallery.items[0] &&
                                <TemplatePrintImageHeader
                                    image={template.photoGallery.items[0].url}
                                    onHandleIsPrint={setIsPrintHeaderImage}
                                />
                            }

                            {/*quotation && quotation.name && isQuotationTo && // solo se Preventivo to
                                <div className="mt-3" style={{ display: 'grid' }}>
                                    <TemplatePrintHeaderDescription
                                        title={quotation.name}
                                        description={quotation.description}
                                        onHandleIsPrint={setIsPrintHeaderDesc}
                                    />
                                </div>
                            */}

                            {/* note dinamiche top 
                            <div className="mt-3" style={{ display: 'grid' }}>
                                {dynamicNotesTop && dynamicNotesTop.length > 0 &&
                                    <PrintDynamicNotes
                                        notes={dynamicNotesTop}
                                        cultureName={cultureName}
                                        onHandleIsPrint={setIsPrintDynamicNotesTop}
                                    />
                                }
                            </div>
                            */}

                            {/* passeggeri */}
                            {paxes && paxes.length > 0 &&
                                <>
                                    <div className="mt-3" style={{ display: 'grid' }}>
                                        <PrintPaxes
                                            paxes={paxes}
                                            isConfirmPrint={false}
                                            onHandleIsPrint={setIsPrintPaxes}
                                        />
                                    </div>
                                </>
                            }

                            {itemStepPrint.map(qInfo => <>
                                {qInfo.productType === 'Flight' && isFirstFlight &&
                                    <>
                                        {setFirstFlight(false)}
                                        <div className="mb-3 mt-5" style={{ display: 'grid' }}>
                                            <TemplatePrintStepHeader
                                            title={t('Product:Flight:Items')}
                                                PNR={qInfo.productInfo.priceBar.supplierPNR}
                                                totaleViaggio=''
                                                iconName=''
                                                cultureName={cultureName}
                                            />
                                        </div>
                                    </>
                                }
                                {/* prodotti */}
                                {qInfo.productType === 'Flight' &&
                                    <div className="" style={{ display: 'grid' }}>
                                        <PrintStepItem
                                            qInfo={qInfo}
                                            isPrintCliente={false}
                                            isNotesHide={false}
                                            dynamicNotesDistinct={dynamicNotes}
                                            isNotStep={false}
                                            isConfirmPrint={false }
                                        />
                                    </div>
                                }

                            </>
                            )}

                            {isShowAlternatives && quotationAlternatives.length > 0 &&
                                <div style={{ display: 'grid' }}>
                                    <PrintStepItemAlternatives
                                        alternativeArr={quotationAlternatives.filter(c => c.productType === "Flight")}
                                        isPrintCliente={false}
                                        isTemplate={true}
                                        isConfirmPrint={false}
                                    />
                                </div>
                            }

                            {/* itinerario */}
                            <PrintItineraryResume
                                paxes={paxes}
                                onHandleIsPrint={setIsPrintItineraryResume}
                                isConfirmPrint={false}
                                isNotStep={false}
                            />

                            {/* template */}
                            {isShowAfterAllCall && quotation && quotation.quotationItemStep && quotation.quotationItemStep.length > 0 && quotation.quotationItemStep.map((step, stepKey) =>

                                <>
                                    <div className="" style={{ display: 'grid' }}>
                                        {step.quotationItemInfo && step.quotationItemInfo.length > 0 && step.quotationItemInfo.filter(x => x.productType !== "Flight" && x.productType !== "Generic").map((item, itemKey) =>
                                            <>
                                                {itemKey === 0 &&
                                                    <>
                                                        <div className="mb-2" style={{ display: 'grid' }}>
                                                            <TemplatePrintStepHeader
                                                                key={stepKey}
                                                                title={step.stepName ? step.stepName : step.stepDestinationName}
                                                                isStep={true}
                                                                stepIndex={step.step}
                                                                minDate={step.dateFrom}
                                                                maxDate={step.dateTo}
                                                            />
                                                        </div>
                                                        {setShowImageStep(true)}
                                                        {dynamicNotesStep.map(note =>
                                                            note.step === step.step &&
                                                            <>
                                                                <div className="mb-3" style={{ display: 'grid' }}>
                                                                    <PrintProductsNotesStep
                                                                        notes={[note]}
                                                                        cultureName={cultureName}
                                                                        isShowImage={getShowImageStep()}
                                                                        hideText={showTextStep(note)}
                                                                    />
                                                                    {setShowImageStep(false)}
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                }
                                                <PrintStepItem
                                                    key={itemKey}
                                                    qInfo={itemStepPrint.find(c => c.quotationItemId === item.id)}
                                                    isPrintCliente={false}
                                                    isTemplate={true}
                                                    isConfirmPrint={false}
                                                    isPrintPdf={false}
                                                    isNotStep={false}
                                                    isQuotationTo={isQuotationTo}
                                                />

                                                {isShowAlternatives && quotationAlternatives.filter(c => c.quotationItemIdFather === item.id && c.productType === item.productType).length > 0 &&
                                                    <div style={{ display: 'grid' }}>
                                                        <PrintStepItemAlternatives
                                                            key={itemKey}
                                                            alternativeArr={quotationAlternatives.filter(c => c.quotationItemIdFather === item.id)}
                                                            isPrintCliente={false}
                                                            isTemplate={true}
                                                            isConfirmPrint={false}
                                                        />
                                                    </div>
                                                }
                                            </>
                                        )}
                                    </div>
                                </>
                            )}

                            {/* titolo quotazione 
                            <div className="mt-3" style={{ display: 'grid' }}>
                                <PrintTitleSeparator
                                    title={t(`Template:Quotation`)}
                                />
                            </div>
                            */}
                            {/* quote accessorie 
                            {checkIfExistValues(quotationRecapItems) &&
                                <>
                                    <div className="mt-3" style={{ display: 'grid' }}>
                                        <PrintAncillaries
                                            recapItems={quotationRecapItems}
                                            cultureName={cultureName}
                                            isConfirmPrint={false}
                                            onHandleIsPrint={setIsPrintAncillaries}
                                        />
                                    </div>
                                </>
                            */}

                            {/* Tabella Prezzi */}
                            <div className="mt-3" style={{ display: 'grid' }}>
                                <PrintCommissionsDetailCompact
                                    marginItems={quotationRecapItems}
                                    cultureName={cultureName}
                                    paxes={paxes}
                                    onHandleIsPrint={setIsPrintCommissions}
                                    isConfirmPrint={false}
                                    isPrintCliente={authCode ? true : false}
                                />
                            </div>
                            {/* Scadenziario
                            <div className="mt-3" style={{ display: 'grid' }}>
                                <PrintScadenziario
                                    scadenziario={scadenziario}
                                    cultureName={cultureName}
                                    onHandleIsPrint={setIsPrintScadenziario}
                                />
                            </div>
                             */}

                            {/* quota comprende, non comprende 
                            {template && template.inclusions && template.inclusions.length > 0 &&
                                template.inclusions.map(inclusion =>
                                    <div className="mt-3 card bg-white p-2 shadow" style={{ display: 'grid' }}>
                                        <TemplatePrintInclusion
                                            inclusion={inclusion}
                                            onHandleIsPrint={setIsPrintInclusions}
                                        />
                                    </div>
                                )
                            */}

                            {/* Cancel policy  */
                                 !quotationHeader?.ticketId && <div className = "mt-3" style = {{ display: 'grid' } } >
                                <PrintCancelPolicy
                                    cancelPolicyArr={getCancelPolicyArr()}
                                    cultureName={cultureName}
                                    onHandleIsPrint={setIsPrintCancelPolicy}
                                />
                            </div> 
                            }
                            {/* note big (nota pubblica)  */}
                            <div className="mt-3" style={{ display: 'grid' }}>
                                <PrintProductsNotes
                                    arrNotesProdcuts={getNotePorductBigArr()}
                                    onHandleIsPrint={setIsPrintNoteBig}
                                />
                            </div>

                            {/* programma di viaggio, note extra */}

                            {itemStepPrint.sort((a, b) => {
                                if (a.productSubType < b.productSubType) {
                                    return -1;
                                }
                                if (a.productSubType > b.productSubType) {
                                    return 1;
                                }
                                return 0;
                            }).map(qInfo => {

                                const shouldRenderLogo = !renderedSubTypes.has(qInfo.productSubType);
                                if (shouldRenderLogo) {
                                    renderedSubTypes.add(qInfo.productSubType);
                                }

                                return <>
                                    {qInfo.productType === 'Activity' && qInfo.productInfo.option.descriptions.length > 0 &&
                                        ((qInfo.productInfo.quotationDetail != null && qInfo.productInfo.quotationDetail.isManualLoading) || qInfo.productInfo.priceBar.isDMC) &&
                                        <>
                                            {shouldRenderLogo && (
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <img src={getIconUrlToPrint(qInfo.productSubType.toLowerCase())} className="ico-image-extra-large" />
                                                    <div style={{ width: "6px" }}></div>
                                                    <div style={{ display: "flex" }}>
                                                        <div style={{ height: "12px" }}></div>
                                                        {qInfo.productSubType}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="mt-3" style={{ display: 'grid' }}>
                                                <PrintStepHeader
                                                    productSubType={qInfo.productSubType}
                                                    specefiche='SPECIFICHE ESCURSIONE'
                                                    title={qInfo.productInfo.name}
                                                    PNR=''
                                                    totaleViaggio=''
                                                    productIcon={true}
                                                    iconName={qInfo.productSubType}
                                                    cultureName={cultureName}
                                                    onHandleIsPrint={setIsPrintNotes}
                                                    isShowPrintButton={getIsFirstProgrammaDiViaggio()}
                                                />
                                            </div>
                                            {setProgrammaDiViaggioFalse()}
                                            {qInfo.productInfo.option.descriptions.filter(x => x.title !== "VOUCHER INFO").map((descToPrint, idx) =>
                                                <TourStaticPrint
                                                    titleStep={qInfo.productInfo.name}
                                                    descriptions={replaceAllStyleElements(descToPrint.description)}
                                                    title={descToPrint.title}
                                                    tourName={idx === 0 ? qInfo.productInfo.name : ''}
                                                />
                                            )}
                                        </>
                                    }

                                    {qInfo.productType === 'Structure' && qInfo.productInfo.descriptions.length > 0 &&
                                        ((qInfo.productInfo.quotationDetail != null && qInfo.productInfo.quotationDetail.isManualLoading) || qInfo.productInfo.priceBar.isDMC) &&
                                        <>
                                            {shouldRenderLogo && (
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <img src={getIconUrlToPrint(qInfo.productSubType.toLowerCase())} className="ico-image-extra-large" />
                                                    <div style={{ width: "6px" }}></div>
                                                    <div style={{ display: "flex" }}>
                                                        <div style={{ height: "12px" }}></div>
                                                        {qInfo.productSubType}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="mt-3" style={{ display: 'grid' }}>
                                                <PrintStepHeader
                                                    title={qInfo.productInfo.name}
                                                    PNR=''
                                                    totaleViaggio=''
                                                    productIcon={true}
                                                    iconName={qInfo.productSubType}
                                                    cultureName={cultureName}
                                                    onHandleIsPrint={setIsPrintNotes}
                                                    isShowPrintButton={getIsFirstProgrammaDiViaggio()}
                                                />
                                            </div>
                                            {setProgrammaDiViaggioFalse()}
                                            {qInfo.productInfo.descriptions.filter(x => x.isPrint).map((descToPrint, idx) =>
                                                <TourStaticPrint
                                                    titleStep={qInfo.productInfo.name}
                                                    descriptions={replaceAllStyleElements(descToPrint.description)}
                                                    title={descToPrint.title}
                                                    tourName={idx === 0 ? qInfo.productInfo.name : ''}
                                                />
                                            )}
                                            {qInfo.productInfo.rooms && (
                                                <>
                                                    {qInfo.productInfo.rooms.map((item, index) => item.notes.length > 0 && (
                                                        <React.Fragment key={item.id}>
                                                            <PrintStepHeader
                                                                title={`Note Camera ${index + 1}`}
                                                                PNR=""
                                                                totaleViaggio=""
                                                                iconName="info_hotel"
                                                                cultureName={cultureName}
                                                                onHandleIsPrint={setIsPrintNotes}
                                                                isShowPrintButton={getIsFirstProgrammaDiViaggio()}
                                                            />
                                                            {setProgrammaDiViaggioFalse()}
                                                            <TourStaticPrint
                                                                titleStep={qInfo.productInfo.name}
                                                                descriptions={replaceAllStyleElements(item.notes.join(" <br/> "))}
                                                                title={"Note Servizio"}
                                                            />
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    }

                                    {qInfo.productType === 'Tour' && qInfo.productInfo.descriptions.length > 0 &&
                                        ((qInfo.productInfo.quotationDetail != null && qInfo.productInfo.quotationDetail.isManualLoading) || qInfo.productInfo.priceBar.isDMC) &&
                                        <>
                                           
                                            <div className="mt-3" style={{ display: 'grid' }}>
                                                <PrintStepHeader
                                                    title={qInfo.productInfo.name}
                                                    PNR=''
                                                    totaleViaggio=''
                                                    productIcon={true}
                                                    iconName={qInfo.productSubType}
                                                    cultureName={cultureName}
                                                    onHandleIsPrint={setIsPrintNotes}
                                                    isShowPrintButton={getIsFirstProgrammaDiViaggio()}
                                                />
                                            </div>
                                            {setProgrammaDiViaggioFalse()}
                                            {qInfo.productInfo.descriptions.filter(x => x.isPrint).map((descToPrint, idx) =>
                                                <TourStaticPrint
                                                    titleStep={qInfo.productInfo.name}
                                                    descriptions={replaceAllStyleElements(descToPrint.description)}
                                                    title={descToPrint.title}
                                                    tourName={idx === 0 ? qInfo.productInfo.name : ''}
                                                />
                                            )}
                                        </>
                                    }
                                </>
                            })}
                            {/* note dinamiche */}
                            {dynamicNotes && dynamicNotes.length > 0 && <>
                                <div className="mt-3" style={{ display: 'grid' }}>
                                    <PrintDynamicNotes
                                        notes={dynamicNotes}
                                        cultureName={cultureName}
                                        onHandleIsPrint={setIsPrintDynamicNotes}
                                    />
                                </div>
                            </>
                            }
                        </div>
                    </div>
                    :
                    !isError ?
                        <Loading textMsg={t('MyBook:Loading')} />
                        :
                        <div className="text-center h4">
                            <Error textMsg={t('Error:Quotation')} />
                        </div>
                }
            </section>
        </>
    );
}
