import ReactDOMServer from "react-dom/server";
import { generateComponentInBody, replaceAllStyleElements, dynamicNotesDistincts, getIconUrlToPrint } from "../../../js/PrintUtility";
import { formatPrice } from '../../../js/Utils.js';
import { PrintStepHeader } from "../components/PrintStepHeader";
import { PrintStepItem } from "../components/PrintStepItem";
import { TourStaticPrint } from "../components/TourStaticPrint";
import { PrintProductsNotes } from "../components/PrintProductsNotes";
import { PrintCommissionsDetailCompact } from "../components/PrintCommissionsDetailCompact";
import { PrintCommissionsDetail } from "../components/PrintCommissionsDetail";
import { PrintPaxes } from "../components/PrintPaxes";
import { PrintDynamicNotes } from "../components/PrintDynamicNotes";
import { PrintTitle } from "../components/PrintTitle";
import { TemplatePrintInclusion } from "../template/TemplatePrintInclusion";
import { TemplatePrintStepHeader } from "../template/TemplatePrintStepHeader";
import { PrintItineraryResume } from "../components/PrintItineraryResume";
import { TemplatePrintImageHeader } from "../template/TemplatePrintImageHeader";
import { TemplatePrintHeaderDescription } from "../template/TemplatePrintHeaderDescription";
import { PrintStepItemAlternatives } from "../components/PrintStepItemAlternatives";
import { PrintAncillariesNotSelected } from "../components/PrintAncillariesNotSelected";
import { PrintScadenziario } from "../components/PrintScadenziario";
import { PrintCancelPolicy } from "../components/PrintCancelPolicy";
import { PrintProductsNotesStep } from '../components/PrintProductsNotesStep';
import configData from "../../../appsettings.json";


export function generateQuotationHTMLDocViaggio(
    itemStepPrint,
    bookDate,
    t,
    paxes,
    dynamicNotes,
    dynamicNotesTop,
    dynamicNotesStep,
    confirmMarginItems,
    praticheSteps,
    template,
    scadenziario,
    cancelPolicyArr,
    noteProdottoBigArr,
    taxesFlightArr,
    notesPratica,
    cultureName,
    isPrintCliente = false,
    isPrintHeaderDesc,
    isPrintItineraryResume,
    isPrintPaxes,
    isPrintAncillaries,
    isPrintCommissions,
    isPrintInclusions,
    isPrintScadenziario,
    isPrintCancelPolicy,
    isPrintNoteBig,
    isPrintExtraNotes,
    isPrintDynamicNotes,
    isPrintNotes,
    showCommissionDetailExplode
) {
    let myArr = [];

    let isFirstFlight = true;
    let isFirstService = true;
    let rowTitle = ReactDOMServer.renderToString(<PrintTitle createdDate={bookDate} isPrintCliente={isPrintCliente} title={t("Print:ConfirmBookingTitle")} titleCliente={t("Print:ConfirmBookingClientTitle")} />);

    myArr.push({ html: generateComponentInBody(rowTitle), break: false, wrap: true });

    if (template && template.photoGallery && template.photoGallery.items && template.photoGallery.items[0]) {
        let rowImageHeader = ReactDOMServer.renderToString(<TemplatePrintImageHeader
            image={template.photoGallery.items[0].url}
        />);

        //myArr.push({ html: generateComponentInBody(rowImageHeader), break: false, wrap: true });
    }

    if (template) {
        let rTitleDescription = ReactDOMServer.renderToString(<TemplatePrintHeaderDescription
            title={template.name}
            description={template.description}
        />);

        myArr.push({ html: generateComponentInBody(rTitleDescription), break: false, wrap: true });
    }
    /*
    if (dynamicNotesTop && dynamicNotesTop.length > 0) {
        let rowDynamicNotesTop = ReactDOMServer.renderToString(<PrintDynamicNotes
            notes={dynamicNotesTop}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowDynamicNotesTop), break: false, wrap: true });
    }
    */

    let isBreakAfter = false;

    /* passeggeri */
    if (isPrintPaxes && paxes && paxes.length > 0) {
        let rPaxes = ReactDOMServer.renderToString(<PrintPaxes paxes={paxes} isConfirmPrint={true} />);

        myArr.push({ html: generateComponentInBody(rPaxes), break: false, wrap: true });

        isBreakAfter = true;
    }

    /* itinerario 
    if (isPrintItineraryResume) {
        let itineraryResume_ = ReactDOMServer.renderToString(<PrintItineraryResume
            paxes={paxes}
            isConfirmPrint={true}
            isNotStep={template ? false : true}
        />);

        myArr.push({ html: generateComponentInBody(itineraryResume_), break: isBreakItinerary, wrap: true });
    }
    */
    
    var finalArr = itemStepPrint;
    var idArrNote = [];

    for (var j = 0; j < finalArr.length; j++) {
        let qInfo = finalArr[j];

        // crea intestazione/titolo tipo di servizio, lo crea una volta sola
        if (qInfo.productType === 'Flight' && isFirstFlight) {
            let rowStep = ReactDOMServer.renderToString(<TemplatePrintStepHeader
                title={t('Product:Flight:Items')}
                PNR=""
                cultureName={cultureName}
            />);

            myArr.push({ html: generateComponentInBody(rowStep), break: isBreakAfter, wrap: true });

            isFirstFlight = false;
        }

        if (qInfo && qInfo.productType === 'Flight') {
            /* Prodotti  */
            let rowQInfo = ReactDOMServer.renderToString(<PrintStepItem
                qInfo={qInfo}
                isConfirmPrint={false}
                isDocViaggio={true}
                isPrintCliente={isPrintCliente}
            />);

            myArr.push({ html: generateComponentInBody(rowQInfo), break: false, wrap: true });
        }
    }
    myArr.push({
        html:
            generateComponentInBody(ReactDOMServer.renderToString(<>Itinerario</>)), break: true, wrap: false
    })


    if (praticheSteps && praticheSteps.length > 0) {
        praticheSteps.forEach(function (step, stepKey) {
            itemStepPrint.filter(x => x.productInfo.stepId === step.step && x.productType !== "Flight").map((item, itemKey) => {
                if (itemKey === 0) {
                    let rowTemplateStepHeader = ReactDOMServer.renderToString(<TemplatePrintStepHeader
                        key={step.step}
                        title={step.stepDestinationName}
                        isStep={true}
                        stepIndex={step.step}
                        itemSteps={finalArr}
                    />);

                    myArr.push({ html: generateComponentInBody(rowTemplateStepHeader), break: false, wrap: true });

                    if (dynamicNotesStep && dynamicNotesStep.length > 0) {
                        var showImage = true;
                        var hideText = false;

                        dynamicNotesStep.map((note, idx) => {
                            if (note.step === step.step) {
                                if (!idArrNote.find(x => x.id === note.noteId && note.step === x.step)) {
                                    idArrNote.push({ id: note.noteId, step: note.step });
                                }
                                else {
                                    hideText = true;
                                }

                                let rowNoteStep = ReactDOMServer.renderToString(<PrintProductsNotesStep
                                    notes={[note]}
                                    cultureName={cultureName}
                                    isShowImage={showImage}
                                    hideText={hideText}
                                />);

                                myArr.push({ html: generateComponentInBody(rowNoteStep), break: false, wrap: true });

                                showImage = false;
                            }
                        });
                    }
                }
                let qInfo = item;

                let rowQInfo = ReactDOMServer.renderToString(<PrintStepItem
                    qInfo={qInfo}
                    isConfirmPrint={false}
                    isPrintCliente={isPrintCliente}
                    isTemplate={true}
                    isDocViaggio={true}
                    isPrintPdf={true}
                    isNotStep={template ? false : true}
                />);

                myArr.push({ html: generateComponentInBody(rowQInfo), break: false, wrap: true });

            });
 
        });
    }

    /*
    let rowTitleSeparator = ReactDOMServer.renderToString(<PrintTitleSeparator
        title={t(`Template:Quotation`)}
    />);

    myArr.push({ html: generateComponentInBody(rowTitleSeparator), break: true, wrap: true });

    */
    /* Quote accessorie
    if (isPrintAncillaries) {
        let rAncillaries = ReactDOMServer.renderToString(<PrintAncillaries
            recapItems={itemStepPrint}
            isConfirmPrint={true}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rAncillaries), break: false, wrap: false });
    }
    */
    /* Totale viaggio - dettaglio commissioni */
    if (isPrintCommissions && !showCommissionDetailExplode) {
        let rowCommDetail = ReactDOMServer.renderToString(<PrintCommissionsDetailCompact
            marginItems={confirmMarginItems}
            taxesFlightArr={taxesFlightArr}
            cultureName={cultureName}
            paxes={paxes}
            isConfirmPrint={true}
            isPrintCliente={isPrintCliente}
        />);

        myArr.push({ html: generateComponentInBody(rowCommDetail), break: true, wrap: false });
    }
    myArr.push({ html: generateComponentInBody("<div></div>"), break: true, wrap: false });

    if (isPrintCommissions && showCommissionDetailExplode) {
        let rowCommDetail = ReactDOMServer.renderToString(<PrintCommissionsDetail
            marginItems={confirmMarginItems}
            taxesFlightArr={taxesFlightArr}
            cultureName={cultureName}
            paxes={paxes}
            isConfirmPrint={true}
            isPrintCliente={isPrintCliente}
        />);

        myArr.push({ html: generateComponentInBody(rowCommDetail), break: false, wrap: false });
    }

    /* scadenziario
    if (isPrintScadenziario) {
        let rowScadenziario = ReactDOMServer.renderToString(<PrintScadenziario
            scadenziario={scadenziario}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowScadenziario), break: false, wrap: false });
    } */

    /* Cancel Policy
    if (isPrintCancelPolicy) {
        let rowCancelPolicy = ReactDOMServer.renderToString(<PrintCancelPolicy
            cancelPolicyArr={cancelPolicyArr}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowCancelPolicy), break: false, wrap: false });
    }*/

    /* quota comprende, non comprende
    if (template && template.inclusions && template.inclusions.length > 0 && isPrintInclusions) {
        template.inclusions.map(inclusion => {
            let rowInclusion = ReactDOMServer.renderToString(<TemplatePrintInclusion
                inclusion={inclusion}
            />);

            myArr.push({ html: generateComponentInBody(rowInclusion), break: false, wrap: true });
        })
    }
     */

    

    let isFirstTour = true;


    let schedeStatiche = [];
    let nameActivityTourArr = [];
    let nameStructureArr = [];

    if (isPrintNotes || isPrintExtraNotes) {
        // Grouping by productSubType and excluding 'Flight' items
        const groupedBySubType = finalArr.reduce((acc, item) => {
            if (item.productType !== 'Flight') {
                (acc[item.productSubType] = acc[item.productSubType] || []).push(item);
            }
            return acc;
        }, {});

        // Sorting keys to maintain a consistent order
        const sortedSubTypes = Object.keys(groupedBySubType).sort();

        // Iterate through each productSubType group
        sortedSubTypes.forEach(subType => {
            const subTypeItems = groupedBySubType[subType];

            // Filter items based on the conditions applied in the loops
            const validItems = subTypeItems.filter(qInfo => {
                return (
                    (isPrintNotes && qInfo.productType === 'Activity' && qInfo.productInfo.option.descriptions.length > 0) ||
                    (isPrintNotes && qInfo.productType === 'Structure' && qInfo.productInfo.descriptions.length > 0) ||
                    (isPrintNotes && qInfo.productType === 'Tour' && qInfo.productInfo.descriptions.length > 0) ||
                    (isPrintExtraNotes && notesPratica && notesPratica.length > 0 && notesPratica.find(x => x.idPratica === qInfo.productInfo.idPratica))
                );
            });

            // Only add heading if there are valid items
            if (validItems.length > 0) {
                let heading = ReactDOMServer.renderToString(<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <img src={getIconUrlToPrint(subType.toLowerCase())} className="ico-image-extra-large" />
                    <div style={{ width: "6px" }}></div>
                    <div style={{ display: "flex" }}>
                        <div style={{ height: "12px" }}></div>
                        {subType}
                    </div>
                </div>);
                schedeStatiche.push({ html: generateComponentInBody(heading), break: false, wrap: true });

                validItems.forEach(qInfo => {
                    if (isPrintNotes && qInfo.productType === 'Activity' && (qInfo.productInfo.option.descriptions.length > 0 || qInfo.productInfo.option.notes.length > 0)) {

                        if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                            return;

                        /*
                        if (nameActivityTourArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato di Tour 
                            nameActivityTourArr.push(qInfo.productInfo.name);
                        else
                            return;
                        */

                        if (isFirstTour) {
                            schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: false, wrap: true });
                            isFirstTour = false;
                        }

                        let rowSchedaSt;
                        let tourName;
                        let descToPrint = qInfo.productInfo.option.descriptions.filter(x => x.title !== "VOUCHER INFO");

                        let rowSchedaTitle = ReactDOMServer.renderToString(<PrintStepHeader
                            title={qInfo.productInfo.name}
                            PNR=''
                            totaleViaggio=''
                            iconName='directions_walk'
                            cultureName={cultureName}
                        />);
                        schedeStatiche.push({ html: generateComponentInBody(rowSchedaTitle), break: false, wrap: true });

                        for (var k = 0; k < descToPrint.length; k++) {
                            let newDescription = replaceAllStyleElements(descToPrint[k].description);

                            if (k > 0)
                                tourName = "";
                            else
                                tourName = qInfo.productInfo.name;

                            rowSchedaSt = ReactDOMServer.renderToString(<TourStaticPrint
                                descriptions={newDescription}
                                title={descToPrint[k].title}
                                tourName={tourName}
                            />);

                            schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
                        }
                        if (qInfo.productInfo.option.notes && qInfo.productInfo.option.notes.length > 0) {

                            let note = ReactDOMServer.renderToString(<>

                                <TourStaticPrint
                                    titleStep={qInfo.productInfo.name}
                                    descriptions={replaceAllStyleElements(qInfo.productInfo.option.notes.join(" "))}
                                    title={t('Product:Structure:RoomNotes')}
                                />
                            </>)


                            schedeStatiche.push({ html: generateComponentInBody(note), break: false, wrap: true });
                        }



                    }
                    else if (isPrintNotes && qInfo.productType === 'Structure' && qInfo.productInfo.descriptions.length > 0) {
                        if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                            return;
                        /*
                        if (nameStructureArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato
                            nameStructureArr.push(qInfo.productInfo.name);
                        else
                            return;
                        */

                        if (isFirstTour) {
                            schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: false, wrap: true });
                            isFirstTour = false;
                        }

                        let rowSchedaSt;
                        let tourName;
                        let descToPrint = qInfo.productInfo.descriptions.filter(x => x.isPrint);

                        let rowSchedaTitle = ReactDOMServer.renderToString(<PrintStepHeader
                            title={qInfo.productInfo.name}
                            PNR=''
                            totaleViaggio=''
                            productIcon={true}
                            iconName={subType.toLowerCase()}
                            cultureName={cultureName}
                        />);
                        schedeStatiche.push({ html: generateComponentInBody(rowSchedaTitle), break: false, wrap: true });

                        for (var k = 0; k < descToPrint.length; k++) {
                            let newDescription = replaceAllStyleElements(descToPrint[k].description);

                            if (k > 0)
                                tourName = "";
                            else
                                tourName = qInfo.productInfo.name;

                            rowSchedaSt = ReactDOMServer.renderToString(<TourStaticPrint
                                descriptions={newDescription}
                                title={descToPrint[k].title}
                                tourName={tourName}
                            />);
                            schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
                        }
                        if (qInfo.productInfo.rooms) {
                            for (let i = 0; i < qInfo.productInfo.rooms.length; i++) {
                                if (qInfo.productInfo.rooms[i].notes.length > 0) {
                                    let note = ReactDOMServer.renderToString(<>
                                        <TourStaticPrint
                                            titleStep={qInfo.productInfo.name}
                                            descriptions={replaceAllStyleElements(qInfo.productInfo.rooms[i].notes.join(" <br/> "))}
                                            title={t('Product:Structure:RoomNotes')}
                                        />
                                    </>)
                                    schedeStatiche.push({ html: generateComponentInBody(note), break: false, wrap: true });
                                }
                            }
                        }

                    }
                    else if (isPrintNotes && qInfo.productType === 'Tour' && qInfo.productInfo.descriptions.length > 0) {
                        if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                            return;
                        /*
                        if (nameStructureArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato
                            nameStructureArr.push(qInfo.productInfo.name);
                        else
                            return;
                        */

                        if (isFirstTour) {
                            schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: false, wrap: true });
                            isFirstTour = false;
                        }

                        let rowSchedaSt;
                        let tourName;
                        let descToPrint = qInfo.productInfo.descriptions.filter(x => x.isPrint);

                        let rowSchedaTitle = ReactDOMServer.renderToString(<PrintStepHeader
                            title={qInfo.productInfo.name}
                            PNR=''
                            totaleViaggio=''
                            productIcon={true}
                            iconName={subType.toLowerCase()}
                            cultureName={cultureName}
                        />);
                        schedeStatiche.push({ html: generateComponentInBody(rowSchedaTitle), break: false, wrap: true });

                        for (var k = 0; k < descToPrint.length; k++) {
                            let newDescription = replaceAllStyleElements(descToPrint[k].description);

                            if (k > 0)
                                tourName = "";
                            else
                                tourName = qInfo.productInfo.name;

                            rowSchedaSt = ReactDOMServer.renderToString(<TourStaticPrint
                                descriptions={newDescription}
                                title={descToPrint[k].title}
                                tourName={tourName}
                            />);
                            schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
                        }
                    }

                    if (isPrintExtraNotes && notesPratica && notesPratica.length > 0 && notesPratica.find(x => x.idPratica === qInfo.productInfo.idPratica)) {
                        let rowSchedaTitle = ReactDOMServer.renderToString(<PrintStepHeader
                            title={qInfo.productInfo.name}
                            PNR=''
                            totaleViaggio=''
                            productIcon={true}
                            iconName={subType.toLowerCase()}
                            cultureName={cultureName}
                        />);
                        schedeStatiche.push({ html: generateComponentInBody(rowSchedaTitle), break: false, wrap: true });
                    }

                    /* note pratica */
                    if (isPrintExtraNotes && notesPratica && notesPratica.length > 0) {
                        notesPratica.forEach(function (note) {
                            if (note.idPratica == qInfo.productInfo.idPratica) {
                                let notePratica = ReactDOMServer.renderToString(<TourStaticPrint
                                    titleStep={qInfo.productInfo.name}
                                    descriptions={note.nota}
                                    title={note.title}
                                />);
                                schedeStatiche.push({ html: generateComponentInBody(notePratica), break: false, wrap: true });
                            }
                        });
                    }
                });
            }
        });
    }

    myArr.push(...schedeStatiche);

    /* Note dinamiche */
    if (isPrintDynamicNotes && dynamicNotes && dynamicNotes.length > 0) {
        let notePagamenti = dynamicNotesDistincts(dynamicNotes).filter(x => x.categoryName === "Pagamenti");
        if (notePagamenti) {
            let rowDynamicNotes = ReactDOMServer.renderToString(<PrintDynamicNotes
                notes={[notePagamenti]}
                cultureName={cultureName}
            />);

            myArr.push({ html: generateComponentInBody(rowDynamicNotes), break: false, wrap: true });
        }

        myArr.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });


        let note = dynamicNotesDistincts(dynamicNotes).filter(x => x.categoryName !== "Pagamenti");
        let rowDynamicNotes = ReactDOMServer.renderToString(<PrintDynamicNotes
            notes={[note]}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowDynamicNotes), break: false, wrap: true });
    }

    return myArr;
}