import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StructureSummary } from '../product/Summary/StructureSummary';
import { ActivitySummary } from '../product/Summary/ActivitySummary';
import { CarRentalSummary } from '../product/Summary/CarRentalSummary';
import { CruiseSummary } from '../product/Summary/CruiseSummary';
import { TransferSummary } from '../product/Summary/TransferSummary';
import { FlightSummary } from '../product/Summary/FlightSummary';
import { TrainSummary } from '../product/Summary/TrainSummary';
import { TourSummary } from '../product/Summary/TourSummary';
import { TemplateInclusion } from '../TemplateUtils';

export const TemplateSummary = ({ steps }) => {
    const { t } = useTranslation();

    const [stepMapping, setStepMapping] = useState([]);

    useEffect(() => {

        if (steps && steps.length > 0) {
            let newStepMapping = [];
            for (let i = 0; i < steps.length; i++) {
                let step = steps[i];
                if (step.templateData && step.templateData.length > 0) {
                    let selectedItemsOriginal = step.templateData.filter(x => x.inclusion !== TemplateInclusion.AtLeastOne || x.isDefault === true);
                    let alternativeItems = step.templateData.filter(x => x.inclusion === TemplateInclusion.AtLeastOne && x.isDefault === false);
                    let selectedItems = [...selectedItemsOriginal];

                    for (let j = 0; j < selectedItemsOriginal.length; j++) {
                        let tData = selectedItemsOriginal[j];

                        // transfer
                        if (tData.productType === 4 || tData.productSubType === 41) {
                            selectedItems[j].product.multiItineraryName = "Template:Transfer";
                            step.alternativeItems = alternativeItems.filter(x => x.productType !== 4 && x.productSubType !== 41);

                            step.selectedItems = selectedItems;
                        }
                        else if (tData.productType === 5 || tData.productSubType === 51) {
                            let selectItineraryName = tData.product.itineraryName;

                            let isReturnFlight = (steps.length - 1) === i;
                            let arrivalAirport = '';
                            let aName = '';
                            let count = 0;
                            try {
                                arrivalAirport = selectItineraryName.split('>')[1];
                                aName = selectItineraryName.split('>')[1];
                                arrivalAirport = '>' + arrivalAirport;

                                count = alternativeItems.filter(x => {
                                    if (x.productType !== 5 && x.productSubType !== 51)
                                        return true;

                                    return x.product.itineraryName.includes(arrivalAirport)
                                }).length;

                                if (count > 0) {
                                    alternativeItems = alternativeItems.filter(x => !x.product.itineraryName.includes(arrivalAirport));

                                    if (!isReturnFlight)
                                        selectedItems[j].product.multiItineraryName = "Voli per" + aName;
                                    else
                                        selectedItems[j].product.multiItineraryName = "Volo ritorno";
                                }

                                let fltAlternative = [];
                                let airportNamePushed = [];

                                for (let z = 0; z < alternativeItems.length; z++) {
                                    let myAlt = alternativeItems[z];
                                    if (myAlt.productType !== 5 && myAlt.productSubType !== 51) {
                                        fltAlternative.push(myAlt);
                                    } else {
                                        let aName = myAlt.product.itineraryName.split('>')[1];
                                        if (!airportNamePushed.includes(aName) && !isReturnFlight) {
                                            airportNamePushed.push(aName);
                                            myAlt.product.multiItineraryName = "Voli per" + aName;
                                            fltAlternative.push(myAlt);
                                        }
                                    }
                                }

                                alternativeItems = fltAlternative;

                            } catch (ex) {

                            }


                            step.selectedItems = selectedItems;
                            step.alternativeItems = alternativeItems;

                        } else {
                            step.selectedItems = selectedItems;
                            step.alternativeItems = alternativeItems;
                        }

                    }

                }

                newStepMapping.push(step);
            }

            setStepMapping(newStepMapping);
        }

    }, [steps]);

    return (
        <>
            {stepMapping && stepMapping.length > 0
                ? (
                    stepMapping.map((step, index) => {
                        return <React.Fragment key={index}>
                            <div className="py-2">
                                <label className="border-bottom w-100 pb-1 text-white border-1" style={{ borderBottomColor: '#780704' }}>{step.step} {t(`Template:JourneyLeg`)} - <var>{step.webDestinationName}</var></label>
                                {step.selectedItems && step.selectedItems.length > 0
                                    ? (
                                        step.selectedItems.map((tData, idx) => {
                                            return (
                                                <React.Fragment key={idx}>
                                                    {tData.productType === 0 && <StructureSummary structure={tData.product} />}
                                                    {tData.productType === 1 && <ActivitySummary activity={tData.product} />}
                                                    {tData.productType === 2 && <CarRentalSummary carRental={tData.product} />}
                                                    {tData.productType === 3 && <CruiseSummary cruise={tData.product} />}
                                                    {tData.productType === 4 && <TransferSummary transfer={tData.product} />}
                                                    {tData.productType === 5 && <FlightSummary flight={tData.product} />}
                                                    {tData.productType === 9 && <TrainSummary train={tData.product} />}
                                                    {tData.productType === 10 && <TourSummary tour={tData.product} />}
                                                </React.Fragment>
                                            )
                                        })
                                    )
                                    : (<></>)
                                }

                                {step.alternativeItems && step.alternativeItems.length > 0 &&
                                    <label className="py-2 text-white h075" style={{ borderBottomColor: '#780704' }}>{t(`Template:AlternativeProducts`)}</label>}
                                {step.alternativeItems && step.alternativeItems.length > 0
                                    ? (
                                        step.alternativeItems.map((tData, idx) => {
                                            return (
                                                <React.Fragment key={idx}>
                                                    <div className="pl-4">
                                                        {tData.productType === 0 && <StructureSummary structure={tData.product} isAlternative={true} />}
                                                        {tData.productType === 1 && <ActivitySummary activity={tData.product} isAlternative={true} />}
                                                        {tData.productType === 2 && <CarRentalSummary carRental={tData.product} isAlternative={true} />}
                                                        {tData.productType === 3 && <CruiseSummary cruise={tData.product} isAlternative={true} />}
                                                        {tData.productType === 4 && <TransferSummary transfer={tData.product} isAlternative={true} />}
                                                        {tData.productType === 5 && <FlightSummary flight={tData.product} isAlternative={true} />}
                                                        {tData.productType === 9 && <TrainSummary train={tData.product} isAlternative={true} />}
                                                        {tData.productType === 10 && <TourSummary tour={tData.product} isAlternative={true} />}
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                    )
                                    : (<></>)
                                }
                            </div>
                        </React.Fragment>
                    })
                )
                : (<></>)
            }
        </>
    );
}
