import React, { useState, useEffect } from 'react';
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { InputSearchDestinationContainer } from '../../Common/Destination/InputSearchDestinationContainer';
import { InputCalendarContainer } from '../../Common/Calendar/InputCalendarContainer';
import { SelectCitizenship } from '../../Common/SelectCitizenship';
import { compareDates } from '../../../js/Utils.js';
import { getCarListLink, generateClientFlowId, callGetLastItemsSearch, callGetGetProductRules, getCarCarpetId } from '../../../js/ProductService.js';
import { ListBox } from 'primereact/listbox';
import { InputTimeContainer } from '../../Common/Time/InputTimeContainer';
import { SelectRay } from '../../Common/SelectRay';

export const CarSearchEngine = ({ searchParameters, modeView, resetValues, extraParams, isFake, isTrippie, onSearchTrippie }) => {
    const { t } = useTranslation();

    const [lastSearchItems, setLastSearchItems] = useState([]);
    const [defaultCitizenshipId, setDefaultCitizenshipId] = useState(null);
    const [isStartSearch, setIsStartSearch] = useState(false);
    const [isValidComposition, setIsValidComposition] = useState(true);
    const [rayAvailbles] = useState([1, 5, 10, 15]);
    const [isDeliveryPoint, setIsDeliveryPoint] = useState(false);
    const [selectAge, setSelectAge] = useState(25);

    const [textColorClass, setTextColorClass] = useState('text-white');

    const [selectCitizenship, setSelectCitizenship] = useState(null);
    const [selectDestinations, setSelectDestinations] = useState([{}]);
    const [selectDates, setSelectDates] = useState();

    const [selectTimeTo, setSelectTimeTo] = useState(null);
    const [selectTimeFrom, setSelectTimeFrom] = useState(null);

    const [selectDefaultTimes, setSelectDefaultTimes] = useState({
        timeFrom: new Date(2099, 1, 1, 12, 0, 0),
        timeTo: new Date(2099, 1, 1, 12, 0, 0)
    });
    const [isValidForm, setIsValidForm] = useState({
        isValidDestination: false,
        isValidDates: false,
        isValidComposition: false,
        isAllFieldValid: false
    });

    // tipo car informazioni
    const [extraOptionsCalendarOutbound, setExtraOptionsCalendarOutbound] = useState({ multiDate: false, numberOfMonths: 1, showTime: false, label: 'SearchEngine:DateFrom', labelPlaceHolder: 'SearchEngine:SingleDate' });
    const [extraOptionsCalendarReturn] = useState({ multiDate: false, numberOfMonths: 1, showTime: false, label: 'SearchEngine:DateTo', labelPlaceHolder: 'SearchEngine:SingleDate' });
    const [validDateRule, setValidDateRule] = useState(extraParams && extraParams.validDateRule ? extraParams.validDateRule : {});

    // gestisco la visibilità
    const [showDestination, setShowDestination] = useState([{ dep: false, arr: false }]);
    const [showCalendarOutbound, setShowCalendarOutbound] = useState(false);
    const [showTimeFrom, setShowTimeFrom] = useState(false);
    const [showTimeTo, setShowTimeTo] = useState(false);
    const [showCalendarReturn, setShowCalendarReturn] = useState(false);
    const [showAgePanel, setShowAgePanel] = useState(false);
    const [showTime, setShowTime] = useState(false);

    // gestione raggio
    const [showRayPickup, setShowRayPickup] = useState(false);
    const [showRayDropoff, setShowRayDropoff] = useState(false);
    const [selectPickupRay, setSelectPickupRay] = useState(1);
    const [selectDropoffRay, setSelectDropoffRay] = useState(1);

    useEffect(() => {

        const getLastSearchItems = async () => {
            const response = await callGetLastItemsSearch(21);
            setLastSearchItems(response);
        }

        const getRules = async () => {
            const response = await callGetGetProductRules(21);

            if (response) {
                try {
                    setIsValidComposition(response['ADDTOCART'].allowed);
                } catch (ex) {
                    console.error(ex);
                }
            }
        }

        getLastSearchItems();
        getRules();

        if (modeView === 'MODAL')
            setTextColorClass('text-dark');
        else
            setTextColorClass('text-white');
    }, []);

    useEffect(() => {
        setValuesHowDefault();
    }, [searchParameters, resetValues]);

    useEffect(() => {
        let isValidFormUpdate = {
            isValidDestinationMap: [],

            isValidDestination: false,
            isValidDates: false,

            isAllFieldValid: false
        };


        // verifico che tutte le destinazioni siano compilate
        let isValidDestinationMap = [{ dep: false }];
        let isValidDestination = false;
        if (selectDestinations) {
            isValidDestinationMap = Object.values(selectDestinations)
                .map(x => (
                    {
                        dep: x && x.dep !== null && x.dep !== undefined && x.dep.Id,
                    })
                );

            isValidDestination = Object.values(isValidDestinationMap).every(x => x && x.dep);
        }

        isValidFormUpdate.isValidDestinationMap = isValidDestinationMap;
        isValidFormUpdate.isValidDestination = isValidDestination;

        // verifico che tutte le date siano compilate
        let isValidDates = false;
        if (selectDates) {
            isValidDates = selectDates.dateFrom !== null && selectDates.dateFrom !== undefined && selectDates.dateTo;
        }

        isValidFormUpdate.isValidDates = isValidDates;

        isValidFormUpdate.isAllFieldValid = isValidFormUpdate.isValidDates && isValidFormUpdate.isValidDestination;
        setIsValidForm(isValidFormUpdate);

    }, [selectDestinations, selectDates,]);


    function setValuesHowDefault() {
        if (searchParameters) {

            let updateDeparture = {
                Text: searchParameters.pickupDestinationName,
                Id: searchParameters.pickupDestinationWebId,
                Type: searchParameters.pickupDestinationType
            };

            if (searchParameters.pickupDestinationType === 'POI') {
                setShowRayPickup(true);
                setSelectPickupRay(searchParameters.pickupRay);
            }

            let updateArrival = null;
            if (searchParameters.dropOffSameAsPickup) {
                updateArrival = { ...updateDeparture };
            }
            else {
                updateArrival = {
                    Text: searchParameters.dropoffDestinationName,
                    Id: searchParameters.dropoffDestinationWebId,
                    Type: searchParameters.dropoffDestinationType
                };

                if (searchParameters.pickupDestinationType === 'POI') {
                    setShowRayDropoff(true);
                    setSelectDropoffRay(searchParameters.pickupRay);
                }
            }


            setSelectDestinations([{ dep: updateDeparture, arr: updateArrival }]);

            let updateCalendar = {
                dateFrom: searchParameters.pickupDate,
                dateTo: searchParameters.dropoffDate,
            };


            let picTmpDate = new Date(searchParameters.pickupDate);
            if (picTmpDate !== "Invalid Date") {
                setSelectTimeFrom(picTmpDate.toTimeString().split(' ')[0].slice(0, -3));
            }
            let dropTmpDate = new Date(searchParameters.dropoffDate);
            if (new Date(searchParameters.dropoffDate) !== "Invalid Date") {
                setSelectTimeTo(dropTmpDate.toTimeString().split(' ')[0].slice(0, -3));
            }

            setSelectDefaultTimes()

            setSelectDefaultTimes({
                ...selectDefaultTimes,
                timeFrom: picTmpDate,
                timeTo: dropTmpDate
            });
            setSelectDates(updateCalendar);
            setDefaultCitizenshipId(searchParameters.citizenship);
        }
        else {
            setExtraOptionsCalendarOutbound({ ...extraOptionsCalendarOutbound, multiDate: false, numberOfMonths: 1, label: 'SearchEngine:DateFrom' });
        }
    }

    async function onSearchButton() {
        setIsStartSearch(true);

        try {
            let newSP = {};

            if (searchParameters) {
                newSP = searchParameters;
            } else {
                newSP = {
                    pickupDestinationType: null,
                    pickupDestinationName: null,
                    pickupDestinationWebId: null,
                    pickupRay: 0,
                    pickupDate: null,
                    dropoffDate: null,
                    dropOffSameAsPickup: true,
                    driverAge: null,
                    citizenship: 380,
                    agencyMarkup: null,
                    agencyFe: null,
                    dropoffDestinationType: null,
                    dropoffDestinationName: null,
                    dropoffDestinationWebId: null,
                    dropoffRay: 0
                };
            }

            newSP.pickupDestinationName = selectDestinations[0].dep.Text;
            newSP.pickupDestinationWebId = selectDestinations[0].dep.Id;
            newSP.pickupDestinationType = selectDestinations[0].dep.Type;

            if (selectDestinations[0].dep.Type === 'POI') {
                newSP.Latitude = selectDestinations[0].dep.Latitude;
                newSP.Longitude = selectDestinations[0].dep.Longitude;
            }

            if (showRayPickup)
                newSP.pickupRay = selectPickupRay;

            if (selectDestinations.length > 0 && selectDestinations[0].arr && isDeliveryPoint) {
                newSP.dropoffDestinationName = selectDestinations[0].arr.Text;
                newSP.dropoffDestinationWebId = selectDestinations[0].arr.Id;
                newSP.dropoffDestinationType = selectDestinations[0].arr.Type;

                if (showRayDropoff)
                    newSP.dropoffRay = selectDropoffRay;

                if (selectDestinations[0].dep.Type === 'POI') {
                    newSP.Latitude = selectDestinations[1].dep.Latitude;
                    newSP.Longitude = selectDestinations[1].dep.Longitude;
                }

                newSP.dropOffSameAsPickup = false;
            }
            else {
                newSP.dropoffDestinationName = null;
                newSP.dropoffDestinationWebId = null;
                newSP.dropoffDestinationType = null;
                newSP.dropoffRay = 0;
                newSP.dropOffSameAsPickup = true;
            }

            newSP.pickupDate = selectDates.dateFrom;
            newSP.dropoffDate = selectDates.dateTo;

            newSP.driverAge = selectAge;

            if (selectCitizenship)
                newSP.citizenship = selectCitizenship.isoId;

            if (isTrippie) {
                let clientFlowId = generateClientFlowId();
                let carpetId = await getCarCarpetId(newSP, clientFlowId, selectTimeFrom, selectTimeTo);

                onSearchTrippie(extraParams.stepIndex, clientFlowId, carpetId, newSP, configData.Settings.Products.CarRental.IdTipoProdotto);
                setIsStartSearch(false);
                return;
            }

            let clientFlowId = generateClientFlowId();
            let url = await getCarListLink(newSP, clientFlowId, selectTimeFrom, selectTimeTo);
            window.open(url, '_self');

        } catch (ex) {
            console.error(ex);
        }

        setIsStartSearch(false);

    }


    // funzioni destinazione
    function onSelectSearchDestination(newSelectDestination, externalId) {
        let externalIdParts = externalId.split('_');
        let indexToUpdate = parseInt(externalIdParts[1]);
        let updateSelectDestinations = [...selectDestinations];
        let isDeparture = externalIdParts[0].includes('departure');

        if (isDeparture) {
            updateSelectDestinations[indexToUpdate].dep = newSelectDestination;
            setShowRayPickup(newSelectDestination.Type === 'POI');
        }
        else {
            updateSelectDestinations[indexToUpdate].arr = newSelectDestination;
            setShowRayDropoff(newSelectDestination.Type === 'POI');
        }

        setSelectDestinations(updateSelectDestinations);
    }

    function onOpenPanelDestination(externalId) {
        let externalIdParts = externalId.split('_');
        let isDeparture = externalIdParts[0].includes('departure');
        let indexToUpdate = parseInt(externalIdParts[1]);

        let showDestinationMap = getShowDestinationLegs(false);
        if (isDeparture) {
            showDestinationMap[indexToUpdate].dep = true;
        }
        else {
            showDestinationMap[indexToUpdate].arr = true;
        }

        setShowDestination(showDestinationMap);

        setShowCalendarOutbound(false);
        setShowCalendarReturn(false);
        setShowTimeFrom(false);
        setShowTimeTo(false);
        setShowAgePanel(false);
    }

    // funzioni per il calendario
    function onOpenPanelCalendarOutbound() {
        let showDestinationMap = getShowDestinationLegs(false);
        setShowDestination(showDestinationMap);

        setShowCalendarOutbound(true);
        setShowCalendarReturn(false);
        setShowTimeFrom(false);
        setShowTimeTo(false);
        setShowAgePanel(false);
    }

    function onOpenPanelCalendarReturn() {
        let showDestinationMap = getShowDestinationLegs(false);
        setShowDestination(showDestinationMap);

        setShowCalendarOutbound(false);
        setShowCalendarReturn(true);
        setShowTimeFrom(false);
        setShowTimeTo(false);
        setShowAgePanel(false);
    }

    function onClickSelectDatesOutbound(values) {
        setShowCalendarOutbound(false);

        let updSelectDates = {
            ...selectDates,
            dateFrom: values.dateFrom
        };
        if (updSelectDates.dateTo && values.dateFrom) {
            let isLess = compareDates(updSelectDates.dateTo, values.dateFrom);
            if (isLess < 0)
                updSelectDates.dateTo = null;
        }

        if (!updSelectDates.dateTo) {
            updSelectDates.dateTo = values.dateFrom;
        }

        let updValidDateRule = {
            ...validDateRule
        };
        updValidDateRule.minDate = values.dateFrom;

        setSelectDates(updSelectDates);
        setValidDateRule(updValidDateRule);
    }

    function onClickSelectDatesReturn(values) {
        setShowCalendarReturn(false);

        setSelectDates({
            ...selectDates,
            dateTo: values.dateFrom
        });
    }

    // funzioni cittadinanza
    function onSelectCitizenship(item) {
        setSelectCitizenship(item);
    }


    // funzioni raggio
    function onSelectPickupRay(item) {
        setSelectPickupRay(item);
    }

    function onSelectDropoffRay(item) {
        setSelectDropoffRay(item);
    }

    function getShowDestinationLegs(value) {
        let result = [];

        if (showDestination && showDestination.length > 0) {
            for (let i = 0; i < showDestination.length; i++) {
                result.push({ dep: value, arr: value });
            }
        }

        return result;
    }

    function openAgePanel() {
        setShowAgePanel(!showAgePanel);
        setShowCalendarOutbound(false);
        setShowCalendarReturn(false);
        setShowTimeFrom(false);
        setShowTimeTo(false);
    }

    function onChangeDropDownAge(item) {
        setSelectAge(item);
        setShowAgePanel(false);
    }

    // funzioni Time
    function onOpenPanelTimeFrom() {
        let showDestinationMap = getShowDestinationLegs(false);
        setShowDestination(showDestinationMap);

        setShowCalendarOutbound(false);
        setShowCalendarReturn(false);
        setShowTimeFrom(true);
        setShowTimeTo(false);
        setShowAgePanel(false);
    }

    function onOpenPanelTimeReturn() {
        let showDestinationMap = getShowDestinationLegs(false);
        setShowDestination(showDestinationMap);

        setShowCalendarOutbound(false);
        setShowCalendarReturn(false);
        setShowTimeFrom(false);
        setShowTimeTo(true);
        setShowAgePanel(false);
    }

    function onSetSelectTimeFrom(value) {
        setSelectTimeFrom(value);
        //setShowTimeFrom(false);
    }

    function onSetSelectTimeTo(value) {
        setSelectTimeTo(value);
        // setShowTimeTo(false);
    }

    return (
        <>
            <div className="row pb-2 mb-3">
                <div className="col-12 col-sm-9 my-2"></div>
                <div className="col-12 col-sm-3 my-2 d-inline text-right">
                    <SelectCitizenship
                        defaultCitizenshipId={defaultCitizenshipId}
                        onSelectCitizenship={onSelectCitizenship}
                        modeView={modeView} />
                </div>

                <div className={"col-12 my-1 " + (showRayPickup ? "col-sm-3 col-lg-4" : "col-sm-4 col-lg-5")}>
                    <InputSearchDestinationContainer
                        externalId="departureSegment_0"
                        defaultSelectDestination={selectDestinations[0].dep}
                        productType={configData.Settings.Products.CarRental.IdTipoProdotto}
                        lastSearchItems={lastSearchItems}
                        onSelectSearchDestination={onSelectSearchDestination}
                        onOpenPanelDestination={onOpenPanelDestination}
                        extraOptions={{ selectGroupTitle: true }}
                        closeExternal={showDestination[0].dep}
                        modeView={modeView} />
                </div>

                {
                    showRayPickup && <>
                        <div className="col-12 col-sm-1 my-1">
                            <SelectRay
                                defaultRay={selectPickupRay}
                                onSelectRay={onSelectPickupRay}
                                defaultRayValues={rayAvailbles} />
                        </div>
                    </>
                }

                <div className="col-12 col-sm-6 col-md-6 col-lg-2 pl-0  my-1">
                    <InputCalendarContainer
                        dateFrom={selectDates ? selectDates.dateFrom : null}
                        extraOptions={extraOptionsCalendarOutbound}
                        productType={configData.Settings.Products.CarRental.IdTipoProdotto}
                        onClickSelectDates={onClickSelectDatesOutbound}
                        onOpenPanelCalendar={onOpenPanelCalendarOutbound}
                        closeExternal={showCalendarOutbound}
                        modeView={modeView}
                        validDateRule={extraParams ? extraParams.validDateRule : null}
                        type="m" />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-1 pl-0  my-1">
                    <InputTimeContainer
                        time={selectDefaultTimes && selectDefaultTimes.timeFrom ? selectDefaultTimes.timeFrom : null}
                        validDateRule={validDateRule}
                        onOpenPanelTime={onOpenPanelTimeFrom}
                        modeView={modeView}
                        closeExternal={showTimeFrom}
                        onHandleTime={onSetSelectTimeFrom}
                        type="m" />
                </div>
                <div className="col-12 col-sm-12 col-md-4 col-lg-2 pl-0 my-1">
                    <InputCalendarContainer
                        dateFrom={selectDates ? selectDates.dateTo : null}
                        extraOptions={extraOptionsCalendarReturn}
                        validDateRule={validDateRule}
                        productType={configData.Settings.Products.CarRental.IdTipoProdotto}
                        onClickSelectDates={onClickSelectDatesReturn}
                        onOpenPanelCalendar={onOpenPanelCalendarReturn}
                        closeExternal={showCalendarReturn}
                        modeView={modeView}
                        type="m" />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-1 pl-0 my-1">
                    <InputTimeContainer
                        time={selectDefaultTimes && selectDefaultTimes.timeTo ? selectDefaultTimes.timeTo : null}
                        validDateRule={validDateRule}
                        onOpenPanelTime={onOpenPanelTimeReturn}
                        modeView={modeView}
                        closeExternal={showTimeTo}
                        onHandleTime={onSetSelectTimeTo}
                        type="m" />
                </div>
                <div className="col-12 col-sm-1 pl-0 my-1">
                    <div className="w-100">
                        <div className="input-group rounded pr-1 bg-white" onClick={_ => openAgePanel()}>
                            <span className="input-group-text px-2 w-30 bg-white" id="basic-addon1">
                                <M3Icon iconName="PaxAdl" />
                            </span>
                            <div className="pl-1 w-70">
                                <label htmlFor="ddlAge" className="form-label h065">
                                    <data m3lab="SearchEngine.Age">
                                        {t('SearchEngine:Age')}
                                    </data>
                                </label>

                                <input className="form-control h075 w-100 border-0 shadow-none"
                                    id="ddlAge"
                                    value={selectAge}
                                    readOnly />
                            </div>
                        </div>
                        {
                            showAgePanel && <div className="mb-2">
                                <ListBox value={selectAge}
                                    onChange={(e) => onChangeDropDownAge(e.value)}
                                    options={new Array(60).fill("").map((_, i) => i + 21)}
                                    listStyle={{ height: '250px', textAlign: 'left' }}
                                />
                            </div>
                        }
                    </div>
                </div>

                <div className="col-12 col-sm-9 my-1">
                    <input className="mr-2 cursor-pointer form-check-input" type="checkbox" onChange={(e) => setIsDeliveryPoint(e.target.checked)} />
                    <label className={"form-check-label " + textColorClass} htmlFor="Return">
                        <data m3lab="SearchEngine.Roundtrip">
                            {t('SearchEngine:RentPointReturn')}
                        </data>
                    </label>
                </div>

                {isDeliveryPoint && <div className="col-12 col-sm-4 col-lg-5 my-1">
                    <InputSearchDestinationContainer
                        externalId="arrivalSegment_0"
                        defaultSelectDestination={selectDestinations[0].arr}
                        productType={configData.Settings.Products.CarRental.IdTipoProdotto}
                        lastSearchItems={lastSearchItems}
                        onSelectSearchDestination={onSelectSearchDestination}
                        onOpenPanelDestination={onOpenPanelDestination}
                        extraOptions={{ label: 'SearchEngine:DeliveryPoint', selectGroupTitle: true }}
                        closeExternal={showDestination[0].arr}
                        modeView={modeView} />
                </div>}
                
                        {
                            showRayDropoff && <div className="col-12 col-sm-2 my-1">
                                <SelectRay defaultRay={selectDropoffRay} onSelectRay={onSelectDropoffRay} />
                            </div>
                        }

                <div className="col-12 col-sm-12 my-1 p-3 text-center">
                    <button
                        className="btn tp-btn-search py-2"
                        onClick={_ => onSearchButton()}
                        disabled={!isValidForm.isAllFieldValid && !isStartSearch}
                    >
                        {isStartSearch ? <><div className="spinner-border spinner-border-sm text-primary" role="status"><span className="sr-only"></span></div></> : <>{t('Button:Search')}</>}
                    </button>
                </div>

            </div>
        </>
    );
}