import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../js/Utils';
import configData from "../../../appsettings.json";


export const TemplatePrintStepHeader = ({ title, iconName, isStep = false, stepIndex, itemSteps, minDate = null, maxDate = null }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    var dateFrom = null;
    var dateTo = null;

    function setMinMaxDate() {
        let minDate = new Date("01-01-3990");
        let maxDate = 0;

        itemSteps.filter(x => x.productInfo.stepId === stepIndex).map((item, itemKey) => {
            if (item.productInfo && item.productInfo.priceBar) {
                let itemMinDate = new Date(item.productInfo.priceBar.startDate);
                let itemMaxDate = new Date(item.productInfo.priceBar.endDate);

                if (minDate > itemMinDate)
                    minDate = itemMinDate;
                if (maxDate < itemMaxDate)
                    maxDate = itemMaxDate;
            }
        });


        dateFrom = minDate.getDate().toString().padStart(2, "0") + "/" + minDate.getMonth().toString().padStart(2, "0");
        dateTo = maxDate.getDate().toString().padStart(2, "0") + "/" + maxDate.getMonth().toString().padStart(2, "0");
    }

    if (minDate && maxDate) {
        dateFrom = formatDateTime(minDate, cultureName, { noYear: true });
        dateTo = formatDateTime(maxDate, cultureName, { noYear: true });
    }
    else {
        //setMinMaxDate();
    }

    return (
        <div className="rounded-top text-white mb-0 mt-3 stepHeader marginBottomPdf">
            {!(stepIndex == 99) && <table className="w-100">
                <tbody>
                    <tr>
                        {!isStep ?
                            <td className="pl-2">
                                <span className="h5 text-white float-start">
                                    <span className="material-icons p-1">{iconName}</span>{title}
                                </span>
                            </td>
                            :
                            <>
                                <td className="pl-2">
                                    {
                                        <>
                                            <span className="h4 text-white float-start">
                                                <span className="text-white h5">
                                                    {stepIndex} &nbsp;
                                                </span>
                                                <span className="text-white h5">
                                                    Step &nbsp;
                                                </span>
                                                <span className="text-white h5">
                                                    {title && `- ${title}`}
                                                </span>
                                            </span>
                                        </>
                                    }
                                </td>
                                <td className="w-40 text-right">
                                    {dateFrom &&
                                        <>
                                            <span className="text-white h5">
                                                {t(`Template:FromDate`)}: &nbsp;
                                            </span>
                                            <span className="text-white h5">
                                                {dateFrom} &nbsp;
                                            </span>
                                            <span className="text-white h5">
                                                {t(`Template:ToDate`)}: &nbsp;
                                            </span>
                                            <span className="text-white h5">
                                                {dateTo}
                                            </span>
                                        </>
                                    }
                                </td>
                            </>
                        }
                    </tr>
                </tbody>
            </table>}
        </div>
    );
}