import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { PassengerPrint } from './PassengerPrint';
import { PriceBarPrint } from './PriceBarPrint';
import { formatDateTime, getStatusClass } from '../../../js/Utils';
import { imageOnError, imageOnLoadTransfer } from '../../../js/CDNUtility';
import { getIconUrlToPrint, replaceAllStyleElements, isNotesTooBig, getAllFirstUppercase, getFirstUppercase, getNoteCut } from '../../../js/PrintUtility';
import { ServiceNotePrint } from '../service/ServiceNotePrint';


export const CarRentalDetailPrint = ({ id, type, isNotStep, carRentalDetail, isPrintCliente, isDocViaggio, isShowNote = false, isConfirmPrint, isHideCancelPolicy }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            {carRentalDetail &&
                <table className="w-100 border shadow">
                    <tbody>
                        <tr class="shadow">
                            <td className="widthTdImage">
                                <img src={`data:image;base64, ` + carRentalDetail.thumbUrlB} alt="Thumb" className="imgProductTransfer" onError={imageOnError} />
                            </td>
                            <td class="w-80  px-3">
                                <table class="w-100">
                                    <tbody>
                                        <tr class="h6">
                                            <td colspan="2" className="fontBold">
                                                {getAllFirstUppercase(carRentalDetail.name)}
                                            </td>
                                            <td className="text-right">
                                                {isConfirmPrint && carRentalDetail.priceBar && carRentalDetail.priceBar.wsBookingId &&
                                                    <>
                                                        N. PNR: {carRentalDetail.priceBar.wsBookingId}
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                        <tr class="h5">
                                            <td colspan="4" >                                            </td>
                                            <td className="col-2  text-right">
                                                {isConfirmPrint && carRentalDetail.option && carRentalDetail.option.cancelPolicy && !carRentalDetail.option.cancelPolicy.isFree &&
                                                    <span className={"h6 status " + getStatusClass(carRentalDetail.priceBar.status)}>{t(`statiPrenotazione:` + carRentalDetail.priceBar.status)}</span>
                                                }
                                            </td>
                                        </tr>
                                        {carRentalDetail.option &&
                                            <>
                                                <tr>
                                                    <td className="w-imagePdf">
                                                        <img src={getIconUrlToPrint('announcement')} className="ico-image mr-1" alt="" />
                                                    </td>
                                                    <td className="w-40">
                                                        <label>
                                                            {getFirstUppercase(carRentalDetail.option.description)}
                                                        </label>
                                                    </td>

                                                </tr>
                                            </>
                                        }
                                        {carRentalDetail.pickUpInfo &&
                                            <>
                                                <tr>
                                                    <td className="w-imagePdf">
                                                        <img src={getIconUrlToPrint('carRental')} className="ico-image mr-1" alt="" />
                                                    </td>
                                                    <td>
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "135px" }} className="carDetailIconCustomWidth">
                                                                <label className="mr-1">{t(`Product:RentCar:Pick`)}</label>

                                                                <label>{formatDateTime(carRentalDetail.pickUpInfo.pickupDateTime, cultureName)}</label>
                                                            </div>
                                                            <img src={getIconUrlToPrint('redClock')} className="ico-image mr-1" alt="" />
                                                            <label >{formatDateTime(carRentalDetail.pickUpInfo.pickupDateTime, cultureName, {
                                                                onlyTime: true
                                                            })}</label>
                                                            <div style={{ width: "8px" }}>
                                                            </div>
                                                            {
                                                                carRentalDetail.pickUpInfo.address && <>

                                                                    <img src={getIconUrlToPrint('location')} alt="" className="ico-image mr-1" />

                                                                    <label className="mr-1">{carRentalDetail.pickUpInfo.address}</label>
                                                                </>
                                                            }

                                                            {
                                                                (!carRentalDetail.pickUpInfo.address && carRentalDetail.pickUpInfo.officeName) ? <>

                                                                    <img src={getIconUrlToPrint('location')} alt="" className="ico-image mr-1" />

                                                                    <label className="mr-1">{carRentalDetail.pickUpInfo.officeName}</label>
                                                                </> : <></>
                                                            }
                                                        </div>
                                                    </td>

                                                </tr>
                                            </>
                                        }
                                        {carRentalDetail.dropOffInfo &&
                                            <>
                                                <tr>
                                                    <td className="w-imagePdf">
                                                        <img src={getIconUrlToPrint('carRental')} className="ico-image mr-1" alt="" />
                                                    </td>
                                                    <td>
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "135px" }} className="carDetailIconCustomWidth">
                                                                <label className="mr-1">{t(`Product:RentCar:Drop`)}</label>
                                                                <label>{formatDateTime(carRentalDetail.dropOffInfo.dropOffDateTime, cultureName)}</label>
                                                            </div>
                                                            <img src={getIconUrlToPrint('redClock')} className="ico-image mr-1" alt="" />
                                                            <label >{formatDateTime(carRentalDetail.dropOffInfo.dropOffDateTime, cultureName, {
                                                                onlyTime: true
                                                            })}</label>
                                                            <div style={{ width: "8px" }}>
                                                            </div>
                                                            {carRentalDetail.dropOffInfo.address && <>
                                                                <img src={getIconUrlToPrint('location')} alt="" className="ico-image mr-1" />
                                                                <label className="mr-1">{carRentalDetail.dropOffInfo.address}</label>

                                                            </>}
                                                            {
                                                                (!carRentalDetail.dropOffInfo.address && carRentalDetail.dropOffInfo.officeName) ? <>

                                                                    <img src={getIconUrlToPrint('location')} alt="" className="ico-image mr-1" />

                                                                    <label className="mr-1">{carRentalDetail.dropOffInfo.officeName}</label>
                                                                </> : <></>
                                                            }
                                                        </div>
                                                    </td>

                                                </tr>
                                            </>
                                        }
                                        <tr>
                                            <td colspan="2">
                                                {carRentalDetail.option && carRentalDetail.option.note && carRentalDetail.priceBar && carRentalDetail.priceBar.isDMC && isShowNote &&
                                                    <>
                                                        <div class="h075  text-left">{t(`General:Note`)}:</div>
                                                        <div>
                                                            <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: carRentalDetail.option.note }} /></p>
                                                        </div>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                        {carRentalDetail.option.extras && carRentalDetail.option.extras.length > 0 && carRentalDetail.option.extras.map((extra, key) =>
                                            <tr>
                                                <td className="w-60 text-left">
                                                    <label>Extra &nbsp;{key + 1}:&nbsp;</label>
                                                    <label dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(extra.description) }} />
                                                </td>
                                            </tr>
                                        )}
                                        {!isHideCancelPolicy && carRentalDetail.option && carRentalDetail.option.cancelPolicy && !carRentalDetail.option.cancelPolicy.isFree &&
                                            <tr>
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('localAtm')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    {carRentalDetail.option.cancelPolicy.isFree ?
                                                        <label className="text-success">{t(`CancelPolicy:FreeCancellation`)} {formatDateTime(carRentalDetail.option.cancelPolicy.expirationDate, { twoDigits: true })}</label>
                                                        :
                                                        <label className="penalty">{t(`CancelPolicy:PenaltyCancellation`)}</label>
                                                    }
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            <div style={{ padding: "5px" }}>
                <ServiceNotePrint notes={carRentalDetail.notesService} noteTypeToShow={[isDocViaggio ? 11 : isConfirmPrint ? 10 : 13]} />
            </div>
            {carRentalDetail.quotationDetail != null && carRentalDetail.quotationDetail.isManualLoading && carRentalDetail.option.note ?
                <div className="w-100 marginTopPdfAlternatives" style={{ padding: '5px', textAlign: "justify" }}> {/* da lascaire per problema split pagina react PDF */}
                    {!isNotesTooBig(carRentalDetail.option.notes) ?
                        carRentalDetail.option.notes.map((note, index) =>
                            <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(note) }} /></p>
                        )
                        :
                        <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(getNoteCut(carRentalDetail.option.notes)) }} />...</p>
                    }
                </div>
                : <></>
            }
        </>
    );
}