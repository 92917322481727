import React, { useState, useEffect } from 'react';
import { getCustomStyle } from '../service/WebContentService';

export const WebContentBasicItem = ({ item }) => {
    const [myStyle, setMyStyle] = useState({});

    useEffect(() => {
        let cssStyle = getCustomStyle(item);
        setMyStyle(cssStyle);


        try {
            let items = document.getElementsByClassName("carousel-inner shadow");
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                for (let j = 0; j < item.children.length; j++) {
                    let child = item.children[j];

                    if (!child.classList.contains('active'))
                        continue;

                    let bg = child.children[0].style.backgroundImage;
                    if (!bg || bg.includes('url("")')) {
                        item.style.display = 'none';
                    }
                }
                
            }

        } catch (ex) {

        }

        try {
            let items = document.getElementsByClassName("iubenda-embed");
            if (items && items.length > 0) {
               const script = document.createElement("script");
                script.src = "https://cdn.iubenda.com/iubenda.js";
                script.async = true;
                document.body.appendChild(script);
            }

        } catch (ex) {

        }

        try {
            let items = document.getElementsByClassName("redirect-to-home");
            if (items && items.length > 0) {
                window.location.href = "/";
            }

        } catch (ex) {

        }

    }, []);

    return <div style={myStyle} dangerouslySetInnerHTML={{ __html: item.html }}></div>;
}