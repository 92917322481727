import ReactDOMServer from "react-dom/server";
import { generateComponentInBody, replaceAllStyleElements, dynamicNotesDistincts, getIconUrlToPrintCustom, getProductIconToPrint, getIconUrlToPrint } from "../../../js/PrintUtility";
import { formatPrice } from '../../../js/Utils.js';
import { PrintStepHeader } from "../components/PrintStepHeader";
import { PrintStepItem } from "../components/PrintStepItem";
import { TourStaticPrint } from "../components/TourStaticPrint";
import { PrintAncillaries } from "../components/PrintAncillaries";
import { PrintCommissionsDetail } from "../components/PrintCommissionsDetail";
import { PrintCommissionsDetailCompact } from "../components/PrintCommissionsDetailCompact";
import { PrintPaxes } from "../components/PrintPaxes";
import { PrintDynamicNotes } from "../components/PrintDynamicNotes";
import { PrintTitle } from "../components/PrintTitle";
import { TemplatePrintInclusion } from "../template/TemplatePrintInclusion";
import { TemplatePrintStepHeader } from "../template/TemplatePrintStepHeader";
import { PrintItineraryResume } from "../components/PrintItineraryResume";
import { PrintTitleSeparator } from "../components/PrintTitleSeparator";
import { TemplatePrintHeaderDescription } from "../template/TemplatePrintHeaderDescription";
import { PrintStepItemAlternatives } from "../components/PrintStepItemAlternatives";
import { PrintProductsNotes } from "../components/PrintProductsNotes";
import { PrintScadenziario } from "../components/PrintScadenziario";
import { PrintCancelPolicy } from "../components/PrintCancelPolicy";
import { PrintProductsNotesStep } from '../components/PrintProductsNotesStep';
import configData from "../../../appsettings.json";
import { ServiceNotePrint } from "./ServiceNotePrint";

// verifica se esite qualche valore
function checkIfExistValues(recapItems) {
    let ok = recapItems.filter((item) => item.productType === "Generic")
    if (ok.length > 0)
        return true;
    else
        return false;
}

export function generateQuotationHTML(
    t,
    quotationRecapItems,
    quotation,
    itemStepPrint,
    quotationPaxes,
    scadenziario,
    dynamicNotes,
    dynamicNotesStep,
    dynamicNotesTop,
    quotationAlternatives,
    cancelPolicyArr,
    noteProdottoBigArr,
    cultureName,
    isPrintCliente = false,
    isPrintHeaderDesc,
    isPrintItineraryResume,
    isPrintPaxes,
    isPrintAncillaries,
    isPrintInclusions,
    isPrintCommissions,
    isPrintServicesNotSelected,
    isShowAlternatives,
    isPrintScadenziario,
    isPrintDynamicNotes,
    isPrintNotes,
    isPrintCancelPolicy,
    isPrintNoteBig,
    isQuotationTo,
    showCommissionDetailExplode
) {

    let myArr = [];
    let isFirstFlight = true;

    /*
    let rowTitle = ReactDOMServer.renderToString(<PrintTitle isPrintCliente={isPrintCliente} title={t("Print:QuotationTitle")} titleCliente={t("Print:QuotationClientTitle")} />);
    myArr.push({ html: generateComponentInBody(rowTitle), break: false, wrap: true });
    */

    //if (isQuotationTo) {
    //    let rTitleDescription = ReactDOMServer.renderToString(<TemplatePrintHeaderDescription
    //        title={quotation.name}
    //        description={quotation.description}
    //    />);

    //    myArr.push({ html: generateComponentInBody(rTitleDescription), break: false, wrap: true });
    //}

    /*
    if (dynamicNotesTop && dynamicNotesTop.length > 0) {
        let rowDynamicNotesTop = ReactDOMServer.renderToString(<PrintDynamicNotes
            notes={dynamicNotesTop}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowDynamicNotesTop), break: false, wrap: true });
    }
    */

    let isBreakAfter = false;

    if (isPrintPaxes && quotationPaxes && quotationPaxes.length > 0) {
        let rPaxes = ReactDOMServer.renderToString(<PrintPaxes paxes={quotationPaxes} isConfirmPrint={false} />);

        myArr.push({ html: generateComponentInBody(rPaxes), break: false, wrap: true });
        isBreakAfter = true
    }

    var finalArr = itemStepPrint;
    var idArrNote = [];
    let breakAfterFlight = false;
    for (var j = 0; j < finalArr.length; j++) {
        let qInfo = finalArr[j];
        if (qInfo.productSubType === 'Flight' && isFirstFlight) {
            let rowStep = ReactDOMServer.renderToString(<TemplatePrintStepHeader
                title={t('Product:Flight:Items')}
                PNR={qInfo.productInfo.priceBar.supplierPNR}
                totaleViaggio=''
                iconName=''
                cultureName={cultureName}
            />);

            myArr.push({ html: generateComponentInBody(rowStep), break: isBreakAfter, wrap: true });

            isFirstFlight = false;
            breakAfterFlight = true
        }

        if (qInfo.productSubType === 'Flight') {
            let rowQInfo = ReactDOMServer.renderToString(<PrintStepItem
                qInfo={qInfo}
                isPrintCliente={isPrintCliente}
                isTemplate={true}
                isNotStep={false}
                isConfirmPrint={false }
            />);

            myArr.push({ html: generateComponentInBody(rowQInfo), break: false, wrap: true });
        }
    }

    if (isShowAlternatives && quotationAlternatives.filter(c => c.productType === "Flight").length > 0) {
        let rowTemplateStepAlternatives = ReactDOMServer.renderToString(<PrintStepItemAlternatives
            alternativeArr={quotationAlternatives.filter(c => c.productType === "Flight")}
            isPrintCliente={false}
            isTemplate={true}
            isConfirmPrint={false}
        />);

        myArr.push({ html: generateComponentInBody(rowTemplateStepAlternatives), break: false, wrap: true });
    }

    /* itinerario */
    if (isPrintItineraryResume) {
        let itineraryResume_ = ReactDOMServer.renderToString(<PrintItineraryResume
            paxes={quotationPaxes}
            isConfirmPrint={false}
            isNotStep={false}
        />);

        myArr.push({ html: generateComponentInBody(itineraryResume_), break: breakAfterFlight, wrap: true });
    }

    if (quotation && quotation.quotationItemStep && quotation.quotationItemStep.length > 0) {
        quotation.quotationItemStep.map((step, stepKey) => {
            if (step.quotationItemInfo && step.quotationItemInfo.length > 0) {
                step.quotationItemInfo.filter(x => x.isSelected && x.productType !== "Flight").map((item, itemKey) => {
                    if (itemKey === 0) {
                        let rowTemplateStepHeader = ReactDOMServer.renderToString(<TemplatePrintStepHeader
                            key={stepKey}
                            title={step.stepDestinationName}
                            isStep={true}
                            stepIndex={step.step}
                            minDate={step.dateFrom}
                            maxDate={step.dateTo}
                        />);

                        myArr.push({ html: generateComponentInBody(rowTemplateStepHeader), break: false, wrap: false });
                    }

                    if (itemKey === 0 && dynamicNotesStep && dynamicNotesStep.length > 0) {
                        var showImage = true;
                        var hideText = false;

                        dynamicNotesStep.map((note, idx) => {
                            if (note.step === step.step) {
                                if (!idArrNote.find(x => x.id === note.noteId && note.step === x.step)) {
                                    idArrNote.push({ id: note.noteId, step: note.step });
                                }
                                else {
                                    hideText = true;
                                }

                                let rowNoteStep = ReactDOMServer.renderToString(<PrintProductsNotesStep
                                    notes={[note]}
                                    cultureName={cultureName}
                                    isShowImage={showImage}
                                    hideText={hideText}
                                />);

                                myArr.push({ html: generateComponentInBody(rowNoteStep), break: false, wrap: true });

                                showImage = false;
                            }
                        });
                    }
                    if (itemStepPrint.find(c => c.quotationItemId === item.id && c.productType !== "Flight")) {
                        let rowTemplateStep = ReactDOMServer.renderToString(<PrintStepItem
                            key={itemKey}
                            qInfo={itemStepPrint.find(c => c.quotationItemId === item.id && c.productType !== "Flight")}
                            isPrintCliente={false}
                            isTemplate={true}
                            isConfirmPrint={false}
                            isNotStep={false}
                            isQuotationTo={isQuotationTo}
                        />);

                        let wrap = false;
                        if (item.productType === "Flight")
                            wrap = true;

                        myArr.push({ html: generateComponentInBody(rowTemplateStep), break: false, wrap: wrap });
                    }

                    if (isShowAlternatives && quotationAlternatives.filter(c => c.quotationItemIdFather === item.id && c.productType === item.productType && item.productType !== "Flight").length > 0) {
                        let rowTemplateStepAlternatives = ReactDOMServer.renderToString(<PrintStepItemAlternatives
                            key={itemKey}
                            alternativeArr={quotationAlternatives.filter(c => c.quotationItemIdFather === item.id)}
                            isPrintCliente={false}
                            isTemplate={true}
                            isConfirmPrint={false}
                        />);

                        myArr.push({ html: generateComponentInBody(rowTemplateStepAlternatives), break: false, wrap: true });
                    }
                })

            }
        })
    }

    /*
    let rowTitleSeparator = ReactDOMServer.renderToString(<PrintTitleSeparator
        title={t(`Template:Quotation`)}
    />);

    myArr.push({ html: generateComponentInBody(rowTitleSeparator), break: true, wrap: true });
    */

    /* Quote accessorie
    if (isPrintAncillaries && checkIfExistValues(quotationRecapItems)) {
        let rAncillaries = ReactDOMServer.renderToString(<PrintAncillaries
            recapItems={quotationRecapItems}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rAncillaries), break: false, wrap: false });
    }
    */
    /*if (isPrintServicesNotSelected && checkIfExistValues(quotationRecapItems)) {
        let ServiecsNotSelectedTitle = ReactDOMServer.renderToString(<PrintStepHeader
            title="Servizi Opzionali Aggiuntivi"
            PNR=''
            totaleViaggio=''
            iconName=''
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(ServiecsNotSelectedTitle), break: false, wrap: true });

        let servicesNotSelected = ReactDOMServer.renderToString(<PrintAncillariesNotSelected
            recapItems={quotationRecapItems}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(servicesNotSelected), break: false, wrap: true });
    }*/

    /* Totale viaggio - dettaglio commissioni */
    if (isPrintCommissions && !showCommissionDetailExplode) {
        let rowCommDetail = ReactDOMServer.renderToString(<PrintCommissionsDetailCompact
            marginItems={quotationRecapItems}
            cultureName={cultureName}
            paxes={quotationPaxes}
            isConfirmPrint={false}
            isPrintCliente={isPrintCliente}
        />);

        myArr.push({ html: generateComponentInBody(rowCommDetail), break: true, wrap: false });
    }


    if (isPrintCommissions && showCommissionDetailExplode) {
        let rowCommDetail = ReactDOMServer.renderToString(<PrintCommissionsDetail
            marginItems={quotationRecapItems}
            cultureName={cultureName}
            paxes={quotationPaxes}
            isConfirmPrint={false}
            isPrintCliente={isPrintCliente}
        />);

        myArr.push({ html: generateComponentInBody(rowCommDetail), break: true, wrap: false });
    }

    /* Scadenziario
    if ((!configData.Settings.HidePrintScadenziarioCliente && isPrintScadenziario) ||
        (configData.Settings.HidePrintScadenziarioCliente && !isPrintCliente && isPrintScadenziario)
    ) {
        let rowScadenziario = ReactDOMServer.renderToString(<PrintScadenziario
            scadenziario={scadenziario}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowScadenziario), break: false, wrap: false });
    }
    * /
    
    /* Cancel Policy  */
    if (isPrintCancelPolicy && !isPrintCliente) {
        let rowCancelPolicy = ReactDOMServer.renderToString(<PrintCancelPolicy
            cancelPolicyArr={cancelPolicyArr}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowCancelPolicy), break: false, wrap: false });
    }



    /* quota comprende, non comprende
    if (template && template.inclusions && template.inclusions.length > 0 && isPrintInclusions) {
        template.inclusions.map(inclusion => {
            let rowInclusion = ReactDOMServer.renderToString(<TemplatePrintInclusion
                inclusion={inclusion}
            />);

            myArr.push({ html: generateComponentInBody(rowInclusion), break: false, wrap: true });
        })
    } */

    let isFirstTour = true;



    let schedeStatiche = [];
    let nameActivityTourArr = [];
    let nameStructureArr = [];
    let nameTourArr = [];

   if (isPrintNotes) {
    // Grouping by productSubType and excluding 'Flight' items
    const groupedBySubType = finalArr.reduce((acc, item) => {
       
            (acc[item.productSubType] = acc[item.productSubType] || []).push(item);
        
        return acc;
    }, {});

    // Sorting keys to maintain a consistent order
    const sortedSubTypes = Object.keys(groupedBySubType).sort();

    // Flag to check if break has been added before the first heading and content
    let isFirstContent = true;

    // Iterate through each productSubType group
    sortedSubTypes.forEach(subType => {
        const subTypeItems = groupedBySubType[subType];
        let hasContent = false;
        let subTypeContent = [];

        subTypeItems.forEach(qInfo => {
            if (qInfo.productType === 'Activity' && (qInfo.productInfo.option.descriptions.length > 0 || qInfo.productInfo.option.notes.length > 0)) {

                if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // exclude note in xml non DMC
                    return;

                if (nameActivityTourArr.indexOf(qInfo.productInfo.name) === -1) // check for duplicate tour
                    nameActivityTourArr.push(qInfo.productInfo.name);
                else
                    return;

                let rowSchedaSt;
                let tourName;
                let descToPrint = qInfo.productInfo.option.descriptions.filter(x => x.title !== "VOUCHER INFO");

                let rowSchedaTitle = ReactDOMServer.renderToString(
                    <PrintStepHeader
                        title={qInfo.productInfo.name}
                        PNR=''
                        totaleViaggio=''
                        productIcon={true}
                        iconName={subType.toLowerCase()}
                        cultureName={cultureName}
                    />
                );
                subTypeContent.push({ html: generateComponentInBody(rowSchedaTitle), break: false, wrap: true });

                for (var k = 0; k < descToPrint.length; k++) {
                    let newDescription = replaceAllStyleElements(descToPrint[k].description);

                    if (k > 0)
                        tourName = "";
                    else
                        tourName = qInfo.productInfo.name;

                    rowSchedaSt = ReactDOMServer.renderToString(
                        <TourStaticPrint
                            descriptions={newDescription}
                            title={descToPrint[k].title}
                            tourName={tourName}
                        />
                    );
                    subTypeContent.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
                }
                if (qInfo.productInfo.option.notes && qInfo.productInfo.option.notes.length > 0) {

                    let note = ReactDOMServer.renderToString(<>

                        <TourStaticPrint
                            titleStep={qInfo.productInfo.name}
                            descriptions={replaceAllStyleElements(qInfo.productInfo.option.notes.join(" "))}
                            title={t('Product:Structure:RoomNotes')}
                        />
                    </>)

                    subTypeContent.push({ html: generateComponentInBody(note), break: false, wrap: true });
                }

                hasContent = true;
            } else if (qInfo.productType === 'Structure' && qInfo.productInfo.descriptions.length > 0) {

                if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // exclude note in xml non DMC
                    return;

                if (nameStructureArr.indexOf(qInfo.productInfo.name) === -1) // check for duplicate
                    nameStructureArr.push(qInfo.productInfo.name);
                else
                    return;

                let rowSchedaSt;
                let tourName;
                let descToPrint = qInfo.productInfo.descriptions.filter(x => x.isPrint);

                let rowSchedaTitle = ReactDOMServer.renderToString(
                    <PrintStepHeader
                        title={qInfo.productInfo.name}
                        PNR=''
                        totaleViaggio=''
                        productIcon={true}
                        iconName={subType.toLowerCase()}
                        cultureName={cultureName}
                    />
                );
                subTypeContent.push({ html: generateComponentInBody(rowSchedaTitle), break: false, wrap: true });

                for (var k = 0; k < descToPrint.length; k++) {
                    let newDescription = replaceAllStyleElements(descToPrint[k].description);

                    if (k > 0)
                        tourName = "";
                    else
                        tourName = qInfo.productInfo.name;

                    rowSchedaSt = ReactDOMServer.renderToString(
                        <TourStaticPrint
                            descriptions={newDescription}
                            title={descToPrint[k].title}
                            tourName={tourName}
                        />
                    );
                    subTypeContent.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
                }

                if (qInfo.productInfo.rooms) {
                    for (let i = 0; i < qInfo.productInfo.rooms.length; i++) {
                        if (qInfo.productInfo.rooms[i].notes) {
                            if (qInfo.productInfo.rooms[i].notes.join(" ").length > 0) {
                                let note = ReactDOMServer.renderToString(<>

                                    <TourStaticPrint
                                        titleStep={qInfo.productInfo.name}
                                        descriptions={replaceAllStyleElements(qInfo.productInfo.rooms[i].notes.join(" <br/> "))}
                                        title={t('Product:Structure:RoomNotes')}
                                    />
                                </>)

                                subTypeContent.push({ html: generateComponentInBody(note), break: false, wrap: true });

                            }
                        }
                    }
                }
                hasContent = true;

            }
            else if (qInfo.productType === 'Tour' && qInfo.productInfo.descriptions.length > 0) {

                if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // exclude note in xml non DMC
                    return;

                if (nameTourArr.indexOf(qInfo.productInfo.name) === -1) // check for duplicate
                    nameTourArr.push(qInfo.productInfo.name);
                else
                    return;

                let rowSchedaSt;
                let tourName;
                let descToPrint = qInfo.productInfo.descriptions.filter(x => x.isPrint);

                let rowSchedaTitle = ReactDOMServer.renderToString(
                    <PrintStepHeader
                        title={qInfo.productInfo.name}
                        PNR=''
                        totaleViaggio=''
                        productIcon={true}
                        iconName={subType.toLowerCase()}
                        cultureName={cultureName}
                    />
                );
                subTypeContent.push({ html: generateComponentInBody(rowSchedaTitle), break: false, wrap: true });

                for (var k = 0; k < descToPrint.length; k++) {
                    let newDescription = replaceAllStyleElements(descToPrint[k].description);

                    if (k > 0)
                        tourName = "";
                    else
                        tourName = qInfo.productInfo.name;

                    rowSchedaSt = ReactDOMServer.renderToString(
                        <TourStaticPrint
                            descriptions={newDescription}
                            title={descToPrint[k].title}
                            tourName={tourName}
                        />
                    );
                    subTypeContent.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
                }
                if (qInfo.productInfo.rooms) {
                    for (let i = 0; i < qInfo.productInfo.rooms.length; i++) {
                        if (qInfo.productInfo.rooms[i].notes) {
                            if (qInfo.productInfo.rooms[i].notes.join(" ").length > 0) {
                                let note = ReactDOMServer.renderToString(<>

                                    <TourStaticPrint
                                        titleStep={qInfo.productInfo.name}
                                        descriptions={replaceAllStyleElements(qInfo.productInfo.rooms[i].notes.join(" "))}
                                        title={"t('Product:Structure:RoomNotes')"}
                                    />
                                </>)

                                subTypeContent.push({ html: generateComponentInBody(note), break: false, wrap: true });

                            }
                        }
                    }
                }

                hasContent = true;
            }

            else {
                if (qInfo.productInfo.notesService && qInfo.productInfo.notesService.filter((item) => item.idTypeNote == 12).length > 0) {
                    console.log(qInfo.productInfo.notesService)

                    let rowSchedaTitle = ReactDOMServer.renderToString(
                        <PrintStepHeader
                            title={qInfo.productInfo.name}
                            PNR=''
                            totaleViaggio=''
                            productIcon={true}
                            iconName={subType.toLowerCase()}
                            cultureName={cultureName}
                        />
                    );


                    subTypeContent.push({ html: generateComponentInBody(rowSchedaTitle), break: false, wrap: true });

                    let rowSchedaSt = ReactDOMServer.renderToString(
                        <TourStaticPrint
                            descriptions={qInfo.productInfo.notesService.filter((item) => item.idTypeNote == 12)[0].text}
                            title={"Descrizione prodotto"}
                        />
                    );
                    subTypeContent.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
                    hasContent = true;
                }
                
            }
        });

        if (hasContent) {
            if (isFirstContent) {
                schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });
                isFirstContent = false;
            }
            let heading = ReactDOMServer.renderToString(
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <img src={getIconUrlToPrint(subType.toLowerCase())} className="ico-image-extra-large" />
                    <div style={{ width: "6px" }}></div>
                    <div style={{ display: "flex" }}>
                        <div style={{ height: "12px" }}></div>
                        {subType}
                    </div>
                </div>
            );
            schedeStatiche.push({ html: generateComponentInBody(heading), break: false, wrap: true });
            subTypeContent.forEach(content => schedeStatiche.push(content));
        }
    });
}





    myArr.push(...schedeStatiche);

    /* Note dinamiche */
    if (isPrintDynamicNotes && dynamicNotes && dynamicNotes.length > 0) {
        let notePagamenti = dynamicNotesDistincts(dynamicNotes).filter(x => x.categoryName === "Pagamenti");
        if (notePagamenti) {
            let rowDynamicNotes = ReactDOMServer.renderToString(<PrintDynamicNotes
                notes={[notePagamenti]}
                cultureName={cultureName}
            />);

            myArr.push({ html: generateComponentInBody(rowDynamicNotes), break: false, wrap: true });
        }

        myArr.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });


        let note = dynamicNotesDistincts(dynamicNotes).filter(x => x.categoryName !== "Pagamenti");
        let rowDynamicNotes = ReactDOMServer.renderToString(<PrintDynamicNotes
            notes={[note]}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowDynamicNotes), break: false, wrap: true });
    }

    return myArr;
}