import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCDNLogoDocument } from "../../../js/CDNUtility";
import { formatDateTime } from '../../../js/Utils.js';
import Cookies from 'universal-cookie';

export const HeaderPDFPrint = ({ agency, extraInfo, isNotPrint=false }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    function getDateNow() {
        let now = new Date();
        return formatDateTime(now, cultureName, {withTime: true});
    }

    return (<>
        <div style={{ paddingTop: '24px', paddingBottom: '8px', paddingLeft: "24px", paddingRight: "24px" }}>
            <table className="border-bottom w-100">
                    <tr className="d-flex justify-content-between w-100">
                        <div className="h4" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                <img src={getCDNLogoDocument()} className="logo-print" alt="Logo" />

                            </div>

                    <div className="h4" style={{ display: "flex", flexGrow: "1", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                        {!isNotPrint &&
                            <>
                                <p className="h4">{extraInfo.name}</p>
                            </>
                        }
                        </div>
                    </tr>
                <tr className="d-flex justify-content-between w-100">
                    <div className="h4" style={{ display: "flex", flexGrow: "1",flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <p>{t(`Template:Agency`)}: </p>
                        <p className="h3" style={{ whiteSpace: "nowrap", marginBottom: "0px", marginLeft: "5px" }}>
                            {agency}
                        </p>
                    </div>
                    {extraInfo &&
                        <div className="h4" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", marginBottom: "0px", marginLeft: "5px" }}>
                            <p>{extraInfo.numberLabel}: </p>
                            <p className="h3" style={{ marginBottom: "5px", marginLeft: "5px" }}>
                                {extraInfo.quotationId}
                            </p> 
                        </div>
                    }
                </tr>
            </table>
        </div>
    </>
    )
}
