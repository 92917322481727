import React, { useEffect, useState } from 'react';
import { Engine } from "./Engine";
import Cookies from 'universal-cookie';
import { getWebsiteInfo } from '../js/Utils';


export const Home = () => {
    const [isLogged, setIsLogged] = useState(false);
    const [startModule, setStartModule] = useState('hotel');
    const cookies = new Cookies();

    useEffect(() => {

        const jCurrentUser = localStorage.getItem("CurrentUser");
        if (jCurrentUser !== null && jCurrentUser !== undefined) {
            setIsLogged(true);
        } else {
            setIsLogged(false);
        }

        let websiteInfo = getWebsiteInfo();
        if (websiteInfo && websiteInfo.startModule)
            setStartModule(websiteInfo.startModule);
    }, [])

    return (
        <>
            {
                <div>
                    <div><Engine isFake={!isLogged} startModule={startModule} /></div>
                </div>
            }
        </>
    );
}
