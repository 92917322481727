import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { Loading } from '../../Common/Loading';
import configData from "../../../appsettings.json";
import { handleError } from "../../../js/Utils";
import { manualRangeParameter } from "../../../js/Constant";
import Cookies from 'universal-cookie';

import 'react-quill/dist/quill.snow.css';

export const FormRichiestaInterventoBooking = ({ customId, onSend, onSendResponse, minDate, maxDate, resetForm, isFromCart }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();

    const [modalId, setModalId] = useState("RichiestaInterventoBooking");
    const today = new Date().toISOString().split("T")[0];
    const cultureName = cookies.get("CultureName");
    const defaultRichiestaBookingForm = {
        title: '',
        description: '',
        categoryId: -1,
        budget: undefined,
        adults: 2,
        children: 0,
        infant: 0,
        paxes: [{ id: 0, type: "ADT", name: "", surname: "", age: null }, { id: 1, type: "ADT", name: "", surname: "", age: null }],
        destination: '',
        fromLocation: '',
        startDate: minDate ? minDate.substring(0, 10) : null,
        endDate: maxDate ? maxDate.substring(0, 10) : null,
        webDestinationId: null,
        webDestinationName: null,
        webDestinationTreeId: 100,
        emptyCart: false
    };

    const [quoteTicketCategories, setQuoteTicketCategories] = useState([]);
    const [webDestinations, setWebDestinations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [onSendResponseResult, setOnSendResponseResult] = useState(null);
    const [countries, setCountries] = useState([]);

    const [richiestaBookingForm, setRichiestaBookingForm] = useState(defaultRichiestaBookingForm);

    const [validateInputData, setValidateInputData] = useState({
        isValidTitle: false,
        isTouchedTitle: false,
        isValidDescription: false,
        isTouchedDescription: false,

        isValidRefPaxName: false,
        isTouchedRefPaxName: false,
        isValidRefPaxSurname: false,
        isTouchedRefPaxSurname: false,

        isValidBudget: false,
        isTouchedBudget: false,
        isValidCitizenshipRefPax: false,
        isTouchedCitizenshipRefPax: false,
        isValidEmailRefPax: false,
        isTouchedEmailRefPax: false,
        isValidWebDestinationId: false,
        isTouchedWebDestinationId: false,
        isValidFromLocation: false,
        isTouchedFromLocation: false,

        isValidStartDate: minDate ? true : false,
        isTouchedStartDate: minDate ? true : false,
        isValidEndDate: maxDate ? true : false,
        isTouchedEndDate: maxDate ? true : false,

        isValidForm: false
    });

    useEffect(() => {
        const getCountries = async () => { callGetCountries(); }
        getCountries();

        let modalRichiestaIntereventoCommerciale = document.getElementById('modal_RichiestaInterventoBooking');
        modalRichiestaIntereventoCommerciale.addEventListener('shown.bs.modal', function () {
            setRichiestaBookingForm(defaultRichiestaBookingForm);
        });

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}constant/getticketquotecategories`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuoteTicketCategories(item);
            })
            .catch((err) => {
                console.error(err);
            });

        fetch(`${configData.Settings.CommonApi_BaseUrl}constant/GetWebDestinationsList/${cultureName}/100`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setWebDestinations(item);
            })
            .catch((err) => {
                console.error(err);
            });

        if (customId)
            setModalId(customId);

    }, []);

    async function callGetCountries() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Constant/GetCountries/' + cultureName, requestOption);
        const response = await fetchedRes.json();
        setCountries(response);
    }


    useEffect(() => {
        setIsLoading(false);
        setOnSendResponseResult(onSendResponse);
    }, [onSendResponse]);

    function onBlurEvent(event, propertyName) {
        let updateRichiestaBookingForm = { ...richiestaBookingForm };
        let newValue = '';
        switch (propertyName) {
            case 'emptyCart': {
                newValue = event.target.checked;
                break;
            }
            case 'description': {
                newValue = event;
                break;
            }
            default: {
                newValue = event.target.value;
                break;
            }
        }

        let prevValidateInputData = { ...validateInputData };
        let isValidForm = true;
        switch (propertyName) {
            case "title":
                {
                    delete prevValidateInputData.isValidTitle;
                    delete prevValidateInputData.isTouchedTitle;
                    delete prevValidateInputData.isValidForm;

                    let isNotEmpty = (newValue !== '' && newValue !== undefined);
                    isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                    setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidTitle: isNotEmpty, isTouchedTitle: true });
                }
                break;
            case "description":
                {
                    delete prevValidateInputData.isValidDescription;
                    delete prevValidateInputData.isTouchedDescription;
                    delete prevValidateInputData.isValidForm;
                    let isValidQuill = newValue ? true : false;

                    let replace = newValue.replaceAll(/<\/?[^>]+(>|$)/gi, "");
                    let isNotEmpty = (replace !== '' && replace !== undefined);

                    isValidForm = isValidQuill && isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                    setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidDescription: isNotEmpty, isTouchedDescription: true });
                }
                break;

            case "adults":
                {
                    let numAdults = event.target.value ? parseInt(event.target.value) : 0;

                    if (numAdults > manualRangeParameter.hotel.maxAdults) {
                        numAdults = manualRangeParameter.hotel.maxAdults;
                    } else if (numAdults < manualRangeParameter.hotel.minAdults) {
                        numAdults = manualRangeParameter.hotel.minAdults;
                    }

                    if (numAdults > updateRichiestaBookingForm[propertyName]) {
                        let paxToAdd = numAdults - updateRichiestaBookingForm[propertyName];
                        for (let i = 0; i < paxToAdd; i++) {
                            updateRichiestaBookingForm.paxes.push({ id: 0, type: "ADT", name: "", surname: "", age: undefined });
                        }
                    } else if (numAdults < updateRichiestaBookingForm[propertyName]) {
                        let toRemove = updateRichiestaBookingForm[propertyName] - numAdults;
                        let adtPaxes = updateRichiestaBookingForm.paxes.filter(x => x.type === "ADT");
                        for (let i = 0; i < toRemove; i++)
                            adtPaxes.pop();

                        let updatePaxes = updateRichiestaBookingForm.paxes.filter(x => x.type !== "ADT");
                        for (let i = 0; i < adtPaxes.length; i++)
                            updatePaxes.push(adtPaxes[i]);

                        updateRichiestaBookingForm.paxes = updatePaxes;
                    }

                    newValue = numAdults;
                }
                break;

            case "children":
            case "infant":
                {
                    let numChildren = event.target.value ? parseInt(event.target.value) : 0;

                    if (numChildren > manualRangeParameter.hotel.maxChildren) {
                        numChildren = manualRangeParameter.hotel.maxChildren;
                    } else if (numChildren < 0) {
                        numChildren = 0;
                    }

                    if (numChildren > updateRichiestaBookingForm[propertyName]) {
                        let paxToAdd = numChildren - updateRichiestaBookingForm[propertyName];

                        for (let i = 0; i < paxToAdd; i++) {
                            if (propertyName === 'children') {
                                updateRichiestaBookingForm.paxes.push({ id: 0, type: "CHD", name: "", surname: "", age: undefined });
                            } else {
                                updateRichiestaBookingForm.paxes.push({ id: 0, type: "INF", name: "", surname: "", age: undefined });
                            }
                        }
                    } else if (numChildren < updateRichiestaBookingForm[propertyName]) {

                        let toRemove = updateRichiestaBookingForm[propertyName] - numChildren;
                        let paxType = propertyName === 'children' ? "CHD" : "INF";

                        let myPaxes = updateRichiestaBookingForm.paxes.filter(x => x.type === paxType);
                        for (let i = 0; i < toRemove; i++)
                            myPaxes.pop();

                        let updatePaxes = updateRichiestaBookingForm.paxes.filter(x => x.type !== paxType);
                        for (let i = 0; i < myPaxes.length; i++)
                            updatePaxes.push(myPaxes[i]);

                        updateRichiestaBookingForm.paxes = updatePaxes;
                    }

                    newValue = numChildren;
                }
                break;

            case "startDate": {
                delete prevValidateInputData.isValidStartDate;
                delete prevValidateInputData.isTouchStartDate;
                delete prevValidateInputData.isValidForm;

                let isNotEmpty = (newValue !== '' && newValue !== undefined);
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                const isValidStartDate = (new Date(newValue) !== "Invalid Date") && new Date(newValue) >= new Date(today);
                if (isValidStartDate) {
                    const isValidEndDate = (new Date(updateRichiestaBookingForm.endDate) !== "Invalid Date") && new Date(newValue) < new Date(updateRichiestaBookingForm.endDate);
                    if (!isValidEndDate && updateRichiestaBookingForm.endDate)
                        updateRichiestaBookingForm['endDate'] = newValue;
                }

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidStartDate: isNotEmpty, isTouchedStartDate: true });
            }
                break;

            case "endDate": {
                delete prevValidateInputData.isValidEndDate;
                delete prevValidateInputData.isTouchedEndDate;
                delete prevValidateInputData.isValidForm;

                let isNotEmpty = (newValue !== '' && newValue !== undefined);
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidEndDate: isNotEmpty, isTouchedEndDate: true });

            }
                break;

            case "budget": {
                delete prevValidateInputData.isValidBudget;
                delete prevValidateInputData.isTouchedBudget;
                delete prevValidateInputData.isValidForm;

                let isNotEmpty = (newValue !== '' && newValue !== undefined);
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidBudget: isNotEmpty, isTouchedBudget: true });

                newValue = newValue.replaceAll(',', '');
                newValue = newValue.replaceAll('.', '');
            }
                break;

            case "citizenshipRefPax": {
                delete prevValidateInputData.isValidCitizenshipRefPax;
                delete prevValidateInputData.isTouchedCitizenshipRefPax;
                delete prevValidateInputData.isValidForm;

                let isNotEmpty = (newValue !== '' && newValue !== undefined);
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidCitizenshipRefPax: isNotEmpty, isTouchedCitizenshipRefPax: true });
            }
                break;

            case "emailRefPax": {
                delete prevValidateInputData.isValidEmailRefPax;
                delete prevValidateInputData.isTouchedEmailRefPax;
                delete prevValidateInputData.isValidForm;

                let isNotEmpty = (newValue !== '' && newValue !== undefined);
                const isValidMailFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(newValue);
                isValidForm = isNotEmpty && isValidMailFormat && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidEmailRefPax: isNotEmpty && isValidMailFormat, isTouchedEmailRefPax: true });

            }
                break;
            case "webDestinationId": {
                delete prevValidateInputData.isValidWebDestinationId;
                delete prevValidateInputData.isTouchedWebDestinationId;
                delete prevValidateInputData.isValidForm;

                let isNotEmpty = (newValue !== '' && newValue !== undefined);
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidWebDestinationId: isNotEmpty, isTouchedWebDestinationId: true });
            }
                break;
            case "fromLocation": {
                delete prevValidateInputData.isValidFromLocation;
                delete prevValidateInputData.isTouchedFromLocation;
                delete prevValidateInputData.isValidForm;

                let isNotEmpty = (newValue !== '' && newValue !== undefined);
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidFromLocation: isNotEmpty, isTouchedFromLocation: true });
            }
                break;
            default:
                break;
        }

        if (propertyName === "adults" || propertyName === "children" || propertyName === "infant") {
            // ricalcolo l'index di tutti i pax
            let paxAdt = updateRichiestaBookingForm.paxes.filter(x => x.type === "ADT");
            let paxChd = updateRichiestaBookingForm.paxes.filter(x => x.type === "CHD");
            let paxInf = updateRichiestaBookingForm.paxes.filter(x => x.type === "INF");

            let outPaxes = [];
            let paxId = 0;
            for (let i = 0; i < paxAdt.length; i++) {
                paxAdt[i].id = paxId++;
                outPaxes.push(paxAdt[i]);
            }
            for (let i = 0; i < paxChd.length; i++) {
                paxChd[i].id = paxId++;
                outPaxes.push(paxChd[i]);
            }
            for (let i = 0; i < paxInf.length; i++) {
                paxInf[i].id = paxId++;
                outPaxes.push(paxInf[i]);
            }
            updateRichiestaBookingForm.paxes = outPaxes;
        }

        updateRichiestaBookingForm[propertyName] = newValue;
        setRichiestaBookingForm(updateRichiestaBookingForm);
    }

    function onBlurPaxEvent(event, propertyName, index) {
        let updateRichiestaBookingForm = { ...richiestaBookingForm };
        let newValue = event.target.value;

        updateRichiestaBookingForm.paxes[index][propertyName] = newValue;
        if (index === 0) {
            let prevValidateInputData = { ...validateInputData };
            let isNotEmpty = (newValue !== '' && newValue !== undefined);
            let isValidForm = true;

            switch (propertyName) {
                case "name": {
                    delete prevValidateInputData.isTouchedRefPaxName;
                    delete prevValidateInputData.isValidRefPaxName;
                    delete prevValidateInputData.isValidForm;


                    isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                    setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidRefPaxName: isNotEmpty, isTouchedRefPaxName: true });
                    break;
                }
                case "surname": {
                    delete prevValidateInputData.isTouchedRefPaxSurname;
                    delete prevValidateInputData.isValidRefPaxSurname;
                    delete prevValidateInputData.isValidForm;


                    isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                    setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidRefPaxSurname: isNotEmpty, isTouchedRefPaxSurname: true });
                    break;
                }
            }
        }

        setRichiestaBookingForm(updateRichiestaBookingForm);
    }

    function sendRequest() {
        setIsLoading(true);
        setOnSendResponseResult(null);
        onSend(richiestaBookingForm);
    }

    return (
        <>
            <a id={"open_" + modalId} data-bs-toggle="modal" data-bs-target={"#modal_" + modalId}></a>
            < div className="modal fade" id={"modal_" + modalId} tabIndex="-1" aria-labelledby="modal_RichiestaInterventoBookingLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal_RichiestaInterventoBookingLabel">{t("MyBook:AskBooking")}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {
                                (!onSendResponseResult || !onSendResponseResult.success) && !isLoading
                                    ? (
                                        <>
                                            {/*Row 1 (Title, Category, Budget)*/}
                                            <div className="row">
                                                <div className={"form-floating col-6" + (!validateInputData.isValidTitle && validateInputData.isTouchedTitle ? " insert-danger" : "")}>
                                                    <input id="richiestaInterventoBooking_Titolo"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Titolo Richiesta"
                                                        value={richiestaBookingForm.title}
                                                        onChange={(e) => { onBlurEvent(e, 'title'); }} />
                                                    <label htmlFor="richiestaInterventoBooking_Titolo" className="active">Titolo Richiesta (*)</label>
                                                    {
                                                        validateInputData.isTouchedTitle && !validateInputData.isValidTitle && <div className="text-danger">Il campo titolo richiesta è obbligatorio</div>
                                                    }
                                                </div>
                                                <div className="form-floating col-3">
                                                    <select id="ddlCategoryTicket"
                                                        className="form-select border-0 border-bottom border-dark"
                                                        aria-label="ticketQuoteCategory"
                                                        onChange={(e) => { onBlurEvent(e, 'categoryId'); }} >
                                                        <option value="-1"></option>
                                                        {
                                                            quoteTicketCategories.map((item) => {
                                                                return <option key={item.key} value={item.key}>{item.text}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <label htmlFor="ddlCategoryTicket">Categoria</label>
                                                </div>
                                                <div className="form-floating col-3">
                                                    <input id="richiestaInterventoBooking_Budget"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Budget"
                                                        value={richiestaBookingForm.budget}
                                                        onChange={(e) => { onBlurEvent(e, 'budget'); }}
                                                        min="0"
                                                        step="1"
                                                        pattern="\d+"
                                                    />
                                                    <label htmlFor="richiestaInterventoBooking_Budget" className="active">Budget (€)(*)</label>
                                                    {
                                                        validateInputData.isTouchedBudget && !validateInputData.isValidBudget && <div className="text-danger">Il campo budget è obbligatorio</div>
                                                    }
                                                </div>
                                            </div>

                                            {/*Row 2 (Dates and Destinations)*/}
                                            <div className="row mt-2">
                                                <div className="form-floating col-3">
                                                    <input id="richiestaInterventoBooking_StartDate"
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Data inizio"
                                                        value={richiestaBookingForm.startDate}
                                                        min={today}
                                                        onChange={(e) => { onBlurEvent(e, 'startDate'); }} />
                                                    <label htmlFor="richiestaInterventoBooking_StartDate" className="active">Data inizio (*)</label>
                                                    {
                                                        validateInputData.isTouchedStartDate && !validateInputData.isValidStartDate && <div className="text-danger"> Il campo Data inizio è obbligatorio</div>
                                                    }
                                                </div>
                                                <div className="form-floating col-3">
                                                    <input id="richiestaInterventoBooking_EndDate"
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Data fine"
                                                        value={richiestaBookingForm.endDate}
                                                        min={richiestaBookingForm.startDate}
                                                        onChange={(e) => { onBlurEvent(e, 'endDate'); }} />
                                                    <label htmlFor="richiestaInterventoBooking_EndDate" className="active">Data fine (*)</label>
                                                    {
                                                        validateInputData.isTouchedEndDate && !validateInputData.isValidEndDate && <div className="text-danger">Il campo Data fine è obbligatorio</div>
                                                    }
                                                </div>
                                                <div className="form-floating col-3 d-none">
                                                    <input id="richiestaInterventoBooking_Destination"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Destinazione"
                                                        value={richiestaBookingForm.destination}
                                                        onChange={(e) => { onBlurEvent(e, 'destination'); }} />
                                                    <label htmlFor="richiestaInterventoBooking_Destination" className="active">Destinazione (*)</label>
                                                </div>
                                                <div className="form-floating col-3">
                                                    <input id="richiestaInterventoBooking_APT"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Punto di partenza"
                                                        value={richiestaBookingForm.fromLocation}
                                                        onChange={(e) => { onBlurEvent(e, 'fromLocation'); }}
                                                        min="0" />
                                                    <label htmlFor="richiestaInterventoBooking_APT" className="active">Punto di partenza (*)</label>
                                                    {
                                                        validateInputData.isTouchedFromLocation && !validateInputData.isValidFromLocation && <div className="text-danger">Il punto di partenza è obbligatorio</div>
                                                    }
                                                </div>
                                                <div className="form-floating col-3">
                                                    <select id="richiestaInterventoBooking_WebDestination"
                                                        className="form-select border-0 border-bottom border-dark"
                                                        aria-label="ticketQuoteCategory"
                                                        onChange={(e) => { onBlurEvent(e, 'webDestinationId'); }} >
                                                        <option value="-1"></option>
                                                        {
                                                            webDestinations.map((item) => {
                                                                return <option key={item.key} value={item.key}>{item.text}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <label htmlFor="richiestaInterventoBooking_WebDestination">Destinazione*</label>
                                                    {
                                                        validateInputData.isTouchedWebDestinationId && !validateInputData.isValidWebDestinationId && <div className="text-danger">La destinazione è obbligatoria</div>
                                                    }
                                                </div>
                                            </div>
                                            {/*Row 3 (Descrizione)*/}
                                            <div className="row mt-2">
                                                <div className="form-floating col-12 mt-4 mb-4" style={{ 'height': '80px' }}>
                                                    <label htmlFor="reactQuillDescrizioneBooking" className="active" style={{ 'top': '-35px' }}>Descrizione (*)</label>

                                                    <ReactQuill
                                                        id="reactQuillDescrizioneBooking"
                                                        theme="snow"
                                                        className={validateInputData.isTouchedDescription && !validateInputData.isValidDescription ? " border-danger mt-2" : "mt-2"}
                                                        modules={{ clipboard: { matchVisual: true } }}
                                                        value={richiestaBookingForm.description}
                                                        style={{ 'height': '80px' }}
                                                        onChange={(value) => {
                                                            onBlurEvent(value, 'description');
                                                        }}
                                                        onBlur={(range, source, quill) => {
                                                            onBlurEvent(quill.getHTML(), 'description');
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-floating col-12">
                                                    {
                                                        validateInputData.isTouchedDescription && !validateInputData.isValidDescription && <div className="text-danger mb-4 position-relative" style={{ 'top': '55px' }}>Inserire una descrizione</div>
                                                    }
                                                </div>
                                            </div>

                                            {/*Row 4 (Passeggeri Header)*/}
                                            <div className="row mt-5">
                                                <div className="form-floating col-2">
                                                    Composizione Passeggeri
                                                </div>
                                                <div className="form-floating col-3">
                                                    <input id="richiestaInterventoBooking_Adulti"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Numero Adulti"
                                                        value={richiestaBookingForm.adults}
                                                        onChange={(e) => { onBlurEvent(e, 'adults'); }}
                                                        min="1"
                                                        max={manualRangeParameter.hotel.maxAdults}
                                                        step="1" />
                                                    <label htmlFor="richiestaInterventoBooking_Adulti" className="active">Numero Adulti (*)</label>
                                                </div>
                                                <div className="form-floating col-3">
                                                    <input id="richiestaInterventoBooking_Bambini"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Numero Bambini"
                                                        value={richiestaBookingForm.children}
                                                        onChange={(e) => { onBlurEvent(e, 'children'); }}
                                                        min="0"
                                                        max={manualRangeParameter.hotel.maxChildren}
                                                        step="1" />
                                                    <label htmlFor="richiestaInterventoBooking_Bambini" className="active">Numero Bambini (*)</label>
                                                </div>
                                                <div className="form-floating col-3">
                                                    <input id="richiestaInterventoBooking_Infant"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Numero Infant"
                                                        value={richiestaBookingForm.infant}
                                                        onChange={(e) => { onBlurEvent(e, 'infant'); }}
                                                        min="0"
                                                        max={manualRangeParameter.hotel.maxChildren}
                                                        step="1" />
                                                    <label htmlFor="richiestaInterventoBooking_Infant" className="active">Numero Infant (*)</label>
                                                </div>
                                            </div>

                                            {/*Row 5 (Passeggeri Info)*/}
                                            {
                                                richiestaBookingForm.paxes.length > 0 && richiestaBookingForm.paxes.map((pax, key) => {
                                                    return <div key={key} className="row mt-2">
                                                        <div className="row">
                                                            <div className="form-floating col-2 pt-2">
                                                                {
                                                                    key === 0
                                                                        ? "Pax 1 (Ref.)"
                                                                        : pax.type === "ADT"
                                                                            ? ("Pax " + (key + 1))
                                                                            : pax.type === "CHD"
                                                                                ? "Bambino"
                                                                                : "Infant"
                                                                }
                                                            </div>
                                                            <div className={"form-floating col-4" + (key === 0 && !validateInputData.isValidRefPaxName && validateInputData.isTouchedRefPaxName ? " insert-danger" : "")}>
                                                                <input id={"richiestaInterventoBooking_NomePax" + key}
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Nome"
                                                                    value={richiestaBookingForm.paxes[key].name}
                                                                    onChange={(e) => { onBlurPaxEvent(e, 'name', key); }}
                                                                />
                                                                <label htmlFor={"richiestaInterventoBooking_NomePax" + key} className="active">Nome {key === 0 && <> (*)</>}</label>
                                                                {
                                                                    key === 0 && validateInputData.isTouchedRefPaxName && !validateInputData.isValidRefPaxName && <div className="text-danger">{t('Login:FieldNameIsMandatory')}</div>
                                                                }
                                                            </div>
                                                            <div className={"form-floating col-4" + (key === 0 && !validateInputData.isValidRefPaxSurname && validateInputData.isTouchedRefPaxSurname ? " insert-danger" : "")}>
                                                                <input id={"richiestaInterventoBooking_CognomePax" + key}
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Cognome"
                                                                    value={richiestaBookingForm.paxes[key].surname}
                                                                    onChange={(e) => { onBlurPaxEvent(e, 'surname', key); }} />
                                                                <label htmlFor={"richiestaInterventoBooking_CognomePax" + key} className="active">Cognome {key === 0 && <> (*)</>}</label>
                                                                {
                                                                    key === 0 && validateInputData.isTouchedRefPaxSurname && !validateInputData.isValidRefPaxSurname && <div className="text-danger">Il campo cognome è obbligatorio</div>
                                                                }
                                                            </div>
                                                            <div className="form-floating col-2">
                                                                <input id={"richiestaInterventoBooking_Eta" + key}
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Età"
                                                                    value={richiestaBookingForm.paxes[key].age}
                                                                    onChange={(e) => { onBlurPaxEvent(e, 'age', key); }} />
                                                                <label htmlFor={"richiestaInterventoBooking_Eta" + key} className="active">Età</label>
                                                            </div>
                                                        </div>
                                                        {
                                                            key == 0 ?
                                                                <div className="row">
                                                                    <div className="form-floating col-2 pt-2">&nbsp;</div>
                                                                    <div className="form-floating col-5">
                                                                        <select id="richiestaInterventoBooking_PaxRifNazionalita"
                                                                            className="form-select border-0 border-bottom border-dark"
                                                                            aria-label="ticketQuoteCategory"
                                                                            onChange={(e) => { onBlurEvent(e, 'citizenshipRefPax'); }} >
                                                                            <option value="-1"></option>
                                                                            {
                                                                                countries.map((item) => {
                                                                                    return <option key={item.iso2} value={item.iso2}>{item.name}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                        <label htmlFor="richiestaInterventoBooking_PaxRifNazionalita">Naz.tà Pax.Rif. (*)</label>
                                                                        {
                                                                            validateInputData.isTouchedCitizenshipRefPax && !validateInputData.isValidCitizenshipRefPax && <div className="text-danger">Il campo budget è obbligatorio</div>
                                                                        }
                                                                    </div>
                                                                    <div className="form-floating col-5">
                                                                        <input id="richiestaInterventoBooking_PaxRifEmail"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Email Pax Rif."
                                                                            value={richiestaBookingForm.EmailRefPax}
                                                                            onChange={(e) => { onBlurEvent(e, 'emailRefPax'); }} />
                                                                        <label htmlFor="richiestaInterventoBooking_PaxRifEmail" className="active">Email PaxRif.(*)</label>
                                                                        {
                                                                            validateInputData.isTouchedCitizenshipRefPax && !validateInputData.isValidCitizenshipRefPax && <div className="text-danger">L'Email del Pax di riferimento è obbligatoria e deve essere valida</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                : ''
                                                        }

                                                    </div>
                                                })
                                            }

                                            {
                                                isFromCart && <div className="row mt-4">
                                                    <div className="form-floating col-12">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                type="checkbox"
                                                                id="sendMailRB_emptyCart"
                                                                checked={richiestaBookingForm.emptyCart}
                                                                defaultChecked={richiestaBookingForm.emptyCart}
                                                                onChange={(e) => { onBlurEvent(e, 'emptyCart'); }}
                                                                value="option2" />
                                                            <label className="text-dark" for="sendMailRB_emptyCart">
                                                                <data m3lab="Cart.EmptyCart_AfterQuotationSend">{t('Cart:EmptyCart_AfterQuotationSend')}</data>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )
                                    : <></>
                            }
                            {
                                <>
                                    {isLoading && !onSendResponseResult && <div className="mt-2"><Loading textMsg={t('MyBook:Loading')} /></div>}
                                    {
                                        !isLoading && onSendResponseResult && onSendResponseResult.success && <div className="row mt-4 mb-4">
                                            <div className="col-12 text-center">
                                                <h5>Richiesta {onSendResponseResult.ticketId} - Preventivo {onSendResponseResult.quotationId}</h5>
                                            </div>

                                            <div className="col-12 text-center penalty free">
                                                La tua richiesta è stata salvata con successo, a breve riceverai una email riepilogativa.
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        <div className='modal-footer'>
                            {
                                !isLoading && onSendResponseResult && !onSendResponseResult.success && <span className="text-danger" style={{ float: 'left', margin: '0 auto' }}>{t('Cart:CartErrorGeneric')}</span>
                            }
                            {(!onSendResponseResult || !onSendResponseResult.success) && !isLoading && <span style={{ float: 'left', margin: '0 auto' }}>Cliccando su "Invia" verrà creato un preventivo e riceverai una email di conferma</span>}
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t('Button:Close')}</button>
                            {(!onSendResponseResult || !onSendResponseResult.success) && !isLoading && <button type="button" className="btn bt-sm tp-btn-confirm" disabled={!validateInputData.isValidForm} onClick={() => sendRequest()}>Invia</button>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
