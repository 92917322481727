import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatDateTime, getStatusClass } from '../../../js/Utils.js';
import { imageOnError, imageOnLoadTransfer } from '../../../js/CDNUtility';
import { getIconUrlToPrint, replaceAllStyleElements, isNotesTooBig, getAllFirstUppercase, getNoteCut } from '../../../js/PrintUtility';
import { PdfPeople } from '../../Common/PdfPeople.js';
import { ServiceNotePrint } from '../service/ServiceNotePrint.js';


export const TransferDetailPrint = ({ id, type, isDocViaggio, isNotStep, transferDetail, isPrintCliente, isShowNote = false, isConfirmPrint, isHideCancelPolicy }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    
    return (
        <>
            {/*Transfer*/}
            {transferDetail &&
                <table className="w-100 border shadow">
                    <tbody>
                        <tr className="shadow">
                            <td className="widthTdImage">
                                <img src={`data:image;base64, ` + transferDetail.thumbUrlB} alt="Thumb" className="imgProductTransfer" onError={imageOnError} />
                            </td>
                            <td className="w-80  px-3">
                                <table className="w-100">
                                    <tbody>
                                        <tr className="h6">
                                            <td colspan="2" className="fontBold">
                                                {getAllFirstUppercase(transferDetail.name)}
                                            </td> 
                                            <td className="text-right" style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                                                {isConfirmPrint && transferDetail.priceBar && transferDetail.priceBar.wsBookingId &&
                                                    <>
                                                    N. PNR: {transferDetail.priceBar.wsBookingId}
                                                    </>
                                                }
                                                <div style={{width: "50px"} }></div>
                                                <PdfPeople adults={transferDetail.adults} children={transferDetail.children} />

                                            </td>
                                        </tr>
                                        <tr className="h5">
                                            <td colspan="4">
                                            </td>
                                            <td className="col-2 text-right">
                                                {isConfirmPrint && transferDetail.cancelPolicy && !transferDetail.cancelPolicy.isFree &&
                                                    <span className={"h6 status " + getStatusClass(transferDetail.priceBar.status)}>{t(`statiPrenotazione:` + transferDetail.priceBar.status)}</span>
                                                }
                                            </td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td className="w-imagePdf">*/}
                                        {/*        <img src={getIconUrlToPrint('hail')} className="ico-image mr-1" alt="" />*/}
                                        {/*    </td>*/}
                                        {/*    <td className="w-40">*/}
                                        {/*        <label>{transferDetail.vehicle && transferDetail.vehicle.type}</label>*/}
                                        {/*    </td>*/}
                                        {/*    <td className="col-4">*/}
                                               
                                        {/*    </td>*/}
                                           
                                        {/*</tr>*/}
                                        {transferDetail.outbound &&
                                        <>
                                            {/*<tr>Tratta 1</tr>*/}
                                            <tr>
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('carRental')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    {/*<label>{formatDateTime(transferDetail.outbound.date, cultureName)}&nbsp;&nbsp;</label>*/}
                                                    <label className="mr-1">{t(`Product:Transfer:ArrivalFrom`)}: &nbsp;</label>
                                                    <label className="font-weight-bold">{transferDetail.outbound.from}&nbsp;</label>
                                                    <label className="mr-1">{t(`Product:Transfer:ArrivalTo`)}: &nbsp;</label>
                                                    <label>{transferDetail.outbound.to}</label>
                                                </td>
                                            </tr>
                                        </>
                                        }
                                        {transferDetail.return &&
                                        <>
                                            <tr>Tratta 2</tr>
                                            <tr>
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('carRental')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    <label>{formatDateTime(transferDetail.return.date, cultureName)}&nbsp;&nbsp;</label>
                                                    <label className="mr-1">{t(`Product:Transfer:ReturnFrom`)}:&nbsp;</label>
                                                    <label className="font-weight-bold">{transferDetail.return.from}&nbsp;</label>
                                                    <label className="mr-1">{t(`Product:Transfer:ReturnTo`)}:&nbsp;</label>
                                                    <label>{transferDetail.return.to}</label>
                                                </td>
                                            </tr>
                                        </>
                                        }
                                        <tr>
                                            <td colspan="2">
                                                {transferDetail.note && transferDetail.priceBar && transferDetail.priceBar.isDMC && isShowNote &&
                                                <>
                                                    <div className="h075 text-left">{t(`General:Note`)}:</div>
                                                    <div>
                                                        <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: transferDetail.note }} /></p>
                                                    </div>
                                                </>
                                                }
                                            </td>
                                        </tr>
                                        {transferDetail.extras && transferDetail.extras.length > 0 && transferDetail.extras.map((extra, key) =>
                                            <tr>
                                                <td className="w-60 text-left">
                                                    <label>Extra &nbsp;{key + 1}:&nbsp;</label>
                                                    <label dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(extra.description) }} />
                                                </td>
                                            </tr>
                                        )}
                                        {!isHideCancelPolicy && transferDetail.cancelPolicy && !transferDetail.cancelPolicy.isFree &&
                                            <tr>
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('localAtm')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    {transferDetail.cancelPolicy.isFree ?
                                                        <>
                                                            <label className="text-success">{t(`CancelPolicy:FreeCancellation`)} {formatDateTime(transferDetail.cancelPolicy.expirationDate, { twoDigits: true })}</label>
                                                        </>
                                                        :
                                                        <label className="penalty">{t(`CancelPolicy:PenaltyCancellation`)}</label>
                                                    }
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            {transferDetail.notesService.filter(x => [isDocViaggio ? 11 : isConfirmPrint ? 10 : 13].some(y => y === x.idTypeNote)).length > 0 && <div style={{ padding: "5px" }}>

                <ServiceNotePrint notes={transferDetail.notesService} noteTypeToShow={[isDocViaggio ? 11 : isConfirmPrint ? 10 : 13]} />
            </div>}
        </>
    );
}