import React, { useState, useEffect } from 'react';
import configData from "../../../appsettings.json";
import { getWebsiteId } from '../../../js/Utils.js';

export const ProductHeader = ({ text, imageUrl, pageCode, height }) => {
    const [dynamicImage, setDynamicImage] = useState('');
    const [isVideo, setIsVideo] = useState(false);
    const [imageTitle, setImageTitle] = useState('');
    const [imageDescription, setImageDescription] = useState('');

    useEffect(() => {
        if (pageCode) {
            async function getData() {
                const response = await fetch(configData.Settings.CommonApi_BaseUrl + `webcontent/getphotogallery/${pageCode}/` + getWebsiteId()).then(data => data.json());
                if (response && response.length > 0) {
                    setDynamicImage(response[0].url);

                    let isVideoUrl = response[0].url.includes('.mp4');
                    setIsVideo(isVideoUrl);

                    if (!text) {
                        setImageTitle(response[0].imageTitle);
                        setImageDescription(response[0].imageDescription);
                    }
                }
            }
            getData();
        } else {
            setDynamicImage(imageUrl);
        }


    }, [])

    return (
        <>
            {dynamicImage &&
                <div className="d-none d-sm-block" style={{ backgroundImage: `url("${dynamicImage}")`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "400px" }}>
                    <div className="w-100 text-center">{text}</div>

                    {
                        (imageTitle || imageDescription) && <div className="container h-100">
                            <div className="row text-center h-100" style={{ alignItems: 'center' }}>
                                <div className="col-12">
                                    {imageTitle && <div className="h2 text-white w-100 text-center" dangerouslySetInnerHTML={{ __html: imageTitle }}></div>}
                                    {imageDescription && <div className="h5 text-white w-100 text-center" dangerouslySetInnerHTML={{ __html: imageDescription }}></div>}
                                </div>
                            </div>
                        </div>
                    }

                </div>
            }
        </>
    );
}