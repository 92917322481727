import React from 'react';

export const PrintVoucherFooter = () => {

    return (<>
        <table>
            <tr className="row" style={{ borderTop: '1px solid #d0d1d6', marginTop: '10px', }}>
                <td className="col-lg-12">
                    <div style={{ textAlign: 'center' }}>
                        <span style={{ textAlign: 'center' }}>
                        First Travel Swiss Sagl, Via Bellinzona 6 - Giubiasco, 6512 - Bellinzona - CH
                        <br />
                        Contact: sales@myfirst.travel - Tel. +41 91 211 01 66
                        <br />
                        <br />
                        Registered Office:
                        First Travel Swiss Sagl - Via Giulia 43 - 6855 Stabio
                    </span>
                    </div>
                </td>
            </tr>
            <tr className="row">
                <td className="col-lg-12">
                    <span>
                    </span>
                </td>
            </tr>
        </table>
    </>);
}