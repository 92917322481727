import React from 'react';
import { formatDateTime } from '../../../../js/Utils.js';
import Cookies from 'universal-cookie';
import { getPrimaryColor } from '../PrintCss.js';
import { useTranslation } from 'react-i18next';

export const FooterFirstPagePDFPrint = ({ title, extraInfo }) => {

    const { t } = useTranslation();

    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const tableStyle = {
        backgroundColor: '#F4EAD5', // Adjust the color as needed
        color: '#000',
        width: '100%',
        borderCollapse: 'collapse',
    };

    const rowStyle = {
        border: 'none',
    };

    const cellStyle = {
        padding: '10px',
        fontSize: "12px"
    };

    const titleCellStyle = {
        ...cellStyle,
        fontWeight: 'bold',
        color: getPrimaryColor(), // Adjust to match your specific color
        fontSize: '36px',
        padding: "0px",
        margin: "0px"
    };

    const agencyCellStyle = {
        ...cellStyle,
        width: "100%",
        textAlign: 'left',
        fontSize: '16px',

    };

    return (
        <table style={tableStyle}>
            <tbody style={{ padding: "16px 24px" }}>
                <tr style={rowStyle}>
                    <td style={cellStyle}>

                        <span style={{ color: getPrimaryColor() }} className="footerdatasize">{t('General:Date')}:</span> <span className="footerdatasize">{formatDateTime(extraInfo.date, cultureName)} </span><br />
                        <div style={{height: '16px'} }></div>
                        <span className="fontBold firstfootertitle" style={titleCellStyle} >{extraInfo.subtitle ? extraInfo.subtitle : 'PREVENTIVO N°'} {extraInfo.quotationId}</span>
                        
                    </td>
                    <td style={agencyCellStyle}>
                        <span style={{ color: getPrimaryColor(), textAlign: 'right', paddingBottom: "8px" }} className="anegcytextsize">{t('General:Agency')}:</span><br />
                        <div style={{height: '4px'} }></div> 

                        <span className="fontBold anegcytextsize" style={{ textAlign: 'right' }}>{extraInfo.agencyName} </span>
                        <br />
                        {extraInfo.quotationHeader && <span className="anegcytextsize" style={{ textAlign: 'right' }}> {extraInfo.quotationHeader.address}</span>}
                        <br />
                        {extraInfo.quotationHeader && <span className="anegcytextsize" style={{ textAlign: 'right' }}> {extraInfo.quotationHeader?.zipCode} {extraInfo.quotationHeader?.city} {extraInfo.quotationHeader.province ? <>({extraInfo.quotationHeader.province})</> : ""} {extraInfo.quotationHeader.phone ? <>TEL: {extraInfo.quotationHeader.phone}</> : <></>}</span>}


                    </td>
                </tr>
            </tbody>
        </table>
    );
}
