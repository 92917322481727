import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { replaceAllStyleElements } from '../../../js/PrintUtility';

export const ServiceNotePrint = ({ notes, noteTypeToShow, camera }) => {
    const { t } = useTranslation();


    function notesByType() {
        if (notes && notes.length > 0) {

            if (noteTypeToShow && noteTypeToShow.length > 0) {
                let notesToShow = notes.filter(x => noteTypeToShow.some(y => y === x.idTypeNote));
                return notesToShow;
            } else {
                return notes;
            }
        } else {
            return [];
        }
    }

    function renderTitleServiceNote(noteTypes) {
        if (camera) {
            return `${t('Product:Structure:RoomNotes')} ${camera}`
        }
        if (noteTypes && noteTypes.some(x => x === 12))
            return t('General:Description')+":"

        return "Note Servizio:"
    }
   
    return (
        <>
            {
                notesByType() && notesByType().length > 0 && <>
                    <div className="p-3 flexStart">
                        <p className="anegcytextsize">
                                {renderTitleServiceNote(noteTypeToShow)}
                            </p>
                        {
                            notesByType().map((item, key) => {
                                return <>
                                    <span className="mb-2 anegcytextsize"  key={key} dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(item.text) }}></span>
                                </>
                            })
                        }
                    </div>
                </>
            }

        </>
    );
}
