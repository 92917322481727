import React, { useState, useEffect } from 'react';
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { InputSearchDestinationOnlyRead } from '../../Common/Destination/InputSearchDestinationOnlyRead';
import { InputSearchDestinationContainer } from '../../Common/Destination/InputSearchDestinationContainer';
import { InputPaxesCompositionContainer } from '../../Common/Paxes/InputPaxesCompositionContainer';
import { InputCalendarContainer } from '../../Common/Calendar/InputCalendarContainer';
import { SelectCitizenship } from '../../Common/SelectCitizenship';
import { getActivityDetailLink, getActivityListLink, generateClientFlowId, callGetLastItemsSearch, callGetGetProductRules, getActivityCarpetId } from '../../../js/ProductService.js';

export const ActivitySearchEngine = ({ searchParameters, typeSearch, extraParams, modeView, resetValues, isTrippie, onSearchTrippie }) => {
    const { t } = useTranslation();

    const [lastSearchItems, setLastSearchItems] = useState([]);
    const [defaultCitizenshipId, setDefaultCitizenshipId] = useState(null);
    const [isStartSearch, setIsStartSearch] = useState(false);
    const [isValidComposition, setIsValidComposition] = useState(true);

    const [selectCitizenship, setSelectCitizenship] = useState(null);
    const [selectDestination, setSelectDestination] = useState(null);
    const [selectComposition, setSelectComposition] = useState(null);
    const [selectDates, setSelectDates] = useState(null);
    const [isValidForm, setIsValidForm] = useState({
        isValidDestination: false,
        isValidDates: false,
        isValidComposition: false,
        isAllFieldValid: false
    });

    const [textColorClass, setTextColorClass] = useState('text-white');

    // gestisco la visibilità
    const [showDestination, setShowDestination] = useState(false);
    const [showPaxes, setShowPaxes] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);

    useEffect(() => {

        const getLastSearchItems = async () => {
            const response = await callGetLastItemsSearch(11);
            setLastSearchItems(response);
        }
        getLastSearchItems();

        const getRules = async () => {
            const response = await callGetGetProductRules(11);

            if (response) {
                try {
                    setIsValidComposition(response['ADDTOCART'].allowed);
                } catch (ex) {
                    console.error(ex);
                }
            }
        }
        getRules();

        if (modeView === 'MODAL')
            setTextColorClass('text-dark');
        else
            setTextColorClass('text-white');

    }, []);

    useEffect(() => {
        if (resetValues) {
            setShowDestination(false);
            setShowPaxes(false);
            setShowCalendar(false);

            setValuesHowDefault();
        }

    }, [resetValues]);


    useEffect(() => {

        setValuesHowDefault();

    }, [searchParameters]);

    function setValuesHowDefault() {

        if (searchParameters) {
            let updateDestination = {
                Text: searchParameters.destinationDesc,
                Id: searchParameters.destinationWebId,
                Type: searchParameters.destinationType
            };
            setSelectDestination(updateDestination);

            let updateTravelers = [searchParameters.passengerComposition];
            setSelectComposition(updateTravelers);

            let updateCalendar = {
                dateFrom: searchParameters.dateFrom,
                dateTo: searchParameters.dateTo,
            };
            setSelectDates(updateCalendar);

            checkIsValidForm(updateDestination, updateTravelers, updateCalendar);

            setDefaultCitizenshipId(searchParameters.citizenship);

        } else {
            checkIsValidForm(null, null, null);
        }
    }

    async function onSearchButton() {
        setIsStartSearch(true);

        try {
            let newSP = {};
            let testRequest = false;
            let onRequest = false;
            if (extraParams && extraParams.testRequest)
                testRequest = true;

            if (extraParams && extraParams.onRequest)
                onRequest = true;

            if (searchParameters) {
                newSP = searchParameters;
                newSP.testRequest = testRequest;
            } else {
                newSP = {
                    activityId: null,
                    searchByDestinationOnly: false,
                    citizenship: 380, // Default italia se non dovrebbe arrivare
                    productSubType: 11,
                    showCancelPolicies: true,
                    templateId: null,
                    testRequest: testRequest,
                    destinationWebIds: null,
                    isTour: false,
                    agencyMarkup: null,
                    agencyFee: null
                };
            }

            if (typeSearch === 'SRC' || (typeSearch === 'AVL' && searchParameters)) {
                newSP.destinationDesc = selectDestination.Text;
                newSP.destinationWebId = selectDestination.Id;
                newSP.destinationType = selectDestination.Type;
            }

            newSP.activityDate = selectDates.dateFrom;
            newSP.dateFrom = selectDates.dateFrom;
            newSP.dateTo = selectDates.dateTo;

            newSP.passengerComposition = selectComposition[0];

            if (selectCitizenship)
                newSP.citizenship = selectCitizenship.isoId;

            if (isTrippie) {
                let clientFlowId = generateClientFlowId();
                let carpetId = await getActivityCarpetId(newSP, clientFlowId);

                onSearchTrippie(extraParams.stepIndex, clientFlowId, carpetId, newSP, configData.Settings.Products.Activity.IdTipoProdotto);
                setIsStartSearch(false);
                return;
            }

            if (typeSearch === 'SRC') {
                let clientFlowId = generateClientFlowId();
                let url = await getActivityListLink(newSP, clientFlowId);

                window.open(url, '_self');
            }

            if (typeSearch === 'AVL') {
                let extraParamUrl = {
                    testRequest: testRequest,
                    onRequest: onRequest
                };

                if (searchParameters) {
                    // ricerca sul dettaglio

                    let cId = null;
                    if (extraParams)
                        cId = extraParams.carpetId;

                    let cfId = '';
                    if (!extraParams || !extraParams.clientFlowId)
                        cfId = generateClientFlowId();
                    else
                        cfId = extraParams.clientFlowId;

                    let url = await getActivityDetailLink(newSP, extraParams.activityId, cId, cfId, true, extraParamUrl);
                    window.open(url, '_self');
                }
                else {
                    // ricerca sul dettaglio statico

                    newSP.destinationDesc = extraParams.city;
                    newSP.activityId = extraParams.activityId;

                    let clientFlowId = generateClientFlowId();
                    let url = await getActivityDetailLink(newSP, extraParams.activityId, null, clientFlowId, true, extraParamUrl);
                    window.open(url, '_self');
                }
            }
        } catch (ex) {
            console.error(ex);
        }

        setIsStartSearch(false);


    }

    function checkIsValidForm(destinationUpdate, travelersUpdate, calendarUpdate) {
        let isValidFormUpdate = {
            isValidDestination: typeSearch === 'AVL',
            isValidDates: false,
            isValidComposition: false,
            isAllFieldValid: false
        };

        if (typeSearch !== 'AVL')
            if (destinationUpdate) {
                if (destinationUpdate.Text && destinationUpdate.Id)
                    isValidFormUpdate.isValidDestination = true;
            } else {
                if (selectDestination && selectDestination.Text && selectDestination.Id)
                    isValidFormUpdate.isValidDestination = true;
            }

        if (travelersUpdate && travelersUpdate.length > 0)
            isValidFormUpdate.isValidComposition = true;
        else if (selectComposition && selectComposition.length > 0)
            isValidFormUpdate.isValidComposition = true;

        if (calendarUpdate && calendarUpdate.dateFrom && calendarUpdate.dateTo)
            isValidFormUpdate.isValidDates = true;
        else if (selectDates && selectDates.dateFrom && selectDates.dateTo)
            isValidFormUpdate.isValidDates = true;

        isValidFormUpdate.isAllFieldValid = isValidFormUpdate.isValidDates && isValidFormUpdate.isValidComposition && isValidFormUpdate.isValidDestination;

        setIsValidForm(isValidFormUpdate);
    }

    // funzioni destinazione
    function onSelectSearchDestination(selectDestination) {
        setSelectDestination(selectDestination);
        checkIsValidForm(selectDestination, null, null);
    }

    function onOpenPanelDestination() {
        setShowCalendar(false);
        setShowPaxes(false);
        setShowDestination(true);
    }

    // funzioni per la composizione
    function onClickSelectPaxes(selectComposition) {
        setSelectComposition(selectComposition);
        checkIsValidForm(null, selectComposition, null);
    }

    function onOpenPanelPaxes() {
        setShowDestination(false);
        setShowCalendar(false);
        setShowPaxes(true);
    }

    // funzioni per il calendario
    function onOpenPanelCalendar() {
        setShowCalendar(true);
        setShowDestination(false);
        setShowPaxes(false);
    }

    function onClickSelectDates(values) {
        setShowCalendar(false);

        setSelectDates({
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
        });

        checkIsValidForm(null, null, values);
    }

    function getDestinationLabel() {
        if (extraParams && extraParams.productName)
            return { Text: extraParams.productName };

        return selectDestination;
    }

    // funzioni cittadinanza
    function onSelectCitizenship(item) {
        setSelectCitizenship(item);
    }


    return (
        <>
            <div className="row mb-3">
                <div className="col-12 col-sm-9 my-2"></div>
                <div className="col-12 col-sm-3 my-2 d-inline text-right">
                    <SelectCitizenship
                        defaultCitizenshipId={defaultCitizenshipId}
                        onSelectCitizenship={onSelectCitizenship} 
                        modeView={modeView} />
                </div>
                {
                    <div className="col-12 col-sm-4 my-1">
                        {
                            typeSearch === 'AVL' && <InputSearchDestinationOnlyRead
                                defaultSelectDestination={getDestinationLabel()}
                                onlyView={true} />
                        }
                        {
                            typeSearch !== 'AVL' && <InputSearchDestinationContainer
                                defaultSelectDestination={selectDestination}
                                productType={configData.Settings.Products.Activity.IdTipoProdotto}
                                lastSearchItems={lastSearchItems}
                                onSelectSearchDestination={onSelectSearchDestination}
                                onOpenPanelDestination={onOpenPanelDestination}
                                closeExternal={showDestination}
                                modeView={modeView} />
                        }
                    </div>
                }
                <div className="col-12 col-sm-4 my-1">
                    <InputCalendarContainer
                        productType={configData.Settings.Products.Activity.IdTipoProdotto}
                        dateFrom={selectDates ? selectDates.dateFrom : null}
                        dateTo={selectDates ? selectDates.dateTo : null}
                        extraOptions={{ multiDate: true }}
                        onClickSelectDates={onClickSelectDates}
                        onOpenPanelCalendar={onOpenPanelCalendar}
                        closeExternal={showCalendar}
                        validDateRule={extraParams ? extraParams.validDateRule : null}
                        modeView={modeView}
                        type="xl" />
                </div>
                <div className="col-12 col-sm-4 my-1">
                    <InputPaxesCompositionContainer
                        searchParameters={searchParameters}
                        productType={configData.Settings.Products.Activity.IdTipoProdotto}
                        callOnSelectComposition={onClickSelectPaxes}
                        onOpenPanelPaxes={onOpenPanelPaxes}
                        closeExternal={showPaxes}
                        modeView={modeView}
                        type="xl" />
                </div>
                <div className="col-12 col-sm-12 my-1 p-3 text-center">
                    <button
                        style={{ position: "relative", zIndex: "100" }}
                        className="btn tp-btn-search py-2"
                        onClick={_ => onSearchButton()}
                        disabled={!isValidForm.isAllFieldValid && !isStartSearch}
                    >
                        {isStartSearch ? <><div className="spinner-border spinner-border-sm text-primary" role="status"><span className="sr-only"></span></div></> : <>{t('Button:Search')}</>}
                    </button>
                </div>

            

                {
                    !isValidComposition && <div className={"col-12 col-sm-12 my-1 " + textColorClass}>
                        {t('SearchEngine:WarningCartComposition')}
                    </div>
                }

            </div>
        </>
    );
}