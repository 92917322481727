import React from 'react';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import { IconChoosePrint } from './IconChoosePrint.js';
import { useTranslation } from 'react-i18next';
import { replaceAllStyleElements } from "../../../js/PrintUtility";
import { PrintStepHeader } from './PrintStepHeader.js';
import Cookies from 'universal-cookie';

export const PrintProductsNotes = ({ arrNotesProdcuts, onHandleIsPrint }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    function addBlackColorToBoldHTML(htmlString) {
        const fontWeightRegex = /<([^>]+)style="([^"]*(font-weight:\s*(700|bold))[^"]*)"([^>]*)>/gi;

        function replaceStyle(match, startTag, styleContent, fontWeight, endTag) {
            if (styleContent.includes('color:')) {
                return `<${startTag}style="${styleContent.replace(/color:\s*[^;]+/, 'color: black')}"${endTag}>`;
            } else {
                return `<${startTag}style="${styleContent}; color: black"${endTag}>`;
            }
        }

        return htmlString.replace(fontWeightRegex, replaceStyle);
    }
    return (
        <>
            {arrNotesProdcuts != null && arrNotesProdcuts.length > 0 &&
                <>
                <PrintStepHeader
                    title={arrNotesProdcuts[0][0].name}
                    PNR=''
                    totaleViaggio=''
                    iconName=''
                    cultureName={cultureName}
                    onHandleIsPrint={onHandleIsPrint }
                />
                {arrNotesProdcuts.map((product, index) =>
                    <>
                        <div className="border" style={{ display: 'grid' }}>
                            <table className="w-100">
                                <tbody>
                                    <tr className="customBackground">
                                        <td className="pl-2">
                                            <span className="h6 float-end" style={{ color: "#780704" }}>{t(`Template:InformationDetailProduct`)}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {product[0].notes.map((note) =>
                                <div style={{ padding: '5px', textAlign: "justify", color: '#555555'}}>
                                    <span style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: addBlackColorToBoldHTML(replaceAllStyleElements(note)) }} />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </>
            }
        </>
    );
}