import configData from "../../../appsettings.json";

export function getPrimaryColor() {
    switch (configData.Settings.CommonApi_BaseUrl +"") {
        case "5":
        case "4":
            return "#a0020a";

        case "3":
            return "#4B61D1";

        default:
            return "#866e51";
    }
}

export const printCssStyle = {
    html: {
        width: '100%',
    },

    ['.fontBold']: {
        fontFamily: 'Helvetica-Bold',
    },

    page: {
        paddingBottom: '8%'
    },

    bodyPdf: {
        paddingLeft: '15px',
        paddingRight: '15px'
    },

    footer: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        textAlign: 'center',
    },

    pageNumber: {
        textAlign: 'right',
        fontSize: '10px',
        width: '99%',
        marginBottom: '5px'
    },

    div: {
        margin: 0,
        textAlign: 'center',
    },

    label: {
        fontSize: '7px',
        whiteSpace: 'normal',
    },

    span: {
        fontSize: '10px',
    },

    p: {
        margin: '1px',
        padding: '1px',
        fontSize: '12px',
    },

    h2: {
        fontWeight: 'bold',
        fontSize: '20px',
    },

    h3: {
        fontWeight: 'bold',
        fontSize: '17px',
    },

    h4: {
        fontWeight: 'bold',
        fontSize: '15px',
        margin: '0px',
    },

    h5: {
        fontWeight: 'bold',
        fontSize: '12px',
        margin: '0px',
        marginBottom: "4px"
    },

    h6: {
        fontWeight: 'bold',
        margin: '0px',
        padding: '0px',
        fontSize: '9px',
    },

    body: {
        fontFamily: 'Helvetica',
    },

    td: {
        padding: '4px',
        fontSize: "10px",
        textAlign: "left",
    },

    img: { width: 100, height: 50 },

    li: {
        width: "90%"
    },

    ['.h2']: {
        fontSize: '20px',
        whiteSpace: 'normal',
    },

    ['.h3']: {
        fontSize: '17px',
        whiteSpace: 'normal',
    },

    ['.h4']: {
        fontSize: '14px',
        whiteSpace: 'normal',
    },

    ['.h5']: {
        fontSize: '12px',
        whiteSpace: 'normal',
    },

    ['.h6']: {
        fontSize: '9px',
        whiteSpace: 'normal',
    },

    ['.border']: {
        border: '1px solid #e7e7e7',
    },

    ['.w-imagePdf']: {
        width: "15px",
        maxWidth: "15px"
    },

    ['.col-segment-print']: {
        width: '80%',
        maxWidth: '80%',
    },

    ['.w-10']: {
        width: '10%',
        maxWidth: '10%',
    },

    ['.w-15']: {
        width: '15%',
        maxWidth: '15%',
    },

    ['.w-20']: {
        width: '20%',
        maxWidth: '20%',
    },

    ['.w-30']: {
        width: '30%',
        maxWidth: '30%',
    },

    ['.w-40']: {
        width: '40%',
        maxWidth: '40%',
    },
    ['.w-50']: {
        width: '50%',
        maxWidth: '50%',
    },
    
    ['.col-lg-6']: {
        width: '50%',
        maxWidth: '50%',
    },

    ['.col-lg-7']: {
        width: '70%',
        maxWidth: '70%',
    },

    ['.col-lg-8']: {
        width: '90%',
        maxWidth: '90%',
    },

    ['.w-100']: {
        width: '100%',
        maxWidth: '100%',
    },

    ['.mybook-order-main']: {
        borderRadius: '5px'
    },

    /* color */
    ['.text-danger']: {
        color: '#DC3545',
    },

    ['.customBackground']: {
        backgroundColor: '#EBDAC4',
    },

    ['.text-success, .text-green-900']: {
        color: '#198754',
    },

    ['.badge-gray-200']: {
        backgroundColor: '#fafbfe',
    },

    ['.badge-white']: {
        backgroundColor: '#FFFFFF',
    },

    ['.badge-customer']: {
        backgroundColor: '#08123B',
    },

    ['.badge-light-customer']: {
        backgroundColor: '#E8EAF1',
    },

    ['.bg-gradient-gray-400, .badge-light']: {
        backgroundColor: '#edf0f8',
        borderRadius: '5px'
    },

    ['.text-darkgrey']: {
        color: '#383838',
    },

    ['.text-white']: {
        color: '#FFFFFF',
    },

    ['.stepTag']: {
        borderRadius: '5px',
        padding: '2px',
        backgroundColor: '#FFFFFF'
    },

    ['.penalty']: {
        color: 'red',
    },

    ['.free']: {
        color: 'green',
    },

    ['.badge-customer-yellow']: {
        backgroundColor: '#DAB451',
    },

    ['.borderBottomColorCustom']: {
        borderBottom: "1px solid #780704"
    },

    ['.pagebreak']: {
        clear: 'both',
        pageBreakBefore: 'always',
    },

    ['.header']: {
        height: '500px',
    },


    ['.logo-header']: {
        height: '50px',
        width: '155px',
        zIndex: "2000"
    },

    ['.logo-header-agency']: {
        width: '140px',
    },

    ['.logo-print']: {
        height: '20px',
        width: '65px'
    },

    ['.ico-image']: {
        height: '11px',
        width: '11px'
    },

    ['.ico-image-small']: {
        height: '7px',
        width: '7px'
    },

    ['.ico-image-large']: {
        height: '13px',
        width: '13px'
    },

    ['.ico-image-extra-large']: {
        top: '6px',
        height: '20px',
        width: '20px'
    },

    ['.image-puntini-verticali']: {
        width: '7px'
    },

    ['.image-puntini-orizzontali']: {
        height: '6px',
        width: '60px'
    },

    ['.imgFlight']: {
        height: '20px',
        width: '20px'
    },

    ['.card-img']: {
        height: '45px',
        width: '65px',
    },

    ['.img-note']: {
        height: '130px',
        width: '550px',
    },
    ['.personpdficon']: {
        height: '18px',
        width: "18px",
    },

    ['.card']: {
        borderRadius: '5px',
    },

    ['.borderThead']: {
        borderLeft: '1px',
    },

    ['.borderTBody']: {
        borderLeft: '1px dotted grey',
    },

    /* voucher */
    ['.badge-voucher']: {
        backgroundColor: '#b7c3d2',
        padding: '8px',
    },

    /* copertina */
    ['.copertina-img']: {
        width: '100%',
        height: '100%',
    },


    ['.bg-gray-100']: {
        backgroundColor: "#f5f5f5",
    },

    ['.bg-gray-200']: {
        backgroundColor: "#eeeeee",
    },

    ['.bg-gray-300']: {
        backgroundColor: "#e0e0e0",
    },

    ['.stepHeader']: {
        backgroundColor: getPrimaryColor(),
    },

    ['.float-start']: {
        textAlign: "left"
    },
    ['.float-end']: {
        textAlign: "right"
    },

    ['.text-left']: {
        textAlign: "left"
    },

    ['.text-right']: {
        textAlign: "right"
    },

    ['.text-right']: {
        textAlign: "right"
    },

    ['.badge-pnr h6']: {
        color: "black"
    },

    ['.border-top']: {
        borderTop: "1px solid #dee2e6",
    },

    ['.border-bottom']: {
        borderBottom: "1px solid #dee2e6",
    },

    ['.rounded-top']: {
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    ['.rounded-bottom']: {
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
    },

    ['.marginTopPdf']:{
        marginTop: "-16px"
    },

    ['.marginBottomPdf']: {
        marginBottom: "-16px"
    },
    [".mb-print-step"]: {
        marginBottom: "-12px"
    },
    ['.marginTopTourPdf']: {
        marginTop: "-32px"
    },
    ['.carDetailIconCustomWidth']: {
        width: "70px !important"
    },

    ['.marginTopPdfAlternatives']: {
        marginTop: "5px"
    },

    ['.mr-1']:{
        marginRight: '5px',
    },

    ['.status.confirm']: {
        color: "#208163",
    },
    ['.status.onrequest']: {
        color: "#6ea510",
    },
    ['.status.deleted']: {
        color: "#penalty",
    },
    ['.imgProductFlight']: {
        width: "130px",
        height: "140px",
        objectFit: "cover",
        objectPosition: "center",
    },
    ['.firstpageheader']: {
        width: "214px",
        height: "53px",
        paddingBottom: "8px"
    },
    [".firstfootertitle"]: {
        fontSize: "26px",
    },
    [".anegcytextsize"]: {
        fontSize: "10px",
    },
    [".footerdatasize"]: {
        fontSize: "16px",
    },
    [".pb-4"]: {
        transform: "translate(0px, -16px);",
    },
    ['.titlefirstpagecolor']: {
        color: 'red !important',
    },

    ['.imgProductStructure']: {
        width: "130px",
        height: "130px",
        objectFit: "cover",
        objectPosition: "center",
    },

    ['.imgProductTransfer']: {
        width: "130px",
        height: "110px",
        objectFit: "cover",
        objectPosition: "center",
    },
    
    ['.imgProducts']: {
        width: "100%",
        height: "100px",
        objectFit: "cover",
        objectPosition: "center",
    },

    ['.widthTdImage']: {
        width: "25%",
        maxWidth: "25%"
    },
    [".flexStart"]: {
        display: "flex",
        justifyContent: "flex-start",
        textAlign: "left",
        fontSize: "16px"
    },

    ['.h075']: {
        padding: "5px"
    },

    ['.borderColor']: {
        border: "1px solid grey",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
    },

    ['.widthFixed']: {
        width: "1%",
        maxWidth: "1%"
    },

    ['.color-text-voucher']: {
        color: "white"
    }
};
