import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import { imageOnError, imageOnLoadFlight } from '../../../js/CDNUtility';
import { getIconUrlToPrint, getDifferenceDayFlight } from '../../../js/PrintUtility';
import { ServiceNotePrint } from '../service/ServiceNotePrint.js';


export const FlightDetailPrint = ({ id, type, isNotStep, flightDetail, isPrintCliente, isConfirmPrint, isDocViaggio }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const renderPassenger = () => {
        return (
            <>
                {flightDetail.passengers && flightDetail.passengers.length > 0 && flightDetail.passengers[0].fullName === null ?
                    <label>{flightDetail.passengers.length} &nbsp;{t("Print:Passengers")}</label>
                    :
                    flightDetail.passengers && flightDetail.passengers.length > 0 && flightDetail.passengers.map((pax, index) =>
                        <>
                            <label>{pax.fullName}</label>
                            {index < flightDetail.passengers.length - 1 ? ", " : ""}
                        </>
                    )
                }
            </>
        );
    }


    const renderPassengerCosts = (passengerFareComponents) => {
        return (
            <>
                {passengerFareComponents.map((paxFareCompo, index) =>
                    <tr key={index}>
                        <td colSpan="4">
                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td className="w-40">
                                            <label>{paxFareCompo.passenger.fullName ? paxFareCompo.passenger.fullName : ("Pax " + (paxFareCompo.passenger.index))}</label>
                                        </td>
                                        <td className="w-30">
                                            <label>{paxFareCompo.fareDetails[0].depAirport !== null ? paxFareCompo.fareDetails[0].depAirport : "--"}</label>
                                            <label> &nbsp;{paxFareCompo.fareDetails[0].arrAirport !== null ? " > " + paxFareCompo.fareDetails[0].arrAirport : ""}</label>
                                        </td>
                                        <td>
                                            <label>{paxFareCompo.fareDetails[0].baggages !== null ? paxFareCompo.fareDetails[0].baggages : "--"}</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                )}
            </>
        );
    }

    function checkForIdPratica(leg, segment, passengerFareComponent) {
        try {
            for (let i = 0; i < passengerFareComponent.length; i++) {
                let item = passengerFareComponent[i];

                let isSamePratica = item.idPratica && leg.idPratica == item.idPratica;
                let isSameQuotation = item.quotationItemId && leg.quotationItemId == item.quotationItemId;

                if (isSameQuotation || isSamePratica) {

                    for (let j = 0; j < item.fareDetails.length; j++) {
                        let fairdetails = item.fareDetails[j]
                        if (fairdetails.legIndex == leg.legIndex && fairdetails.segmentIndex == segment.segmentIndex) {
                            return <span className="" style={{ margin: "0px" }}>  {t('Product:Flight:TotBagages')}: {item.fareDetails[j]?.baggages}</span>
                        }
                    }
                }
            }

            

        } catch(e) {
            console.log(e)
        }
        return <span className="" style={{ margin: "0px" }}>  {t('Product:Flight:TotBagages')}: 0 Pcs</span>

    }

    return (
        <>

            <div className="shadow border w-100">

                {flightDetail && flightDetail.legs && flightDetail.legs.length > 0 && flightDetail.legs.map((leg, index) => {

                    return <div style={{ display: "flex", flexDirection: "column", borderBottom: index === flightDetail.legs.length - 1 ? "" : "1px solid gray" }} >
                        {
                            leg.segments && leg.segments.length > 0 && leg.segments.map((segment, keySegments) => {
                                //if (!((leg.stops && leg.stops.stopsDurations && !leg.stops.stopsDurations[keySegments - 1]) || leg.stops === null)) {
                                //    return <></>
                                //}
                                return <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "16px 0px 16px 16px" }}>
                                        <div>
                                            <img src={`data:image;base64, ` + segment.thumbUrlB} alt={segment.airlineName} className='w-100 p-2 imgFlight' onError={imageOnError} />
                                        </div>
                                        <label className="anegcytextsize">{segment.flightNumber}</label>
                                        <div style={{ width: "40px" }}></div>
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "row", padding: "16px 24px", width: "100%" }}>
                                        <div style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                                            <div style={{ paddingBottom: "12px" }}>
                                                <span className="fontBold" style={{ margin: "0px" }}>  {formatDateTime(segment.departureDate, cultureName, { noYear: true })}</span>
                                            </div>
                                            <div>
                                                <span style={{ margin: "0px" }}> {formatDateTime(segment.arrivalDate, cultureName, { noYear: true })} </span>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", textAlign: "right", justifyContent: 'space-between', alignItems: "center" }}>
                                            <div style={{ backgroundColor: "black", width: "6px", height: "6px", borderRadius: "100%" }}></div>
                                            <div style={{ width: "1px", height: "15px", border: "1px dashed gray" }}>
                                            </div>
                                            <div style={{ backgroundColor: "#CF545E", width: "6px", height: "6px", borderRadius: "100%" }}></div>
                                            <div style={{ width: "32px" }}></div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", textAlign: "center", width: "50px" }}>
                                            <div style={{ paddingBottom: "12px" }}>
                                                <span className="" style={{ margin: "0px" }}>  {formatDateTime(segment.departureDate, cultureName, { onlyTime: true })}</span>
                                            </div>
                                            <div>
                                                <span style={{ margin: "0px" }}> {formatDateTime(segment.arrivalDate, cultureName, { onlyTime: true })} </span>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "420px" }}>
                                            <div style={{ paddingBottom: "12px" }}>
                                                <span className="" style={{ margin: "0px", textAlign: "left" }}>  {segment.departureAirportName}</span>
                                            </div>
                                            <div>
                                                <span style={{ margin: "0px", textAlign: "left" }}> {segment.arrivalAirportName} </span>
                                            </div>
                                        </div>
                                        <div style={{ width: '50px' }}></div>
                                        <div className="flex-1" style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                                            <div>
                                                {flightDetail.passengerFareComponents[0]?.fareDetails[index]?.bookingClass && <span className="" style={{ margin: "0px" }}> { t('Product:Flight:Class')}: {flightDetail.passengerFareComponents[0]?.fareDetails[index].bookingClass}</span>}
                                            </div>
                                            <div>
                                                {
                                                    checkForIdPratica(leg, segment,flightDetail.passengerFareComponents)
                                                }
                                            </div>
                                            <div>
                                                <span style={{ margin: "0px" }}> {t("Print:Passengers")}: {flightDetail.passengers.length} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: '28px' }}></div>

                                </div>
                            })
                        }
                    </div>
                }
                )}

                <ServiceNotePrint notes={flightDetail.notesService} noteTypeToShow={[isDocViaggio ? 11 : isConfirmPrint ? 10 : 13]} />
                

            </div>
            {/*<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", padding: "16px 24px", paddingLeft: "0px" }}>
                    {flightDetail.passengerFareComponents[0]?.fareDetails.map(item => {
                        return <div style={{ paddingBottom: "44px" }}>
                            
                            <div>
                                {item.bookingClass && <span className="" style={{ margin: "0px" }}> Classe: {item.bookingClass}</span>}
                            </div>
                            <div>
                                {item.baggages && <span className="" style={{ margin: "0px" }}> {item.baggages}</span>}
                            </div>
                               <div>
                                   <span style={{ margin: "0px" }}> {t("Print:Passengers")}: {flightDetail.passengers.length} </span>
                               </div>
                        </div>
                    })}
                </div>*/}
        </>

    );
}