import React from 'react';
import { getIconUrlToPrintCustom } from "../../../../js/PrintUtility";
import { getCDNLogoDocument } from "../../../../js/CDNUtility";

export const BodyFirstPagePDFPrint = ({ extraInfo }) => {
    return (<>
        <div style={{ display: "flex", flexDirection: "row", "justifyContent": "center", backgroundColor: "#EBDAC4" }}>

        <div style={{ display: "flex", flexDirection: "row", "justifyContent": "center", backgroundColor: "#EBDAC4", margin: "5px", width: "500px" }}>
            <h1 className="fontBold" style={{ color: "#303030", width: "100%"} }>
                        {extraInfo.name}
                    </h1>
            </div>
        </div>

              
        <img src={getIconUrlToPrintCustom('firstTravelCopertina')} className="copertina-img"  />
    </>
    )
}
