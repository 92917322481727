import React from 'react';
import { Document, Page, View, Text, Font } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import ReactDOMServer from "react-dom/server";
import { HeaderPDFPrint } from '../Custom/Print/HeaderPDFPrint';
import { FooterPDFPrint } from '../Custom/Print/FooterPDFPrint';
import { HeaderFirstPagePDFPrint } from '../Custom/Print/Documents/HeaderFirstPagePDFPrint';
import { BodyFirstPagePDFPrint } from '../Custom/Print/Documents/BodyFirstPagePDFPrint';
import { FooterFirstPagePDFPrint } from '../Custom/Print/Documents/FooterFirstPagePDFPrint';
import Cookies from 'universal-cookie';
import { formatDateTime } from '../../js/Utils';
import { useTranslation } from 'react-i18next';

export const PrintPdfDocuments = ({ html, agency, stylesheet, extraInfo }) => {

    const { t } = useTranslation();

    Font.register({ family: 'Montserrat', src: "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" });
    //const albertSrc = 'https://fonts.gstatic.com/s/albertsans/v1';
    //const jetbrainsSrc = 'https://fonts.gstatic.com/s/jetbrainsmono/v18';

    //Font.register({
    //    family: 'Albert Sans',
    //    fonts: [
    //        {
    //            fontStyle: 'normal',
    //            fontWeight: 400,
    //            src: `${albertSrc}/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHq5P_rI32TxAj1g.ttf`,
    //        },
    //        {
    //            fontStyle: 'italic',
    //            fontWeight: 400,
    //            src: `${albertSrc}/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9AX74fybRUz1r5t.ttf`,
    //        },
    //        {
    //            fontStyle: 'normal',
    //            fontWeight: 700,
    //            src: `${albertSrc}/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHTJT_rI32TxAj1g.ttf`,
    //        },
    //        {
    //            fontStyle: 'italic',
    //            fontWeight: 700,
    //            src: `${albertSrc}/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9Dw6IfybRUz1r5t.ttf`,
    //        },
    //    ],
    //});

    //Font.register({
    //    family: 'JetBrains Mono',
    //    fonts: [
    //        {
    //            fontStyle: 'normal',
    //            fontWeight: 500,
    //            src: `${jetbrainsSrc}/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yKxjPVmUsaaDhw.ttf`,
    //        },
    //    ],
    //});

    const headerFirstPageHtml = ReactDOMServer.renderToString(
        <HeaderFirstPagePDFPrint extraInfo={extraInfo} />
    );
    const bodyFirstPageHtml = ReactDOMServer.renderToString(
        <BodyFirstPagePDFPrint extraInfo={extraInfo} />
    );
    const footerFirstPageHtml = ReactDOMServer.renderToString(
        <FooterFirstPagePDFPrint extraInfo={extraInfo} />
    );
    const headerHtml = ReactDOMServer.renderToString(
        <HeaderPDFPrint agency={agency} extraInfo={extraInfo} />
    );
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const footerDate = formatDateTime(extraInfo.date, cultureName)
    const footerHtml = ReactDOMServer.renderToString(
        <FooterPDFPrint />
    );

    /* wrap = true  permette di accordare nella stessa pagina, wrap = false no,   break = true  obbliga l'elemento a saltare nella pagina successiva'  */
    return (
        <Document>
            <Page size="A4" wrap>
                <View fixed>
                    <Html stylesheet={stylesheet}>
                        {headerFirstPageHtml}
                    </Html>
                </View>

                <View>
                    <Html stylesheet={stylesheet}>
                        {bodyFirstPageHtml}
                    </Html>
                </View>

                <View fixed style={stylesheet.footer}>
                    <Html stylesheet={stylesheet}>
                        {footerFirstPageHtml}
                    </Html>
                </View>
            </Page>

            <Page size="A4" wrap style={stylesheet.page}>
                <View fixed>
                    <Html stylesheet={stylesheet}>
                        {headerHtml}
                    </Html>
                </View>

                {
                    html.map((item, key) => {
                        return <View key={key} wrap={item.wrap} break={item.break} style={stylesheet.bodyPdf}>
                            <Html stylesheet={stylesheet}>
                                {item.html}
                            </Html>
                        </View>
                    })
                }

                <View fixed style={stylesheet.footer}>

                    <View style={{ display: "flex", justifyContent: "space-between", flexDirection: 'row', fontSize: '12px', padding: '8px 24px', borderTop: "1px solid #dee2e6" }}>
                        <Text render={() => (
                            `${t('General:Date')}: ${footerDate}`
                        )} fixed />
                        <Text render={() => (
                            'First Travel Swiss Sagl'
                        )} />
                        <View style={{ width: '100px', textAlign: "right" }}>

                            <Text render={({ pageNumber, totalPages }) => (
                                `${pageNumber} / ${totalPages}`
                            )} fixed />
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}