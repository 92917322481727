import React from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from '../../../Common/M3Icon'
import { Login } from "../../../Auth/Login"
import configData from "../../../../appsettings.json";

export const HeaderLogin = ({ item, modal }) => {
    const { t } = useTranslation();

    function getClassName(className) {
        if (!className)
            return '';

        try {
            return className.split("#")[1];
        } catch (ex) { }

        return '';
    }
    function goTo(link) {
        window.location.href = configData.Settings.Base_Url + link;
    }

    return (
        <>
            <a class="nav-link text-black" href="#" data-bs-toggle="modal" data-bs-target="#loginModal" ><span class="material-icons">account_circle</span></a>
        </>
    );
}
