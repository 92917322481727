import React, { useEffect } from 'react';
import { getIconUrlToPrint, getIconUrlToPrintCustom } from '../../../js/PrintUtility.js';
import { IconChoosePrint } from './IconChoosePrint.js';


export const PrintStepHeader = ({ specefiche, iconName, title, PNR, onHandleIsPrint, isShowPrintButton = false, paxes, productSubType, productIcon }) => {
    function filterByAge(entries, type) {
        return entries.filter(entry => {
            if (entry.bookParams) {
                const isChild = entry.bookParams.some(param => param.bpCode === "PAX_TYPE_QUOT" && param.bpValue === "CHD");
                const isAdult = entry.bookParams.some(param => param.bpCode === "PAX_TYPE_QUOT" && param.bpValue === "ADT");

                if (type === "CHD") {
                    return isChild;
                } else if (type === "ADT") {
                    return isAdult || !isChild; // Default to adult if not a child
                }
            }
            return type === "ADT"; // Default to adult if bookParams is missing
        });
    }

    useEffect(() => { console.log("paxes", paxes) }, []);

    return (
        <>

            <div className="rounded-top text-white stepHeader">
                <table className="w-100">
                    <tbody>
                        <tr style={{ textAlign: 'left', }}>
                            {isShowPrintButton &&
                                <td style={{ width: '2%', maxWidth: '2%' }}>
                                    <IconChoosePrint handleIsPrint={onHandleIsPrint} />
                                </td>
                            }
                            {iconName &&
                                <td style={{ width: '8%', maxWidth: '8%', marginTop: '-10px', marginBottom: '-15px' }}>
                                    <img src={!productIcon ? getIconUrlToPrintCustom(iconName.toLowerCase()) : getIconUrlToPrint(iconName.toLowerCase())} className="ico-image-extra-large" />
                                </td>
                            }
                            <td className="pl-2">
                                <span className="h5 text-white float-start">
                                    {title}
                                </span>
                            </td>
                            {PNR &&
                                <td className="col-lg-3 text-white" style={{ textAlign: 'left', borderLeft: '1px solid #FFFFFF', }}>
                                    <tr>
                                        <div className="badge-white badge-pnr" style={{ padding: '2px', left: '23px', top: '7px' }}>
                                            <h6>PNR: {PNR}</h6>
                                        </div>
                                    </tr>
                                </td>
                            }
                            {paxes && <td className="col-lg-3 text-black">
                                <div className="" style={{ padding: '2px', left: '23px', top: '7px' }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ width: "160px" }}></div>

                                        <img src={getIconUrlToPrint('person')} className="mr-1 personpdficon" style={{ height: "24px" }} />
                                        <span>
                                            {filterByAge(paxes, "ADT").length}
                                            &nbsp;
                                        </span>
                                        <div style={{ width: '6px' }}></div>
                                        <img src={getIconUrlToPrint('personWhite')} className="mr-1 personpdficon" style={{ height: "24px" }} />
                                        <span>
                                            {filterByAge(paxes, "CHD").length}
                                        </span>
                                    </div>
                                </div>
                            </td>}
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};
