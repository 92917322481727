import React from 'react';
import { replaceAllStyleElements } from '../../../js/PrintUtility';
import { PrintStepHeader } from './PrintStepHeader.js';

export const TourStaticPrint = ({ title, descriptions, cultureName }) => {
    function addBlackColorToBoldHTML(htmlString) {
        const fontWeightRegex = /<([^>]+)style="([^"]*(font-weight:\s*(700|bold))[^"]*)"([^>]*)>/gi;

        function replaceStyle(match, startTag, styleContent, fontWeight, endTag) {
            if (styleContent.includes('color:')) {
                return `<${startTag}style="${styleContent.replace(/color:\s*[^;]+/, 'color: black')}"${endTag}>`;
            } else {
                return `<${startTag}style="${styleContent}; color: black"${endTag}>`;
            }
        }

        return htmlString.replace(fontWeightRegex, replaceStyle);
    }
    return (
        <>
            <div className="border marginTopPdf" style={{ display: 'grid' }}>
                <table className="w-100">
                    <tbody>
                        <tr className="customBackground">
                            <td className="pl-2">
                                <span className="h6 float-end" style={{ color: "#780704" }}>{title }</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ padding: '5px', textAlign: "justify", color: '#555555' }}>
                    <span style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: addBlackColorToBoldHTML(replaceAllStyleElements(descriptions))     }} />
                </div>
            </div>
        </>
    )
}