import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import configData from "../../appsettings.json";
import { Loading } from '../Common/Loading';
import { VoucherStructureDetail } from './VoucherProducts/VoucherStructureDetail';
import { VoucherCarRentalDetail } from './VoucherProducts/VoucherCarRentalDetail';
import { VoucherActivityDetail } from './VoucherProducts/VoucherActivityDetail';
import { VoucherTransferDetail } from './VoucherProducts/VoucherTransferDetail';
import { VoucherTourDetail } from './VoucherProducts/VoucherTourDetail';
import { getCDNLogoDocument } from "../../js/CDNUtility";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { generateVoucherHTML } from "./print/Voucher/VoucherService";
import { downloadDocumentPDF } from "../../js/PrintUtility";
import { printCssStyle } from '../Custom/Print/PrintCss';
import { PrintPdfVoucher } from './print/Voucher/components/PrintPdfVoucher';
import { useTranslation } from 'react-i18next';
import '../Custom/Print/PrintCssForHtml.css';
import { PrintVoucherFooter } from './print/Voucher/components/PrintVoucherFooter';


export const Voucher = () => {
    let { orderNumber } = useParams();
    let { wsBookingId } = useParams();
    let { visualizationType } = useParams();

    const { t } = useTranslation();

    let { cultureName } = useParams()
    let { authcode } = useParams()
    const [HeaderData, setHeaderData] = useState([]);
    const [DocOrderDetailData, setDocOrderDetailData] = useState([]);
    const [TitleData, setTitleData] = useState([]);
    const [order, setOrder] = useState({ pratiche: [] });
    const [beginData, setBeginData] = useState([]);
    const [endData, setEndData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [customProductDetailArr, setCustomProductDetailArr] = useState([]);
    const [pdfFileName, setPdfFileName] = useState('');
    const [myHtml, setMyHtml] = useState([]);
    const [isDownloadPDF, setIsDownloadPDF] = useState(false);
    const [customDynamicNoteArr, setCustomDynamicNoteArr] = useState([]);
    const [customBookingArr, setCustomBookingArr] = useState([]);
    const [nameVoucher, setNameVoucher] = useState("");


    useEffect(() => {
        const loadData = async (data) => {
            const response = await fetch(configData.Settings.CommonApi_BaseUrl + 'Document/GetOrder/' + data.OrderNumber + '/' + data.AuthCode, { credentials: 'include' });
            if (!response.ok) return;
            
            const jresponse = await response.json();
            setOrder(jresponse);
            var hd = jresponse.header;
            setHeaderData(hd);
            setTitleData(jresponse.title);
            setBeginData({ section: "BEGIN" });
            setEndData({ section: "END" });
            setDocOrderDetailData(jresponse);
            setIsLoading(false);
        }
        loadData({ OrderNumber: orderNumber, VisualizationType: visualizationType, CultureName: cultureName, AuthCode: authcode }).catch(console.error);
        
    }, []);

    function onCustomProductDetail(productDeatil) {
        setCustomProductDetailArr(arr => [...arr, productDeatil]);
    }

    function onCustomDynamicNote(dynamicNote) {
        setCustomDynamicNoteArr(dynamicNote);
    }

    function onCustomBookingNote(bookingNote) {
        setCustomBookingArr(bookingNote);
    }

    function dowloadPDFAfterRender(filename, url) {
        downloadDocumentPDF(filename, url);
        setIsDownloadPDF(false);
    }
    const renderProducts = (pratiche) => {
        return (
            <div className="col-12 col-lg-12">
                {pratiche.filter(x => x.wsBookingId === wsBookingId).map(pratica =>
                    <div key={pratica.idPratica} data-numpratica={pratica.idPratica} className="row">
                        {pratica.tipoPratica === configData.Settings.Products.Structure.Code && <VoucherStructureDetail id={pratica.wsBookingId} customProductDetail={onCustomProductDetail} customDynamicNote={onCustomDynamicNote} customBookingNote={onCustomBookingNote} />}
                        {pratica.tipoPratica === configData.Settings.Products.CarRental.Code && <VoucherCarRentalDetail id={pratica.wsBookingId} customProductDetail={onCustomProductDetail} customDynamicNote={onCustomDynamicNote} customBookingNote={onCustomBookingNote} />}
                        {pratica.tipoPratica === configData.Settings.Products.Activity.Code && <VoucherActivityDetail id={pratica.wsBookingId} customProductDetail={onCustomProductDetail} customDynamicNote={onCustomDynamicNote} customBookingNote={onCustomBookingNote} />}
                        {pratica.tipoPratica === configData.Settings.Products.Transfer.Code && <VoucherTransferDetail id={pratica.wsBookingId} customProductDetail={onCustomProductDetail} customDynamicNote={onCustomDynamicNote} customBookingNote={onCustomBookingNote} />}
                        {pratica.tipoPratica === configData.Settings.Products.Tour.Code && <VoucherTourDetail id={pratica.wsBookingId} customProductDetail={onCustomProductDetail} customDynamicNote={onCustomDynamicNote} customBookingNote={onCustomBookingNote} />}
                    </div>
                )}
            </div>
        )
    }

    function callPrintPDFFile() {
        const today = new Date().toISOString().split('T')[0];
        setPdfFileName('Voucher_' + today);

        let myArr = generateVoucherHTML(
            t,
            customProductDetailArr,
            visualizationType,
            cultureName,
            customBookingArr,
            customDynamicNoteArr
        );

        setMyHtml(myArr);
        setIsDownloadPDF(true);
    }

    const renderView = (order) => {
        return <main>
            <section className="section bg-soft section-lg pt-5">
                <div className="container">
                    <div className="row justify-content-center document">
                        <div className="col-12 col-lg-10">
                            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between mb-4">
                                <div className=" d-print-none">
                                    <a href={`mybook/orders/detail/${orderNumber}`} className="mb-4 mb-lg-0">
                                        <button className="btn btn-print mb-4 mr-2" >
                                            <span className="material-icons-outlined position-relative" style={{ top: '6px' }}>arrow_back</span> {t('MyBook:ComeToOrder')}
                                        </button>
                                    </a>
                                    <button className="btn btn-primary mb-4 mr-2 stepHeader color-text-voucher" onClick={e => callPrintPDFFile()}>
                                        <span className="material-icons-outlined">file_download</span> {t('Button:Print')} PDF
                                    </button>
                                    {/*<button className="btn btn-outline-gray" onClick={() => window.print()}><span className="fas fa-print mr-2"></span> Print Voucher</button>*/}
                                </div>
                            </div>
                            <div className="card border-light p-2 p-md-5">
                                <div className="d-flex justify-content-between pb-1 border-bottom border-light d-print-block">
                                    <img src={getCDNLogoDocument()} className=" d-lg-block" height="40" alt="Spaces Logo" />
                                        <div>
                                        <i className="ph-phone-bold mr-2"></i> 
                                        </div>
                                        <div>
                                        <i className="ph-envelope-simple-bold"></i> 
                                        </div>
                                </div>
                            </div>
                            <div>
                                {/*<!--Testata Servizio-->*/}
                                {
                                    order.pratiche.length > 0 ? (
                                            renderProducts(order.pratiche)
                                    ): (
                                        isLoading
                                            ? <Loading textMsg={t('MyBook:Loading')} />
                                            : <></>
                                    )
                                }
                                {/*<!--FOOTER-->*/}
                                <div className="row">
                                    <PrintVoucherFooter />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    }

    return (
        <>
            {renderView(order)}
            {myHtml.length > 0 && isDownloadPDF &&
                <PDFDownloadLink className="lnkPDFDownload" document={<PrintPdfVoucher html={myHtml} stylesheet={printCssStyle} extraInfo={null} />} fileName={pdfFileName} >
                    {({ blob, url, loading, error }) => {
                        if (!loading && blob) {
                            dowloadPDFAfterRender(pdfFileName, URL.createObjectURL(blob));
                        }
                    }}
                </PDFDownloadLink>
            }
        </> 
    );
}
