import React, { useState, useEffect } from 'react';
import configData from "../../../../appsettings.json";
import { M3Icon } from '../../../Common/M3Icon';
import { useTranslation } from 'react-i18next'
import { WebVerticalMenu } from '../../../PageComponent/components/WebVerticalMenu'

export const HeaderMenu = ({ item }) => {
    const { t } = useTranslation();
    const [isActive, setIsActive] = useState("");
    function hideMenu() {
        if (isActive === "") {
            setIsActive("is-active");
        }
        else {
            setIsActive("");
        }
    }

    function getClassName(className) {
        if (!className)
            return '';

        try {
            return className.split("#")[1];
        } catch (ex) { }

        return '';
    }


    return (
        <>
            <a className={getClassName(item.className) + " cursor-pointer d-none d-sm-block"} onClick={() => hideMenu()}>
                <M3Icon iconName="MenuBurgher" />
            </a>
            <a className={getClassName(item.className) + " cursor-pointer d-block d-sm-none p-0 pl-2"} onClick={() => hideMenu()}>
                <M3Icon iconName="MenuBurgher" />
            </a>
            <WebVerticalMenu item={item } handleModal={setIsActive} isActive={isActive} />
        </>
    );
}
