import React from 'react';
import Cookies from 'universal-cookie';
import { getCDNLogoDocumentWhite } from "../../../../js/CDNUtility";
import { getPrimaryColor } from '../PrintCss.js';

export const HeaderFirstPagePDFPrint = ({ title, extraInfo }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");


    return (<>
        <div className="" style={{ backgroundColor: getPrimaryColor(), display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingBottom: "8px", paddingRight: "8px" }}>
            <img src={getCDNLogoDocumentWhite()} className="firstpageheader" alt="Logo" />
        </div>
    </>
    )
}
