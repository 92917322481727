import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import AppRoutes_Website from './AppRoutes_Website';
import AppRoutesProtected from './AppRoutesProtected';
import AppRoutes_MyBookProtected from './AppRoutes_MyBookProtected';
import AppRoutes_Document from './AppRoutes_Document';
import AppRoutes_Dynamic from './AppRoutes_Dynamic';
import { GenerateDynamicRoutes } from './AppRoutes_Dynamic';
import { LayoutWebSite } from './components/Layouts/Layout_WebSite';
import { LayoutMyBook } from './components/Layouts/Layout_MyBook';
import { LayoutDocument } from './components/Layouts/Layout_Document';
import { AuthProvider } from './components/Auth/AuthProvider';
import { ProtectedRoute } from './components/Auth/ProtectedRoute';
import { Footer } from './components/PageComponent/Footer';
import configData from "./appsettings.json";
import { Helmet } from 'react-helmet';
import { handleError, getAuthToken, getCurrentUserFromJwtToken, setWebsiteInfoSession, getWebsiteInfo, clearAllSessionData, getWebsiteId } from "./js/Utils";
import './css/custom.css';

export const App = () => {
    const [dynamicRoutes, setDynamicRoutes] = useState([]);
    const [websiteId, setWebsiteId] = useState(null);
    const [websiteHead, setWebsiteHead] = useState(null);
    const MyKeyUrlRewriting = "MyKeyUrlRewriting";

    useEffect(() => {
        const websiteInfo = getWebsiteInfo();
        if (websiteInfo)
            document.title = websiteInfo.name;

        let wId = getWebsiteId();

        let strMyUrlRewriting = sessionStorage.getItem(MyKeyUrlRewriting);
        if (!strMyUrlRewriting) {

            const requestOption = {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' }
            };

            fetch(`${configData.Settings.CommonApi_BaseUrl}website/geturlrewriting/${wId}`, requestOption)
                .then((res) => handleError(res))
                .then((items) => {
                    let appRouteDynamic = GenerateDynamicRoutes(items);
                    setDynamicRoutes(appRouteDynamic);
                    setDynamicWebSiteId(appRouteDynamic);

                    sessionStorage.setItem(MyKeyUrlRewriting, JSON.stringify(items));
                })
                .catch((err) => {
                    console.error(err);
                });

        } else {
            let jCurrentHeader = JSON.parse(strMyUrlRewriting);
            let appRouteDynamic = GenerateDynamicRoutes(jCurrentHeader);

            setDynamicWebSiteId(appRouteDynamic);
            setDynamicRoutes(appRouteDynamic);
        }

        setWebsiteHead(getWebsiteInfo())
    }, [])

    function setDynamicWebSiteId(appRouteDynamic) {
        let myPath = getPath();
        const websiteInfo = getWebsiteInfo();

        if (websiteInfo && websiteInfo.websiteId != null && websiteInfo.websiteId !== undefined) {
            setWebsiteId(websiteInfo.websiteId);
        } else {

            let res = appRouteDynamic.filter(x => x.path && x.path.toUpperCase() === myPath.toUpperCase());
            if (res && res.length > 0 && res[0].websiteId) {
                clearAllSessionData();

                setWebsiteId(res[0].websiteId);
                setWebsiteInfoSession(res[0].websiteInfo);
            }
            else
                setWebsiteId(configData.Settings.WebsiteId);
        }
    }

    function getPath() {
        const url = window.location.href;
        const parts = url.split('/');
        return parts[parts.length - 1];
    }

    function RoutesIsVisibleOnlyBook(page) {
        let isOnlyBook = false;

        try {
            let token = getAuthToken();

            let currentUser = getCurrentUserFromJwtToken(token);
            if (currentUser && (currentUser.roles.includes("W_OnlyBook") || currentUser.roles.includes("W_ConfirmAndBuy")))
                isOnlyBook = true;

        } catch (ex) {

        }

        if (!isOnlyBook) return true;

        return page.visibleOnlyBook || page.index;
    }

    return (
        <>
            <AuthProvider>
                {
                    websiteHead && <Helmet>
                        <title>{websiteHead.websiteName}</title>
                        {websiteHead.colors && (
                            <style>
                                {`
                :root {
                  ${websiteHead.colors
                                        .map(color => `--${color.label}: ${color.color};`)
                                        .join('\n')}
                }
              `}
                            </style>
                        )}
                    </Helmet>
                }

                <Routes>
                    {
                        AppRoutes
                            .filter((page) => RoutesIsVisibleOnlyBook(page))
                            .map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={
                                    <LayoutWebSite websiteId={websiteId}>
                                        {element}
                                    </LayoutWebSite>
                                } />;
                            })
                    }
                    {
                        AppRoutes_Website
                            .filter((page) => RoutesIsVisibleOnlyBook(page))
                            .map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={
                                    <LayoutWebSite websiteId={websiteId}>
                                        <ProtectedRoute>
                                            {element}
                                        </ProtectedRoute>
                                    </LayoutWebSite>
                                } />;
                            })
                    }
                    {
                        AppRoutesProtected
                            .filter((page) => RoutesIsVisibleOnlyBook(page))
                            .map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={
                                    <LayoutWebSite websiteId={websiteId}>
                                        <ProtectedRoute>
                                            {element}
                                        </ProtectedRoute>
                                    </LayoutWebSite>
                                } />;
                            })
                    }
                    {
                        AppRoutes_MyBookProtected
                            .filter((page) => RoutesIsVisibleOnlyBook(page))
                            .map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={
                                    <LayoutMyBook websiteId={websiteId}>
                                        <ProtectedRoute>
                                            {element}
                                        </ProtectedRoute>
                                    </LayoutMyBook>
                                } />;
                            })
                    }
                    {
                        AppRoutes_Document
                            .filter((page) => RoutesIsVisibleOnlyBook(page))
                            .map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={
                                    <LayoutDocument websiteId={websiteId}>
                                        {element}
                                    </LayoutDocument>
                                } />;
                            })
                    }
                    {
                        AppRoutes_Dynamic
                            .filter((page) => RoutesIsVisibleOnlyBook(page))
                            .map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={
                                    <LayoutWebSite websiteId={websiteId}>
                                        {element}
                                    </LayoutWebSite>
                                } />;
                            })
                    }
                    {
                        dynamicRoutes.map((route, index) => {
                            const { element, componentRenderType, ...rest } = route;
                            return <Route key={index} {...rest} element={
                                <LayoutWebSite websiteId={websiteId}>
                                    {element}
                                </LayoutWebSite>
                            } />;
                        })
                    }
                </Routes>
                {
                    !window.location.pathname.includes("documents") && websiteId !== null && <Footer websiteId={websiteId} />
                }
            </AuthProvider>
        </>
    );
}
