import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { StructureDetailPrint } from '../product/StructureDetailPrint';
import { FlightDetailPrint } from '../product/FlightDetailPrint';
import { ActivityDetailPrint } from '../product/ActivityDetailPrint';
import { CarRentalDetailPrint } from '../product/CarRentalDetailPrint';
import { TransferDetailPrint } from '../product/TransferDetailPrint';
import { TourDetailPrint } from '../product/TourDetailPrint';
import { CruiseDetailPrint } from '../product/CruiseDetailPrint';
import { TrainDetail } from '../../Reservation/TrainDetail';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import { getDifferenceDay } from '../../../js/PrintUtility.js';
import configData from "../../../appsettings.json";


export const PrintStepItem = ({ qInfo, dynamicNotesDistinct, isPrintCliente, isTemplate = false, isConfirmPrint = true, isDocViaggio = false, isPrintPdf = true, isNotStep = true, isQuotationTo }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    let diffDate = "";

    useEffect(() => {
        console.log('dynamic notes', dynamicNotesDistinct)
    }, [])
    
    if (!qInfo || (qInfo && qInfo.productType === 'Generic'))
        return;

    if (qInfo.productInfo)
        diffDate = getDifferenceDay(qInfo.productInfo.priceBar.startDate, qInfo.productInfo.priceBar.endDate);


    function getLabelProduct() {
        console.log(qInfo)
        if (qInfo.productType === 'Structure') {
            return t(`Product:Structure:PrintItem`)
        }
        else if (qInfo.productType === 'Activity') {
            return t(`Product:Activity:Item`)
        }
        else if (qInfo.productType === 'Transfer') {
            return t(`Product:Transfer:Item`)
        }
        else if (qInfo.productType === 'CarRental') {
            return t(`Product:RentCar:Item`)
        }
        else if (qInfo.productType === 'Cruise') {
            return t(`Product:Cruise:Item`)
        }
        else if (qInfo.productType === 'Tour') {
            return t(`Product:Tour:Item`)
        }
        else {
            return ""
        }

    }

    return (
        <>
            <div className="borderColor mb-3 mb-print-step">
                {qInfo.productInfo && qInfo.productInfo.priceBar && qInfo.productType !== 'Generic' && ((qInfo.productType !== 'Flight' && !isNotStep) || (isNotStep)) &&
                    <table className="w-100">
                        <tr>
                            {qInfo.productInfo.priceBar.endDate && parseInt(diffDate) > 0 ?
                            <>
                                <td className="w-80 fontBold">
                                    {t(`Template:StartStep`)} {formatDateTime(qInfo.productInfo.priceBar.startDate, cultureName, { noYear: true })}
                                    &nbsp;
                                    {t(`Template:EndStep`)} {formatDateTime(qInfo.productInfo.priceBar.endDate, cultureName, { noYear: true })}
                                </td>
                            </>
                            :
                            <td colSpan="2" className="w-80 fontBold">
                                {t(`Template:onDate`)} {formatDateTime(qInfo.productInfo.priceBar.startDate, cultureName, { noYear: true })}
                            </td>
                            }
                            <td className="text-right fontBold">
                                {qInfo.productType !== 'Flight' && qInfo.productSubType ? qInfo.productSubType : qInfo.productType}
                            </td>
                        </tr>
                    </table>
                }
                {
                    qInfo.productType === 'Structure' && <StructureDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        isNotStep={isNotStep }
                        structureDetail={qInfo.productInfo}
                        isDocViaggio={isDocViaggio }
                        isPrintCliente={isPrintCliente}
                        isConfirmPrint={isConfirmPrint}
                        isPrintPdf={isPrintPdf}
                        isHideCancelPolicy={isDocViaggio ? true : false}
                    />
                }
                {
                    qInfo.productType === 'Flight' && <FlightDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        isDocViaggio={isDocViaggio }
                        isNotStep={isNotStep}
                        flightDetail={qInfo.productInfo}
                        isPrintCliente={isPrintCliente}
                        isConfirmPrint={isConfirmPrint}
                    />
                }
                {
                    qInfo.productType === 'Activity' && <ActivityDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        productSubType={qInfo.productSubType }
                        activityDetail={qInfo.productInfo}
                        isNotStep={isNotStep}
                        isDocViaggio={isDocViaggio }
                        isPrintCliente={isPrintCliente}
                        isConfirmPrint={isConfirmPrint}
                        isHideCancelPolicy={isDocViaggio ? true : false}
                    />
                }
                {
                    qInfo.productType === 'Transfer' && <TransferDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        id={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        isNotStep={isNotStep}
                        transferDetail={qInfo.productInfo}
                        isPrintCliente={isPrintCliente}
                        isDocViaggio={isDocViaggio }
                        isConfirmPrint={isConfirmPrint}
                        isHideCancelPolicy={isDocViaggio ? true : false}
                    />
                }
                {
                    qInfo.productType === 'CarRental' && <CarRentalDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        isNotStep={isNotStep}
                        carRentalDetail={qInfo.productInfo}
                        isPrintCliente={isPrintCliente}
                        isDocViaggio={isDocViaggio }
                        isConfirmPrint={isConfirmPrint}
                        isHideCancelPolicy={isDocViaggio ? true : false}
                    />
                }
                {
                    qInfo.productType === 'Cruise' && <CruiseDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        cruiseDetail={qInfo.productInfo}
                        isNotStep={isNotStep}
                        isDocViaggio={isDocViaggio}
                        isPrintCliente={isPrintCliente}
                        isConfirmPrint={isConfirmPrint}
                        isHideCancelPolicy={isDocViaggio ? true : false}
                    />
                }
                {
                    qInfo.productType === 'Tour' && <TourDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        isDocViaggio={isDocViaggio }
                        isNotStep={isNotStep}
                        tourDetail={qInfo.productInfo}
                        isPrintCliente={isPrintCliente}
                        isConfirmPrint={isConfirmPrint}
                        isPrintPdf={isPrintPdf}
                        isHideCancelPolicy={isDocViaggio ? true : false}
                    />
                }
                {/*
                    qInfo.productType === 'Train' && <TrainDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        id={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        type={qInfo.wsBookingId ? "QuotationTO_MyBook" : "QuotationTO"}
                        showNetPrices={false}
                        trainDetail={qInfo.productInfo }
                        isPrintCliente={isPrintCliente}
                        />
                */}
            </div>
        </>
    );
}