import React from 'react';
import Cookies from 'universal-cookie';
import { PrintStepHeader } from './PrintStepHeader.js';
import { imageOnError } from '../../../js/CDNUtility.js';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';
import { getIconUrlToPrintCustom, replaceAllStyleElements, dynamicNotesDistincts  } from '../../../js/PrintUtility.js';

export const PrintDynamicNotes = ({ notes, onHandleIsPrint }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    function addBlackColorToBoldHTML(htmlString) {
        const fontWeightRegex = /<([^>]+)style="([^"]*(font-weight:\s*(700|bold))[^"]*)"([^>]*)>/gi;

        function replaceStyle(match, startTag, styleContent, fontWeight, endTag) {
            if (styleContent.includes('color:')) {
                return `<${startTag}style="${styleContent.replace(/color:\s*[^;]+/, 'color: black')}"${endTag}>`;
            } else {
                return `<${startTag}style="${styleContent}; color: black"${endTag}>`;
            }
        }

        return htmlString.replace(fontWeightRegex, replaceStyle);
    }
    
    let dynamicNotesFinal = dynamicNotesDistincts(notes);
    return (
        <>
            {dynamicNotesFinal != null && dynamicNotesFinal.length > 0 && dynamicNotesFinal.map((note, index) =>
                <>
                    <div key={index} className="mt-3 marginTopPdfAlternatives" style={{ display: 'grid' }}>
                        <PrintStepHeader
                            title={note.noteName}
                            PNR=''
                            totaleViaggio=''
                            iconName=''
                            cultureName={cultureName}
                            onHandleIsPrint={onHandleIsPrint }
                        />
                    </div>
                    <div className="border">
                        <table className="w-100">
                            <tbody>
                            <tr className="customBackground">
                                <td className="pl-2">
                                        <span className="h6 float-end" style={{ color: "#780704" }}>{note.categoryName }</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div style={{ padding: '5px', textAlign: "justify", color: '#555555' }}>
                            <span style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: addBlackColorToBoldHTML(replaceAllStyleElements(note.note)) }} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}