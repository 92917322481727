import { getIconUrlToPrint } from "../../js/PrintUtility"

export function PdfPeople({adults, children}) {
    return <>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
            <div style={{width: "100px"} }></div>

            <img src={getIconUrlToPrint('person')} className=" mr-1 ico-image" />
            <span>

            {adults}
            &nbsp;
            </span>
            <div style={{ width: '6px' }}></div>
            <img src={getIconUrlToPrint('personWhite')} className="ico-image mr-1" />

            <span>
            
                {children }
            </span>
        </div>

    </>
}