import React from 'react';

export const PrintAccountBalanceFooter = ({ }) => {
  
    return (<>
        <table>
            <tr className="row" style={{ borderTop: '1px solid #d0d1d6', marginTop: '10px', }}>
                <td className="col-lg-12">
                    <span style={{ textAlign: 'center' }}>
                        First Travel Swiss Sagl
                    </span>
                </td>
            </tr>
            <tr className="row">
                <td className="col-lg-12">
                    <span>
                    </span>
                </td>
            </tr>
        </table>
    </>);
}